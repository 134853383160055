import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/services/api.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tip-view',
  templateUrl: './tip-view.component.html',
  styleUrls: ['./tip-view.component.scss']
})
export class TipViewComponent implements OnInit {

  @Input() id: number;
  incTime: string;
  tipDesc: string;
  tipImage: string;
  tipVideo:string;
  anonymous: boolean;
  lat: number;
  lng: number;
  zoom: number;
  name: string;
  phone: string;
  email: string;
  fileType: string;
  read: boolean;
  isImage: boolean = false;
  isVideo: boolean = false;
  imageUrl: string;
  format = "image";
  assignee: string;
  assignedBy: string;

  constructor(private apiService: ApiService, private router: Router, private modal: NgbActiveModal) { }

  ngOnInit() {
        this.apiService.getRequest('admin/tip/'+ this.id).subscribe(
        (response: any) => {
            if(response.success) {
                this.incTime = response.data.inc_time;
                this.tipDesc = response.data.tip_desc;
                this.fileType = response.data.file_type;
                if (this.fileType == 'image') {
                  this.tipImage = response.data.image_url;
                  this.isImage = true;
                }
                if(this.fileType == 'video') {
                  this.isVideo = true;
                  this.tipVideo = response.data.image_url;         
                }
                this.lat = Number(response.data.latitude);
                this.lng = Number(response.data.longitude);
                if (this.lat && this.lng) {
                  this.imageUrl = "https://maps.google.com/maps/api/staticmap?markers=" + this.lat + ", " + this.lng + "&zoom=15&size=500x200&scale=1&maptype= roadmap&key=" + environment.googleApiKey;
                }
                this.zoom = 15;
                this.anonymous = response.data.anonymous;
                this.name = response.data.name;
                this.phone = response.data.phone;
                this.email = response.data.email;
                this.read = response.data.read;
                this.assignee = response.data.assignee;
                this.assignedBy = response.data.assignedBy;
            }
            let putData = {};
            if (!this.read) {
              this.apiService.postRequest('admin/tip-read/' + response.data.id,putData).subscribe(
                (res: any) => {
                  console.log("Marked as read!")
                  if(response.success) {
                    this.apiService.tipCount.next(response.unseen_tip_count);
                  }
                },
                err => {
                    console.log(err);
                },
              );
            }
        });
  }
  toggleVideo(event: any) {
      let audioPlayer = <HTMLVideoElement>document.getElementById('tipVideo');
      audioPlayer.play();
  }
  closeModal(result?: any) {
      this.modal.close();
  }
  printComponent(cmpName) {
    const printContent = document.getElementById(cmpName).innerHTML;
    const WindowPrt = window.open('', '', '');
    WindowPrt.document.write(printContent);
    WindowPrt.document.getElementById('timeInc').style.display='block';
    if (this.lat && this.lng) {
      WindowPrt.document.getElementById('staticMap').style.display='block';
    }
    if (this.tipImage) {
      if (this.fileType == 'image') {
        WindowPrt.document.getElementById('tipImg').style.width = "300px";
        WindowPrt.document.getElementById('tipImg').style.height = "300px";
      }
      if(this.fileType == 'video') {
        WindowPrt.document.getElementById('tipVideo').style.display='none';
      }
    }
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
  }
}
