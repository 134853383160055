import { Component, OnInit, ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver  } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { PierSupportEditComponent } from '../../pier-support/pier-support-edit/pier-support-edit.component';
import { ChaplainEditComponent } from '../../chaplains/chaplain-edit/chaplain-edit.component';
import { CivilianCounselorEditComponent } from '../../civilian-counselors/civilian-counselors-edit/civilian-counselor-edit.component';
import { GeneralInsuranceEditComponent } from '../../general-insurance/general-insurance-edit/general-insurance-edit.component';
import { MindfulLearningEditComponent } from '../../mindful-learning/mindful-learning-edit/mindful-learning-edit.component';

@Component({
  selector: 'app-wellness-information-pages-data-edit',
  templateUrl: './wellness-information-pages-data-edit.component.html',
  styleUrls: ['./wellness-information-pages-data-edit.component.scss']
})
export class WellnessInformationPagesDataEditComponent {
  apiLink:any;
  content:any;
  targetComponent : string;
  targetTitle : '';
  componentFactory : any;
  private componentRef: ComponentRef<any>;
  @ViewChild('dynamicEditComponentContainer', { read: ViewContainerRef, static: false }) dynamicEditComponentContainer: ViewContainerRef;
  updateUrl : any;
  backUrl : any;
  pageType : string = null;
  restricted : any;

  constructor(private resolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.apiLink = 'admin/wellness-by-id/' + params['id'] + (params['type'] ?  "/" + params['type'] : null);
      this.apiService.getRequest(this.apiLink).subscribe(
          (response: any) => {
            if(response.success) {
              this.content = response.data;
              this.targetComponent = this.content.template_name;
              this.targetTitle = this.content.title;
              this.updateUrl = 'admin/update-wellness-data/' + this.content.id 
              this.restricted = this.content.restricted
              this.backUrl = 'wellness-pages/content/'+ this.content.id+'-'+ this.content.slug 
              switch(this.targetComponent) {
                case 'PierSupportListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(PierSupportEditComponent);
                  break;
                }
                case 'ChaplainListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(ChaplainEditComponent);
                  break;
                }
                case 'CivilianCounselorsListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(CivilianCounselorEditComponent);
                  break;
                }
                case 'GeneralInsuranceListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(GeneralInsuranceEditComponent);
                  break;
                }
                case 'MindfulLearningListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(MindfulLearningEditComponent);
                  this.pageType = "mindful"
                  break;
                }
              }
              this.componentRef = this.dynamicEditComponentContainer.createComponent(this.componentFactory);
              this.componentRef.instance.updateUrl = this.updateUrl;
              this.componentRef.instance.pageTitle = this.targetTitle;
              this.componentRef.instance.isStatic = false;
              this.componentRef.instance.backUrl = this.backUrl;
              this.componentRef.instance.pageType = this.pageType;
              this.componentRef.instance.lessonRestricted = this.restricted;
            }
          },
          (err: any) => console.log(err),
        );
        
      });
  }

}
