import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dynamic-content-management-list',
  templateUrl: './dynamic-content-management-list.component.html',
  styleUrls: ['./dynamic-content-management-list.component.scss']
})
export class DynamicContentManagementListComponent implements OnInit {
  content:any;
  page = 1;
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  apiLink:any;
  popoverTitle: string = 'Deleting this page may cause data lose';
  popoverMessage: string = 'Are you sure?';
  @ViewChild('deleteModal', { static: false }) accoViewModal: TemplateRef<any>;
  accoModalRef: any;
  id : any;
  limitReached : boolean = false;

  constructor(public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getPages(this.page);
      }else {
        this.router.navigate(['content-pages', {page:1}]);
      }
    });

  }

  getPages(page:any) {
    // this.apiLink = 'admin/content-pages?page=' + page;
    this.apiLink = 'admin/content-pages';
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          this.content = response.data.pages;
          if(this.content.length == 9) {
            this.limitReached = true;
          }
          // this.page = response.data.current_page;
          // this.collectionSize = response.data.total;
          // this.pageSize = response.data.per_page;
          // this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  removePage(id: any) {
    this.accoModalRef = this.modal.open(this.accoViewModal);
    this.id = id
  }
  deletePage() {
    this.apiService.deleteRequest('admin/delete-content-page/' + this.id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show(response.message, { cssClass: 'alert-success' });
          this.id = null;
          this.closeModal();
          this.getPages(this.page);
          this.limitReached = false;
        } else {
          this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  closeModal(result?: any) {
    this.id = null;
    this.accoModalRef.close();
  }
  getManagePageListPage(pageNumber: any) {
    this.router.navigate(['content-pages', {page:pageNumber}]);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.content, event.previousIndex, event.currentIndex);
    this.saveMenuPositions();
  }
  saveMenuPositions() {
    const menuPositions = this.content.map(menu => menu);
    this.apiService.postRequest('admin/update-content-order', menuPositions).subscribe(
      () => {
        console.log('Content positions saved successfully.');
      },
      error => {
        console.error('Error saving content positions:', error);
      }
    );
  }
}
