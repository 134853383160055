import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { MustMatch } from './../validator';
import { Location } from '@angular/common';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  token: string;
  PasswordResetForm: FormGroup;
  apiErrorMessage: string;
  passwordPattern : string;

  constructor(private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParamMap.get("token");
    this.passwordPattern = '(?=.*[A-Za-z])(?=.*\\d)(?=.*[ ?"#@^$%&/\[=+*!`~:;.,{}<>\-])[A-Za-z\\d@$!%*#?&.{}:;.,<>\/\\+-\[\\]`~]{8,}';
    this.PasswordResetForm = this.formBuilder.group({
      'password': ['', Validators.pattern(this.passwordPattern)],
      'confirmPassword': ['', Validators.required]
    },
    {
      validator : MustMatch('password', 'confirmPassword')
    });
  }

  onSubmit() {
    if (this.PasswordResetForm.valid) {
      let postData = {
        'token': this.token,
        'password': this.PasswordResetForm.value.password
      }

      this.apiService.postRequest('password-reset', postData).subscribe(
        (response: any) => {
          console.log(response)
            if (response.success) {
                this.router.navigate(['/login']);
                this._flashMessagesService.show('Your Password has Successfully reset', { cssClass: 'alert-success' });
            } else {
              this.apiErrorMessage = response.message;
                this._flashMessagesService.show('Password reset Failed!!', { cssClass: 'alert-danger' });
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

}
