import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ValidationService } from '../../shared/services/validation.service';
import { Location } from '@angular/common';
import {MatTooltipModule} from '@angular/material';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-offduty-edit',
  templateUrl: './offduty-edit.component.html',
  styleUrls: ['./offduty-edit.component.scss'],
})
export class OffDutyJobsEditComponent implements OnInit {
    public Editor = ClassicEditor;
  offDutyJobEditForm: FormGroup;
  apiErrorMessage: string;
  offdutyId: string;
  offDutyTitle = "";
  title: string;
  start_date: string;
  end_date: string;
  payment: number;
  staff_slots: number;
  location: string;
  start_time: string;
  end_time: string;
  description: string;
  todayDate:Date = new Date();
  minEndDate = new Date();
  selectedUsersOnly = false;
  selectedTeamOnly: boolean = false;
  hasStaff = true;
  hasTeam = true;
  selectedItems = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  dropdownList = [];
  dropdownList2 = [];
  selectedStafff = [];
  selectedTeam = [];
  type: number;

  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link','|','imageUpload']
  };

  constructor(private apiService: ApiService, private _location: Location, private router: Router, private _flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private modal: NgbModal) { }

  ngOnInit() {
    this.offDutyTitle = environment.offDutyTitle;
    this.dropdownList = [];
    this.dropdownSettings = {
      singleSelection: false,
      text:"Select Staff",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      disabled: true,
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      text:"Select Team",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      disabled: true,
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.apiService.getRequest('admin/staff').subscribe(
      (response: any) => {
        if(response.success) {
          var staffList = response.data;
          staffList.forEach((staff: any) => {
            this.dropdownList.push({"id":staff.id,"itemName":staff.first_name + ' ' + staff.last_name});
          });
        }
      },
      (err: any) => console.log(err),
    );
    this.apiService.getRequest('admin/team').subscribe(
      (response: any) => {
        if(response.success) {
          var teams = response.data;
          teams.forEach((team: any) => {
            this.dropdownList2.push({"id":team.id,"itemName":team.name});
          });
        }
      },
      (err: any) => console.log(err),
    );
    this.activatedRoute.params.subscribe((params) => {
      this.offdutyId = params['id'];
    });
    this.getOffDutyJob();
    this.offDutyJobEditForm = this.formBuilder.group({
      'title': ['', Validators.required],
      'start_date': ['', Validators.required],
      'end_date': ['', Validators.required],
      'payment': ['', Validators.required],
      'location': ['', Validators.required],
      'start_time': ['', Validators.required],
      'end_time': ['', Validators.required],
      'description': ['', Validators.required],
      'staff_slots': [ '', Validators.required],
      'alertType': [{value: this.type, disabled: true}, Validators.required],
      'staffs' : [''],
      'teams': [''],

    });
  }

  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }

  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.files[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              console.log(response.path);
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }

  onSubmit() {
    if (this.offDutyJobEditForm.value.alertType == 2 && this.offDutyJobEditForm.value.staffs.length == 0) {
      this.hasStaff = false;
    } else {
      this.hasStaff = true;
    }
    if (this.offDutyJobEditForm.value.alertType == 3 && this.offDutyJobEditForm.value.teams.length == 0) {
      this.hasTeam = false;
    } else {
      this.hasTeam = true;
    }
    if ((this.hasStaff && this.hasTeam) && this.offDutyJobEditForm.valid) {
      let putData = {
        'title': this.offDutyJobEditForm.value.title,
        'start_date': this.offDutyJobEditForm.value.start_date,
        'end_date': this.offDutyJobEditForm.value.end_date,
        'payment': this.offDutyJobEditForm.value.payment,
        'location': this.offDutyJobEditForm.value.location,
        'start_time': this.offDutyJobEditForm.value.start_time,
        'end_time': this.offDutyJobEditForm.value.end_time,
        'description': this.offDutyJobEditForm.value.description,
        'staff_slots': this.offDutyJobEditForm.value.staff_slots,
        'type': this.type,
        'staffs' : this.offDutyJobEditForm.value.staffs,
        'teams' : this.offDutyJobEditForm.value.teams
      }
      if (!this.selectedUsersOnly) {
        putData['staffs'] = [];
      }
      if (!this.selectedTeamOnly) {
        putData['teams'] = [];
      }
      this.apiService.putRequest('admin/offdutyjobs/' + this.offdutyId, putData).subscribe(
        (response: any) => {
            if (response.success) {
                this.router.navigate(['off-duty-jobs']);
                this._flashMessagesService.show('Update successful', { cssClass: 'alert-success' });
            } else {
                this.apiErrorMessage = response.message;
                this._flashMessagesService.show('Update Failed!', { cssClass: 'alert-danger' });
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  getOffDutyJob() {
    this.apiService.getRequest('admin/offdutyjobs/' + this.offdutyId).subscribe(
      (response: any) => {
        if(response.success) {
          this.title = response.data.title;
          const d = new Date(response.data.start_date);
          const d1 = new Date(response.data.end_date);
          // This will return an ISO string matching your local time.
          let date = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes() - d.getTimezoneOffset()).toISOString();
          let date1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), d1.getHours(), d1.getMinutes() - d1.getTimezoneOffset()).toISOString();
          this.start_date = date; // datepicker converting 1 day lesser issue
          this.minEndDate = d;
          this.end_date = response.data.end_date ? date1 : null; // datepicker converting 1 day lesser issue
          this.payment = response.data.payment;
          this.location = response.data.location;
          this.start_time = response.data.start_time;
          this.end_time = response.data.end_time;
          this.description = response.data.description;
          this.type = response.data.type;
          this.staff_slots = response.data.staff_slots;
          if(this.type != 1)
            this.offDutyJobEditForm.get('staff_slots').disable()
          var staffs = response.data.assigned_staff;
          staffs.forEach((staff: any) => {
            this.selectedStafff.push({"id":staff.id,"itemName":staff.first_name + ' ' + staff.last_name});
          });
          var teamList = response.data.assigned_team;
          teamList.forEach((staff: any) => {
            this.selectedTeam.push({"id":staff.id,"itemName":staff.name});
          });

          if(this.type == 2) {
            this.selectedUsersOnly = true;
            this.selectedTeam = [];
          }
          else if(this.type == 3){
            this.selectedTeamOnly = true;
            this.selectedStafff = [];
          }
          else {
            this.selectedStafff = [];
            this.selectedTeam = [];
          }
        }
      },
      (err: any) => console.log(err),
    );
  }
  onBackClick() {
    this._location.back();
  }

  modifyEndDate(event) {
    this.minEndDate = event.target.value
  }

  handleTypeChange(event) {
    if (event == 1) {
      this.selectedUsersOnly = false;
      this.selectedTeamOnly = false;
      this.hasStaff = true;
      this.hasTeam = true;
    } else if (event == 2) {
      this.selectedUsersOnly = true;
      this.selectedTeamOnly = false;
    } else if(event == 3) {
      this.selectedUsersOnly = false;
      this.selectedTeamOnly = true;
    }
  }

  onItemSelect(item:any){
    console.log(item);
    console.log(this.selectedItems);
    this.hasStaff = true;
    this.hasTeam = true;
  }
  OnItemDeSelect(item:any){
      console.log(item);
      console.log(this.selectedItems);
  }
  onSelectAll(items: any){
      console.log(items);
  }
  onDeSelectAll(items: any){
      console.log(items);
  }
}
