import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'crime-prevention-create',
  templateUrl: './crime-prevention-create.component.html',
  styleUrls: ['./crime-prevention-create.component.scss']
})
export class CrimePreventionCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  crimePreventionId: string;
  images = [];
  key:string;
  name: string;
  // link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    
  @Input() pageTitle: string ;
  @Input() createUrl: string ;
  apiLink:any;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    // if(!this.pageTitle) {
    //   this.pageTitle = "Crime Prevention";
    // }
    this.createPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'desc': ['',Validators.required],
      // 'image': [''],
      // 'file': [''],
      // 'link': [''],
      // 'link_name': ['']
    });
  }

  updateKey (eventData) {
    this.key = eventData.toLowerCase( ).replace(' ', '_');
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': this.createPageForm.value.name,
        'desc': this.createPageForm.value.desc,
        // 'image': this.url,
        // 'link': this.createPageForm.value.link,
        // 'link_name': this.createPageForm.value.link_name,
        'type' : this.key
      }
      // if(!this.isStatic) {
      //   postData['type'] = this.key
      // } else {
      //   postData['type'] = 'crime-prevention'
      // }
      // if(this.createUrl) {
        this.apiLink = this.createUrl;
      // } else{
      //   this.apiLink = 'admin/standard-page?key=' + this.key;
      // }
      this.apiService.postRequest(this.apiLink, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                // if(!this.isStatic) {
                  this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                // } else {
                //   this.router.navigate(['crime-prevention']);
                // }
              }
              // this.router.navigate(['crime-prevention']);
                this._flashMessagesService.show('Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Creation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    if(this.backUrl)  {
      this.router.navigate([this.backUrl]);
    } else {
      this.location.back();
    }
  }

}
