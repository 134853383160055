import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthStorageService } from '../services/auth.storage.service';


@Directive({ selector: '[authPermission]' })
export class AuthPermissionDirective {

  allowedPermissions: Array<string> = [];
  userPermissions: Array<string> = [];

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, authStorageService: AuthStorageService) {
    this.userPermissions = authStorageService.getUserPermissions();
  }

  @Input()
  set authPermission(perms: string) {
    this.allowedPermissions = perms.split(',');
    this._updateView();
  }

  private _updateView() {
    let allowed = false;
    if (this.userPermissions && this.allowedPermissions) {
      this.allowedPermissions.forEach(allowedPerm => {
        this.userPermissions.forEach(userPerm => {
          if (allowedPerm == userPerm) {
            allowed = true;
          }
        });
      });
    }

    if (allowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
