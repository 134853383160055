import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { AuthStorageService } from '../shared/services/auth.storage.service';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { interval, Subscription } from 'rxjs';
import { CommonService } from '../shared/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: string;
  userId: number;
  profile_pic:string;
  hasPublicAlertAccess: boolean;
  hasStaffAlertAccess: boolean;
  hasAllAccess: boolean;
  hasCommonAccess: boolean;
  hasOffDutyAccess: boolean;
  hasZoneCheckAccess: boolean;
  hasTipAccess: boolean;

  userProfilePic: Subscription;

  data: any;
  tipCountSubscr:Subscription;
  timerSubscr:Subscription;
  tipCount;
  praiseCompCountSubscr:Subscription;
  praiseCompCount;

  appName:string;
  appLogo:string;
  appLogoText:string;
  appLogoName: string = '';
  appLogoDesc: string = '';
  sidebarTitleFontSz: string;
  sidebarDescFontSz: string;
  sidebarTextColor: string;

  constructor(private authService: AuthService,
              private commonService: CommonService,
              private authStorageService: AuthStorageService, private router: Router, public apiService: ApiService) {
                this.appLogo = environment.logo;
                this.appLogoText = environment.logoText;
                this.appName = environment.appName; 
                this.appLogoName = environment.appLogoName;
                this.appLogoDesc = environment.appLogoDesc;
                this.sidebarTitleFontSz = environment.sidebarTitleFontSz;
                this.sidebarDescFontSz = environment.sidebarDescFontSz;
                this.sidebarTextColor= environment.sidebarTextColor;
              //   this.tipCountSubscr =this.apiService.tipCount.subscribe((res) => {
              //   this.tipCount = res;
              //   // console.log(res)
              // });
              this.userProfilePic= this.commonService.getUpdate().subscribe((res) => {
                this.profile_pic = res.data;
              });
              // this.praiseCompCountSubscr =this.apiService.praiseCompCount.subscribe((res) => {
              //   this.praiseCompCount = res;
              //   // console.log(res)
              // });
            }


ngOnDestroy(): void {
    // this.tipCountSubscr.unsubscribe();
    // this.praiseCompCountSubscr.unsubscribe();
    // this.timerSubscr.unsubscribe();
    this.userProfilePic.unsubscribe();
}

  ngOnInit() {
    let user = this.authService.getCurrentUser();
      this.username = user.first_name + " " + user.last_name;
      this.userId = user.id;
      this.profile_pic = user.profile_pic_url;
      let permissions = user.permission_list;
      let specialPermissions = this.authService.getUserSpecificRole();
      // this.appName = user.app_name;
      // this.appLogo = user.app_logo;
      // this.appLogoText = user.app_logo_text;
      this.hasPublicAlertAccess = permissions.indexOf("PUBLIC_ALERT_ACCESS") !== -1;
      this.hasStaffAlertAccess = permissions.indexOf("STAFF_ALERT_ACCESS") !== -1;
      this.hasAllAccess = permissions.indexOf("ALL_ACCESS") !== -1;
      this.hasCommonAccess = permissions.indexOf("COMMON_ACCESS") !== -1;
      this.hasTipAccess = permissions.indexOf("TIP_ACCESS") !== -1;
      this.hasOffDutyAccess = specialPermissions.indexOf("OFF_DUTY_ALLOW") !== -1;
      this.hasZoneCheckAccess = specialPermissions.indexOf("ZONE_CHECK_ALLOW") !== -1;
  }

  /*
  function to logout( clears session token also redirtedcts to login)
  */
  logout() {
    this.authService.logout().subscribe((res: any) => {
    })
    this.authStorageService.removeToken();
    this.authStorageService.removePermissions();
    this.router.navigate(['/login']);
  }

  getTipUnseenCount() {
    this.apiService.getRequest('tip-unseen').subscribe(
      (response: any) => {
        if(response.success) {
          // this.data = response.data;
          this.apiService.tipCount.next(response.data);
        }
      },
      (err: any) => console.log(err),
    );
  }

  getPraiseComplaintCount() {
    this.apiService.getRequest('admin/praise-or-complaints-count').subscribe(
      (response: any) => {
        if(response.success) {
          // this.data = response.data;
          this.apiService.praiseCompCount.next(response.data);
        }
      },
      (err: any) => console.log(err),
    );
  }

}
