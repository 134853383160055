import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss']
})
export class ResourceListComponent implements OnInit {
  resources: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  resourceId: any ;
  // pagination
  page = 1;
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  apiLink:any;
  search = "";

  @Input() pageTitle: string ;
  @Input() dataUrl: string ;
  @Input() createUrl: string ;
  @Input() editUrl: string ;
  @Input() paginateUrl: string ;
  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if(!this.dataUrl) {
      this.router.navigate(['content-pages']);
    }
    // this.getResources();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
      }
      this.getResources(this.page, this.search);
    });
    // if(!this.pageTitle) {
    //   this.pageTitle = "Resource";
    // }
    // if(!this.editUrl) {
    //   this.editUrl = '/resource/edit';
    // }
    // if(!this.createUrl) {
    //   this.createUrl = 'create';
    // }
    // if(!this.paginateUrl) {
    //   this.paginateUrl = 'resource';
    // }
  }

  getResources(page:any, search:any) {
    if (search == "") {
      this.activatedRoute.params.subscribe((params) => {
        page = params['page'];
      });
    }
    this.search = search;
    // if(this.dataUrl) {
      this.apiLink = this.dataUrl+ '?page=' + page + "&search=" + search;
    // } else{
    //   this.apiLink = 'admin/resource?page=' + page;
    // }
    this.apiService.getRequest( this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.resources = response.data;
          this.resources = response.data.data;
          if(this.resources.length == 0 && this.page > 1) { // to handle deletion of single record page
            this.page -= 1;
            this.getResourceListPage(this.page)
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteResource(id: any) {
    this.apiService.deleteRequest('admin/app-page/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getResources(this.page, this.search);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  
  getResourceListPage(pageNumber: any) {
    this.router.navigate([this.paginateUrl, {page:pageNumber}]);
  }
  onBackClick() {
    this.router.navigate(['content-pages']);
  }
}
