import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { AuthStorageService } from '../shared/services/auth.storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminService } from '../shared/services/admin.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  username: string;
  hasPublicAlertAccess: boolean;
  hasStaffAlertAccess: boolean;
  hasAllAccess: boolean;
  hasCommonAccess: boolean;
  hasOffDutyAccess: boolean;
  hasZoneCheckAccess: boolean;
  isContentActive: boolean = false;
  isWellnessActive: boolean = false;
  isMindfullActive: boolean = false;
  isWellnessSubActive: boolean = false;
  hasWellnessAccess: boolean = false;
  isAlertActive: boolean = false;
  isPromotionActive: boolean = false;
  currentPage: string = '';
  appLogoName: string = '';
  appLogoDesc: string = '';
  isUserActive: boolean = false;  
  hasTipAccess: boolean;
  hasStaffAccess: boolean;
  hasContentAccess: boolean;
  offDutyTitle = "";
  isDashboardActive: boolean = false;
  isTeamActive: boolean = false;
  isZoneActive: boolean = false;
  isOffdutyActive: boolean = false;
  isSettingsActive: boolean = false;
  isTipActive: boolean = false;
  currentRoute: string = '';

  data: any;
  tipCountSubscr:Subscription;
  timerSubscr1:Subscription;
  // timerSubscr2:Subscription;
  menuSubscr:Subscription;
  // wellnessSubscr:Subscription;
  timerSubscr3:Subscription;
  tipCount;
  // praiseCompCountSubscr:Subscription;
  praiseCompCount;
  praiseCount;

  appName:string;
  zoneCheckText:string;
  isZoneExternal: boolean;
  appLogo:string;
  appLogoText:string;
  sidebarTitleFontSz: string;
  sidebarDescFontSz: string;
  sidebarTextColor: string;

  zoneCheckCount;
  zoneCheckCountSubscr:Subscription;
  menuDropDownList = [];
  menuList = [];
  menuCount : number = 0;
  praiseMenu: boolean = false;
  wellnessMenuDropDownList = [];
  wellnessMenuList = [];
  offdutyVisible: boolean = true;

  constructor(private authService: AuthService,
    private authStorageService: AuthStorageService, private router: Router, public apiService: ApiService, private adminService: AdminService, private cdr: ChangeDetectorRef) {
      this.appLogo = environment.logo;
      this.appLogoName = environment.appLogoName;
      this.appLogoDesc = environment.appLogoDesc;
      this.appLogoText = environment.logoText;
      this.currentPage = this.router.url;
      this.sidebarTitleFontSz = environment.sidebarTitleFontSz;
      this.sidebarDescFontSz = environment.sidebarDescFontSz;
      this.sidebarTextColor= environment.sidebarTextColor;
      // this.isAlertActive = this.checkAlertActiveState(); Keep dropdown open on reload - NOt working
      this.appName = environment.appName;
        this.tipCountSubscr =this.apiService.tipCount.subscribe((res) => {
          this.tipCount = res;
          // console.log(res)
        });
        // this.praiseCompCountSubscr =this.apiService.praiseCompCount.subscribe((res) => {
        //   this.praiseCompCount = res;
        //   // console.log(res)
        // });
        this.zoneCheckCountSubscr =this.apiService.zoneCheckCount.subscribe((res) => {
          this.zoneCheckCount = res;
          // console.log('zone count ===> ',this.zoneCheckCount);
        });
  }


  ngOnDestroy(): void {
    this.tipCountSubscr.unsubscribe();
    // this.praiseCompCountSubscr.unsubscribe();
    this.zoneCheckCountSubscr.unsubscribe();
    // this.wellnessSubscr.unsubscribe();
    this.menuSubscr.unsubscribe();
    // if(!this.hasTipAccess && this.timerSubscr1 != undefined){
    if(this.timerSubscr1 != undefined) {
      this.timerSubscr1.unsubscribe();
    }
    if(this.timerSubscr3 != undefined){
      this.timerSubscr3.unsubscribe();
    }
  }

  highlightMenu(menu) {
    this.isDashboardActive = false;
    this.isAlertActive = false;
    this.isUserActive = false;
    this.isTeamActive = false;
    this.isZoneActive = false;
    this.isOffdutyActive = false;
    this.isSettingsActive = false;
    this.isTipActive = false;
    this.isWellnessActive = false;
    this.isContentActive = false;
    switch(menu) {
      case 'dashboard' : {
        this.isDashboardActive = true;
        break;
      }
      case 'alert' : {
        this.isAlertActive = true;
        break;
      }
      case 'user' : {
        this.isUserActive = true;
        break;
      }
      // case 'team' : {
      //   this.isTeamActive = true;
      //   break;
      // }
      // case 'zone' : {
      //   this.isZoneActive = true;
      //   break;
      // }
      // case 'offduty' : {
      //   this.isOffdutyActive = true;
      //   break;
      // }
      // case 'settings' : {
      //   this.isSettingsActive = true;
      //   break;
      // }
      // case 'tip' : {
      //   this.isTipActive = true;
      //   break;
      // }
      // case 'wellness' : {
      //   this.isWellnessActive = true;
      //   break;
      // }
      // case 'content' : {
      //   this.isContentActive = true;
      //   break;
      // }
    }
  }

  toggleDropdown(type: string) {
    this.isAlertActive = type === 'alert' ? !this.isAlertActive : false;
    this.isUserActive = type === 'user' ? !this.isUserActive : false;
    this.isWellnessActive = type === 'wellness' ? !this.isWellnessActive : false;
    this.isContentActive = type === 'content' ? !this.isContentActive : false;
  }
  
  isActiveRoute(route: string): boolean {
    return this.currentRoute === route;
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.isAlertActive = this.router.url.startsWith('/admin/staff-alert');
    //   }
    // });

    this.offDutyTitle = environment.offDutyTitle;
    let user = this.authService.getCurrentUser();
    this.username = user.first_name + " " + user.last_name;
    let permissions = user.permission_list;
    // if (this.appName == "East Ridge") {
    //   this.zoneCheckText = "Watch";
    // }
    // else if (this.appName == "MILTON") {
    //   this.zoneCheckText = "Vacation Check";
    // }
    // else {
    //   this.zoneCheckText = "Zone Check";
    // }
    this.zoneCheckText = environment.zoneText;
    this.isZoneExternal = environment.isZoneExternal;

    this.adminService.offdutyItemVisibility$.subscribe(visible => {
      this.offdutyVisible = visible;
      console.log('offdutyVisible=======> ',this.offdutyVisible);
    });
    if(this.offdutyVisible) {
        this.adminService.fetchSettings();
    }
    // this.appName = user.app_name;
    // this.appLogo = user.app_logo;
    // this.appLogoText = user.app_logo_text;
    let specialPermissions = this.authService.getUserSpecificRole();
    this.hasPublicAlertAccess = permissions.indexOf("PUBLIC_ALERT_ACCESS") !== -1;
    this.hasStaffAlertAccess = permissions.indexOf("STAFF_ALERT_ACCESS") !== -1;
    this.hasAllAccess = permissions.indexOf("ALL_ACCESS") !== -1;
    this.hasCommonAccess = permissions.indexOf("COMMON_ACCESS") !== -1;
    this.hasTipAccess = permissions.indexOf("TIP_ACCESS") !== -1;
    this.hasContentAccess = permissions.indexOf("CONTENT_ACCESS") !== -1;
    this.hasStaffAccess = permissions.indexOf("STAFF_ACCESS") !== -1;
    this.hasWellnessAccess = permissions.indexOf("WELLNESS_ACCESS") !== -1;

    this.hasOffDutyAccess = specialPermissions.indexOf("OFF_DUTY_ALLOW") !== -1;
    this.hasZoneCheckAccess = specialPermissions.indexOf("ZONE_CHECK_ALLOW") !== -1;

    this.getContentMenu();
    // this.getWellnessMenu();
    this.getTipUnseenCount();
    this.getZoneCheckUnseenCount();
    var observable = interval(10000);
    if(this.hasTipAccess){
      this.timerSubscr1 = observable.subscribe(()=>
          this.getTipUnseenCount()
      );
    }
    if(this.hasZoneCheckAccess){
      this.timerSubscr3 = observable.subscribe(()=>
        this.getZoneCheckUnseenCount()
      );
    }
    // this.wellnessSubscr = observable.subscribe(()=>
    //     this.getWellnessMenu()
    //   );

    this.menuSubscr = observable.subscribe(()=>
        this.getContentMenu()
      );
    // if(this.menuCount >= 9) {
    //   console.log('S')
    //   this.menuSubscr.unsubscribe();
    // }
  }

  /*
  function to logout( clears session token also redirtedcts to login)
  */
  logout() {
    this.authService.logout().subscribe((res: any) => {
    })
    // this.wellnessSubscr.unsubscribe();
    this.menuSubscr.unsubscribe();
    this.authStorageService.removeToken();
    this.authStorageService.removePermissions();
    this.timerSubscr1.unsubscribe();
    if(!this.hasZoneCheckAccess){
      this.timerSubscr3.unsubscribe();
    }
    this.router.navigate(['/login']);
  }

  getTipUnseenCount() {
    this.apiService.getRequest('tip-unseen').subscribe(
        (response: any) => {
          if (response.success) {
            // this.data = response.data;
            this.apiService.tipCount.next(response.data);
          }
        },
        (err: any) => console.log(err),
      );
  }

  // getPraiseComplaintCount() {
  //   this.apiService.getRequest('admin/praise-or-complaints-count').subscribe(
  //     (response: any) => {
  //       if(response.success) {
  //         // this.data = response.data;
  //         this.apiService.praiseCompCount.next(response.data);
  //       }
  //     },
  //     (err: any) => console.log(err),
  //   );
  // }
  checkAlertActiveState(): boolean {
    // Check if the current page URL matches any of the dropdown options
    return this.currentPage === '/public-alert' || this.currentPage === '/staff-alert';
  }

  getZoneCheckUnseenCount() {
    this.apiService.getRequest('zone-check-unseen').subscribe(
        (response: any) => {
          if (response.success) {
            // this.data = response.data;
            this.apiService.zoneCheckCount.next(response.data);
          }
        },
        (err: any) => console.log(err),
      );
  }

  getContentMenu() {
    this.praiseMenu = false;
    this.menuDropDownList = [];
    this.apiService.getRequest('admin/content-pages').subscribe(
      (response: any) => {
        if (response.success) {
          var pageList = response.data.pages;
          this.praiseCount = response.data.totalPraiseCount;
          pageList.forEach((pageList: any) => {
            var linkclass = "dropdown-item";
            if(pageList.template_id == 10) {
              linkclass = "dropdown-item d-flex flex-row justify-content-between";
              this.praiseMenu = true;
            }
            this.menuDropDownList.push({
              "id":pageList.id,
              "itemName":pageList.title,
              'itemSlug' :pageList.slug,
              'template' : pageList.template_id,
              'itemClass' : linkclass,
              'itemCount' : pageList.count
            });
          });
          this.menuList = this.menuDropDownList;
          this.menuCount = this.menuDropDownList.length;
          if(this.praiseMenu == true) {
            this.praiseCompCount = this.praiseCount;
          } else {
            this.praiseCompCount = 0;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }

  loadWellnessPages() {
    this.isWellnessActive = !this.isWellnessActive;
    this.router.navigate(['/wellness-pages/mindful']);
  }
  loadMindfullPages() {
    this.isMindfullActive = !this.isMindfullActive;
  }
  loadWellnessSubPages() {
    this.isWellnessSubActive = !this.isWellnessSubActive;
  }

  loadContentPages() {
    this.router.navigate(['/content-pages']);
  }

  closeMenu() {
    if (window.innerWidth < 992) {
      const navbar = document.querySelector('.navbar-collapse');
      navbar.classList.remove('show');
      const navbarToggler = document.querySelector('.navbar-toggler');
      navbarToggler.setAttribute('aria-expanded', 'false');    
      this.cdr.detectChanges();
    }
  }
  // getWellnessMenu() {
  //   this.wellnessMenuDropDownList = [];
  //   this.apiService.getRequest('admin/wellness-pages').subscribe(
  //     (response: any) => {
  //       if (response.success) {
  //         var pageList = response.data;
  //         pageList.forEach((pageList: any) => {
  //           this.wellnessMenuDropDownList.push({
  //             "id":pageList.id,
  //             "itemName":pageList.title,
  //             'itemSlug' :pageList.slug,
  //             'template' : pageList.template_id,
  //           });
  //         });
  //         this.wellnessMenuList = this.wellnessMenuDropDownList;
  //       }
  //     },
  //     (err: any) => console.log(err),
  //   );
  // }
}
