import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadAdaptor } from "../../upload-adapter";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-manage-app-pages-view',
  templateUrl: './manage-app-pages-view.component.html',
  styleUrls: ['./manage-app-pages-view.component.scss']
})
export class ManageAppPagesViewComponent implements OnInit {
  public Editor = ClassicEditor;
  page: any;
  url: string;
  name: string;
  page_key: string;
  type: string;
  desc: string;
  image: string;
  link: string;
  link_name: string;
  pageEditForm: FormGroup;
  popoverTitle: string = 'Are you sure?';
  popoverMessage: string = 'Are you really sure you want to do this?';
  id: any;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  }; 
  errorMessage;
  fileName;
  constructor(public apiService: ApiService, private router: Router, private formBuilder: FormBuilder, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.pageEditForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'page_key': ['', Validators.required],
      'type': ['standard', Validators.required],
      'desc': [''],
      'image': [''],
      'files': [''],
      'link': [''],
      'link_name': ['']
    });
    this.getPages();
  }

  getPages() {
    this.apiService.getRequest('admin/app-page/'+ this.id).subscribe(
      (response: any) => {
        if (response.success) {
          this.page = response.data;
          this.name = response.data.name;
          this.type = response.data.type;
          this.page_key = response.data.page_key;
          this.desc = response.data.desc;
          this.image = response.data.image;
          this.link = response.data.link;
          this.link_name = response.data.link_name;
          this.url = response.data.image_url;
        }
      },
      (err: any) => console.log(err),
    );
  }
  changeType(eventData) {
    console.log(eventData);
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.files[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  updateKey (eventData) {
    let key = eventData.toLowerCase( ).replace(' ', '_');
    this.pageEditForm.controls['page_key'].setValue(key);
  }
  onSubmit() {

    let putData = {
      'name': this.pageEditForm.value.name,
      'page_key': this.pageEditForm.value.page_key,
      'type': this.pageEditForm.value.type,
      'desc': this.pageEditForm.value.desc,
      'image': this.url,
      'link': this.pageEditForm.value.link,
      'link_name': this.pageEditForm.value.link_name,
    }

    this.apiService.putRequest('admin/app-page/' + this.id, putData).subscribe(
      (response: any) => {
        if (response.success) {
          this._flashMessagesService.show('Update successful', { cssClass: 'alert-success' });
          this.router.navigate(['app-page']);
        } else {
          if (response.message) {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          } else {
            this._flashMessagesService.show('Update Failed!', { cssClass: 'alert-danger' });
          }
        }
      },
      err => {
        console.log(err);
        this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      },
    );

  }


}
