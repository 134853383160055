import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageAppPagesViewComponent } from '../manage-app-pages-view/manage-app-pages-view.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-manage-app-pages-list',
  templateUrl: './manage-app-pages-list.component.html',
  styleUrls: ['./manage-app-pages-list.component.scss']
})
export class ManageAppPagesListComponent implements OnInit {
  pages:any;

  popoverTitle: string = 'Confirm Archive';
  popoverMessage: string = 'Are you sure?';
   // pagination
   page = 1;
   collectionSize: any;
   pageSize: any;
   lastPage: any;  
   apiLink:any;

  constructor(public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // this.getPages();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getPages(this.page);
      }else {
        this.router.navigate(['app-page', {page:1}]);
      }
    });
  }

  getPages(page:any) {
    this.apiLink = 'admin/app-page?page=' + page;

    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.pages = response.data;
          this.pages = response.data.data;
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deletePage(id: any) {
    this.apiService.deleteRequest('admin/app-page/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getPages(this.page);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  getManagePageListPage(pageNumber: any) {
    this.router.navigate(['app-page', {page:pageNumber}]);
  }
}
