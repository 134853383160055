import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  // moduleId: module.id,
  selector: 'sd-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
})

export class HomeComponent {
  clientName : string;

  ngOnInit() {
    this.clientName = environment.clientName;
  }
}
