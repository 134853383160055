import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { UploadAdaptor } from "../../upload-adapter";
import { ValidationService } from '../../shared/services/validation.service';
import { MustMatch } from '../../validator';
import { Location } from '@angular/common';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-wellness-information-edit',
  templateUrl: './wellness-information-edit.component.html',
  styleUrls: ['./wellness-information-edit.component.scss']
})
export class WellnessInformationEditComponent implements OnInit {
  public Editor = ClassicEditor;
  dropdownList = [];
  dropdownSettings = {};
  pageEditForm: FormGroup;
  url = '';
  errorMessage = null;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link']
  }; 
  pageId: string;
  title: string;
  template: string;
  icon: string;
  selectedTemplate = null;
  description: string;
  category: any;
  userId:any;
  author:any;
  restricted : boolean = false;

  constructor(private authService: AuthService,private activatedRoute: ActivatedRoute, private location: Location,private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, 
  private formBuilder: FormBuilder) { }

  ngOnInit() {
    let user = this.authService.getCurrentUser();
    this.userId = user.id;
    this.activatedRoute.params.subscribe((params) => {
      this.pageId = params['id'];
    });
    this.dropdownList = [];
    this.dropdownSettings = { 
      singleSelection: true, 
      text:"Select Template",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.pageEditForm = this.formBuilder.group({
      title: ['', Validators.required],
      template: ['', Validators.required],
      category: [''],
      icon: [''],
      description: [''],
      restricted : ['']
    },
    {});
    this.getPage();
  }
  getPage () {
    this.apiService.getRequest('admin/get-wellness-page/' + this.pageId).subscribe(
      (response: any) => {
        if(response.success) {
          this.title = response.data.title;
          this.author = response.data.author;
          this.restricted = response.data.restricted;
          this.category = response.data.category;
          this.url = response.data.icon;
          this.pageEditForm.get('template').disable()
          this.pageEditForm.get('category').disable()
          this.loadTemplates();
          this.selectedTemplate = this.template = response.data.template_id;
          if(this.selectedTemplate == 6) {
            document.getElementById('description-container').style.display = 'block';
            this.description = this.template = response.data.description;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }

  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  changeType(eventData) {
    console.log(eventData);
  }

  loadTemplates() {
    this.dropdownList = [];
    this.apiService.getRequest('admin/wellness-templates?category='+ this.category).subscribe(
      (response: any) => {
        if(response.success) {
          var templateList = response.data;
          templateList.forEach((template: any) => {
            this.dropdownList.push({"id":template.id,"itemName":template.title});
          });
        }
      },
      (err: any) => console.log(err),
    );
  }
  
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('icon',event.target.files[0]);
      this.apiService.postRequest('admin/upload-wellness-icon', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  onSubmit() {
    if (this.pageEditForm.valid) {
      let postData = {
        'title': this.pageEditForm.value.title,
        'template': this.pageEditForm.value.template,
        'description': this.pageEditForm.value.description,
        'icon': this.url,
        'restricted': this.pageEditForm.value.restricted,
      }
      this.apiService.putRequest('admin/update-wellness-page/'+ this.pageId, postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show("Updated Successfully!", { cssClass: 'alert-success' });
                this.errorMessage = null;
                var content = response.data;
                if(this.category == 1) {
                  this.router.navigate(['wellness-pages']);
                } else {
                  this.router.navigate(['wellness-pages/mindful']);
                }
              } else {
                this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    if(this.category == 1) {
      this.router.navigate(['wellness-pages']);
    } else {
      this.router.navigate(['wellness-pages/mindful']);
    }
  }
}
