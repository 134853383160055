import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from 'src/app/shared/services/api.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-staff-family-create',
  templateUrl: './staff-family-create.component.html',
  styleUrls: ['./staff-family-create.component.css']
})
export class StaffFamilyCreateComponent implements OnInit {

  staffCreateForm: FormGroup;
  emailPattern: string;
  passwordPattern : string;
  staffId: number;

  constructor(private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.staffCreateForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      phone: ['',[Validators.pattern("[0-9]+")]]
    },
    {});
    this.activatedRoute.params.subscribe((params) => {
      this.staffId = params['staff-id'];
    });
  }

  onSubmit() {
    if (this.staffCreateForm.valid) {
        let postData = {
          'first_name': this.staffCreateForm.value.firstName,
          'last_name': this.staffCreateForm.value.lastName,
          'email': this.staffCreateForm.value.email,
          'phone': this.staffCreateForm.value.phone,
          'user_type' : 3,
          'parent_id': this.staffId
        }
        this.apiService.postRequest('admin/staff-family/'+this.staffId+'/create', postData).subscribe(
          (response: any) => {
              if (response.success) {
                  this.router.navigate(['staff-family/'+this.staffId]);
                  this._flashMessagesService.show('Staff Family Member Created Successfully', { cssClass: 'alert-success' });
              } else {
                  this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              }
          },
          err => {
              console.log(err);
              this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          },
        );
      
    }
  }
  onBackClick() {
    this.location.back();
  }

}
