import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from './../shared/services/api.service';
import { AuthStorageService } from '../shared/services/auth.storage.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showEmailError: boolean;
  showOtp: boolean;
  clientName: string;
  errorMessage = null;
  successMessage = null;
  showPwd : boolean = false;
  otpMessage = 'Send OTP';
  otpMedium: any;
  emailVerify: boolean = true;
  phoneVerify: boolean = false;
  loginBg;
  logo;
  appLogoName: string = '';
  appLogoDesc: string = '';
  loginTitleFontSz: string;
  loginDescFontSz: string;
  loginTextColor: string;

  constructor(private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService, private authService: AuthService,
    private authStorageService: AuthStorageService,
    private flashMessagesService: FlashMessagesService) {
      this.loginBg = environment.loginBg;
      this.logo = environment.logo;
      this.appLogoName = environment.appLogoName;
      this.appLogoDesc = environment.appLogoDesc;
      this.loginTitleFontSz = environment.loginTitleFontSz;
      this.loginDescFontSz = environment.loginDescFontSz;
      this.loginTextColor= environment.loginTextColor;
  }

  ngOnInit() {
   
    this.apiService.getRequest('get-login-image?type=admin-login-image').subscribe(
      (response: any) => {
        if (response.success) {
          this.loginBg = response.file_name;
        }
      },
      (err: any) => {
      }
    );
    this.clientName = environment.clientName;
    this.showEmailError = false;
    this.showOtp = false;
    var user = this.authStorageService.getUser();
    // console.log(user);
    this.loginForm = this.fb.group({
      'email': [user ? user['email'] : '', Validators.required],
      'password': [user ? user['password'] : '', [Validators.required]],
      'otp': [''],
      'remember_me': [user ? user['remember_me'] : false],
    });
  }

  sendOtp(type: any) {
    this.otpMedium = type;
    var email = this.loginForm.controls.email.value;
    var password = this.loginForm.controls.password.value;
    if (email) {
      let postData = {
        'email': email,
        'password' :password
      };
      this.authStorageService.rememberUser(postData, this.loginForm.controls.remember_me.value);
      // let user = this.authStorageService.getUser();
      // console.log(user);
      if (type == 'sms') {
        postData['otp_medium'] = 'sms';
      } else {
        postData['otp_medium'] = 'email';
      }
      this.apiService.postRequest('send-otp', postData).subscribe(
        (response: any) => {
          if (response.success) {
            this.errorMessage = null
            this.showOtp = true;
            this.loginForm.controls['otp'].setValidators([Validators.required]);
            // this.flashMessagesService.show('An OTP has been sent to your email address.', { cssClass: 'alert-success on-top', timeout: 8000 });
            if (type == 'sms') {
              this.flashMessagesService.show('An OTP has been sent to your cell phone.', { cssClass: 'alert-success on-top', timeout: 8000 });
            } else {
              this.flashMessagesService.show('An OTP has been sent to your email address.', { cssClass: 'alert-success on-top', timeout: 8000 });
            }
            this.otpMessage = 'Resend OTP'
            this.successMessage = 'OTP sent!';
          } else {
            this.successMessage = null
            if (response.message == 'sent') {
              this.showOtp = true;
              this.errorMessage = null
              this.loginForm.controls['otp'].setValidators([Validators.required]);
              this.flashMessagesService.show('OTP already sent to email/phone. If not, please try resend after some time!', { cssClass: 'alert-success on-top' });
            } else if (response.message == 'Invalid User') {
              this.showOtp = false;
              this.flashMessagesService.show('Login failed!', { cssClass: 'alert-danger on-top' });
              this.errorMessage = 'Invalid Username or Password!';
            } else if (response.result == 'Invalid IP') {
              this.flashMessagesService.show("Your IP  doesn't have access to the website! Please contact administrator.", { cssClass: 'alert-danger on-top' });
            } else {
              this.flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger on-top' });
            }
          }
        },
        (err: any) => {
          if (err.error.text) {
            this.flashMessagesService.show(err.error.text, { cssClass: 'alert-danger on-top' });
          } else {
            this.flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger on-top' });
          }
        }
      );
    }
  }

  toggleShow() {
      this.showPwd = !this.showPwd;
  }
  
  login() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm).subscribe(
        (response) => {
          if (response["success"]) {
            this.authStorageService.setToken(response['token']);
            this.authService.setCurrentUser().then(result => {
              let currentUserRoles = this.authService.getCurrentUserRole()
              if (currentUserRoles.includes('COMMON_ACCESS')) {
                this.router.navigate(['dashboard']);
              } else if (currentUserRoles.includes('STAFF_ACCESS')) {
                this.router.navigate(['dashboard']);
              } else {
                this.router.navigate(['login']);
              }
            }).catch(err => {
              this.flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger  on-top' });
              this.authStorageService.removeToken();
              this.router.navigate(['login']);

            })
          } else {
            if (response["result"] == 'Invalid IP') {
              this.flashMessagesService.show("Your IP  doesn't have access to the website! Please contact administrator.", { cssClass: 'alert-danger  on-top' });
            } else {
              this.flashMessagesService.show('Login failed!', { cssClass: 'alert-danger  on-top' });
            }
            this.loginForm.markAsPristine();
            this.errorMessage = response["message"];
          }
        },
        (error: any) => {
          this.flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger  on-top' });
        }
      );

    }
  }

  // function to logout( clears session token also redirtedcts to login)
  logout() {
    this.authService.logout().subscribe((res: any) => {
    });
    this.authStorageService.removeToken();
    this.authStorageService.removePermissions();
  }

  otpVerifyOption(type: string) {
    if (type == 'email') {
      this.emailVerify = true;
    } else {
      this.emailVerify = false;
    }
    if (type == 'phone') {
      this.phoneVerify = true;
    } else {
      this.phoneVerify = false;
    }
  }

}
