import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/services/api.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-zone-check-request-view',
  templateUrl: './zone-check-request-view.component.html',
  styleUrls: ['./zone-check-request-view.component.scss']
})
export class ZoneCheckRequestViewComponent implements OnInit {

  @Input() id: number;
  request;

  assignedUsers: string;
  zoneCheckText: string;
  zoneText: string;
  constructor(private apiService: ApiService, private router: Router, private modal: NgbActiveModal, private _flashMessagesService: FlashMessagesService) { }

  ngOnInit() {
    // if (environment.appName == "East Ridge") {
    //   this.zoneCheckText = "Watch";
    //   this.zoneText = "Watch";
    // }
    // else if (environment.appName == "MILTON") {
    //   this.zoneCheckText = "Vacation Check";
    //   this.zoneText = "Vacation";
    // }
    // else {

      this.zoneCheckText = environment.zoneText;

      // this.zoneCheckText = "Zone Check";
      this.zoneText = this.zoneCheckText.replace('Check', '');
    // }
    let putData = {};
    if (!this.request.read) {
      this.apiService.postRequest('admin/zone-check-read/' + this.request.id, putData).subscribe(
        (res: any) => {
          if (res.success) {
            this.apiService.zoneCheckCount.next(res.unseen_zone_check_count);
          }
        },
        err => {
          console.log(err);
        },
      );
    }
    this.getZoneCheckRequest();
  }
  closeModal(result?: any) {
      this.modal.close();
  }
  formatDateTime(date) {
    if (!date) {
      return "";
    }
    if (!(moment(date).isValid())) {
      return "";
    }
    // return moment(date).tz("America/New_York").format('MM/DD/YYYY hh:mm:ss A');
    return moment(moment.utc(date).toDate()).local().format('MM/DD/YYYY');
  }
  changeApprovalStatus(status) {
    let postData = {
      'approval_status': status
    }
    this.apiService.postRequest('admin/zone-check-request/'+this.request['id'], postData).subscribe(
      (response: any) => {
        if (response.success) {
          this._flashMessagesService.show(this.zoneCheckText + ' Request Approval Status Updated Successfully!', { cssClass: 'alert-success' });
          
        } else {
          this._flashMessagesService.show(this.zoneCheckText + ' Request Approval Status Update Failed!!', { cssClass: 'alert-danger' });
        }
        this.closeModal();
      },
      (err: any) => console.log(err),
    );
  }

  getZoneCheckRequest(){
    this.apiService.getRequest('zone-check-request/' + this.request['id']).subscribe(
      (response: any) => {
        if (response.success) {
          this.assignedUsers = response.data.assignedUsers.split(',');
        }
      },
      (err: any) => console.log(err),
    );
  }
}
