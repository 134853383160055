import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../upload-adapter";
import { Location } from '@angular/common';

@Component({
  selector: 'hopkinsville-police',
  templateUrl: './hopkinsville-police.component.html',
  styleUrls: ['./hopkinsville-police.component.scss']
})
export class HopkinsvillePoliceComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  images = [];
  link: string  = "";
  link_name: string  = "";
  name: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link','|','imageUpload']
  };    
  @Input() pageTitle: string ;
  @Input() dataUrl: string ;
  @Input() postUrl: string ;
  apiLink:any;
  @Input() backUrl: string ;

  constructor(private location: Location, private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    if(!this.dataUrl) {
      this.router.navigate(['content-pages']);
    }
    this.getPolice();
    this.createPageForm = this.formBuilder.group({
      'name': ['', Validators.required],
      'desc': ['', Validators.required],
      // 'image': [''],
      // 'file': [''],
      'link': ['', Validators.required],
      'link_name': ['']
    });
    // if(!this.pageTitle) {
    //   this.pageTitle = "Know Your Department";
    // }
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  // updateKey (eventData) {
  //   let key = eventData.toLowerCase( ).replace(' ', '_');
  //   this.createPageForm.controls['page_key'].setValue(key);
  // }
  changeType(eventData) {
    console.log(eventData);
  }
  
  getPolice () {
    // if(this.dataUrl) {
      this.apiLink = this.dataUrl;
    // } else{
    //   this.apiLink = 'admin/standard-page?key=know_your_department'
    // }
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          this.desc = response.data.desc;
          this.link = response.data.link;
          this.link_name = response.data.link_name;
          // this.url = response.data.image_url;
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': this.createPageForm.value.name,
        'desc': this.createPageForm.value.desc,
        'link': this.createPageForm.value.link,
        'link_name': this.createPageForm.value.link_name,
      }
      // if(!this.postUrl) {
      //   this.postUrl = 'admin/generic-page?key=know_your_department'
      // }
      this.apiService.postRequest(this.postUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
                this.errorMessage = null;
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    // if (!this.name) {
      this.router.navigate(['content-pages']);
    // } else {
    //   this.location.back();
    // }
  }
}
