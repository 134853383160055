import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { Location } from '@angular/common';

@Component({
  selector: 'app-manage-app-pages-create',
  templateUrl: './manage-app-pages-create.component.html',
  styleUrls: ['./manage-app-pages-create.component.scss']
})
export class ManageAppPagesCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  images = [];
  url: string  = "";
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    
  fileName;
  constructor(private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
        this.createPageForm = this.formBuilder.group({
          'name': ['', Validators.required],
          'page_key': ['', Validators.required],
          'type': ['standard', Validators.required],
          'desc': [''],
          'image': [''],
          'files': [''],
          'link': [''],
          'link_name': ['']
        });
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  updateKey (eventData) {
    let key = eventData.toLowerCase( ).replace(' ', '_');
    this.createPageForm.controls['page_key'].setValue(key);
  }
  changeType(eventData) {
    console.log(eventData);
  }
  // onFileChange(event) {
  //   if (event.target.files && event.target.files[0]) {
  //       var filesAmount = event.target.files.length;
  //       for (let i = 0; i < filesAmount; i++) {
  //         var reader = new FileReader();
  //         reader.onload = (event:any) => {
  //           this.images.push(event.target.result);
  //           this.createPageForm.patchValue({
  //             fileSource: this.images
  //           });
  //         }
  //         reader.readAsDataURL(event.target.files[i]);
  //       }
  //   }
  // }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.files[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': this.createPageForm.value.name,
        'page_key': this.createPageForm.value.page_key,
        'type': this.createPageForm.value.type,
        'desc': this.createPageForm.value.desc,
        'image': this.url,
        'link': this.createPageForm.value.link,
        'link_name': this.createPageForm.value.link_name,
      }
      this.apiService.postRequest('admin/app-page', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this.router.navigate(['app-page']);
                this._flashMessagesService.show('Page Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Page Creation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

}
