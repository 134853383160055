import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AuthStorageService } from '../services/auth.storage.service';

@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private authStorageService: AuthStorageService) { }

  canActivate(route: ActivatedRouteSnapshot){
    return this.authService.isUserPermitted(route.data.allowedRoles.split(','));
  }
}
