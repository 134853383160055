import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'most-wanted-create',
  templateUrl: './most-wanted-create.component.html',
  styleUrls: ['./most-wanted-create.component.scss']
})
export class MostWantedCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  images = [];
  url: string  = "";
  key:string;
  name: string;
  // link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    

  allFilesUploaded=[];
  stringfiedAttachments = '';
  fileToUpload: File = null;
  uploadedDoc = false;
  imagestobeDeleted=[];
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;
  @Input() pageTitle: string ;
  @Input() createUrl: string ;
  apiLink:any;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    // if(!this.pageTitle) {
    //   this.pageTitle = "Most Wanted";
    // }
    this.createPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      // 'image': [''],
      // 'link': ['',Validators.required],
      // 'link_name': ['']
      'images': [''],
      'document': [''],
      'desc': [''],
    });
  }

  updateKey (eventData) {
    this.key = eventData.toLowerCase( ).replace(' ', '_');
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.files[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': this.createPageForm.value.name,
        // 'image': this.url,
        'images': this.allFilesUploaded,
        'images_to_be_deleted': this.imagestobeDeleted,
        'desc': this.createPageForm.value.desc,
        'type' : this.key
      }
      // if(!this.isStatic) {
      //   postData['type'] = this.key
      // } else {
      //   postData['type'] = 'most-wanted'
      // }
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      // if(this.createUrl) {
      //   this.apiLink = this.createUrl;
      // } else{
      //   this.apiLink = 'admin/standard-page?key=' + this.key;
      // }
      this.apiService.postRequest(this.createUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                // if(!this.isStatic) {
                  this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                // } else {
                //   this.router.navigate(['most-wanted']);
                // }
              }
              // this.router.navigate(['most-wanted']);
                this._flashMessagesService.show('Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.createPageForm.markAsPristine();
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  onImageAttachments(files: FileList){
    for (var i = 0; i < files.length; i += 1) {
      this.fileToUpload = files.item(i);
      let postD = new FormData();
      postD.append('image', this.fileToUpload);
      this.apiService.postRequest('admin/app-image',  postD).subscribe(
        (response: any) => {
        if(response.success){
          let resposeData = {
            'name': response.file_name,
            'image_link': response.path,
            'image': response.image,
            'id': null,
          }
          this.allFilesUploaded.push(resposeData);
        } else {
        }
        },
        (error: any) => {
        }
      );
    }
  }
  removeIndex(index) {
    this.allFilesUploaded.splice(index, 1);
  }

  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/app-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }

}
