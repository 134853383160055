import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TemplateListComponent} from "../../template-list/template-list.component";
import { Location } from '@angular/common';
import {MatTooltipModule} from '@angular/material';
import { AuthService } from '../../shared/services/auth.service';


@Component({
  selector: 'app-alert-create',
  templateUrl: './alert-create.component.html',
  styleUrls: ['./alert-create.component.scss']
})
export class AlertCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createAlertForm: FormGroup;
  errorMessage = null;
  showError = false;
  facebookLinked = false;
  instagramLinked = false;
  twitterLinked = false;
  linkedinLinked = false;
  hasSocialMediaAcess = false;
  confianzAdmin: boolean = false;
  selectedTemp = "";
  clicked :boolean = false;
  templates:any;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link','|','imageUpload']
  };   
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isImageUploaded = false;
  isInstaImageUploaded = false;
  imageUploaded = {
    'image_name': null,
    'image_link': null,
    'thumbnail': null,
  };
  instaImageUploaded = {
    'image_name': null,
    'image_link': null,
    'instaImage': null,
  };
  postToMedia :boolean = false;
  postToInsta :boolean = false;
  imageToUpload: File = null;
  isDocUploaded = false;
  fileToUpload: File = null; 
  isAlertSubmitted: boolean = false;

  showZipNote: boolean = false;

  @ViewChild('confirmModal', { static: false }) accoViewModal: TemplateRef<any>;
  accoModalRef: any;

  constructor(private authService: AuthService,private dialog: MatDialog, private location: Location, private modal: NgbModal,private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
      let user = this.authService.getCurrentUser();
      let specialPermissions = this.authService.getUserSpecificRole(); 
      let permissions = user.permission_list;
      this.hasSocialMediaAcess = specialPermissions.indexOf("SOCIAL_MEDIA_ALLOW") !== -1;
      this.createAlertForm = this.formBuilder.group({
        'title': ['', Validators.required],
        'description': ['', Validators.required],
        'expiryDate': [''],
        'name': [''],
        'test_alert': [false],
        'template' : [''],
        'document' : [''],
        'facebook' : [''],
        'instagram' : [''],
        'twitter' : [''],
        'linkedin' : [''],
        'thumbnail': [''],
        'instagram_image': [''],
        'zipcode' : [''],
      });
      this.getTemplates();
      this.checkMediaTokens();
      this.createAlertForm.controls['facebook'].setValue(false); 
      this.createAlertForm.controls['instagram'].setValue(false); 
      this.createAlertForm.controls['twitter'].setValue(false);  
      this.createAlertForm.controls['linkedin'].setValue(false);  
      this.confianzAdmin = permissions.indexOf("CONFIANZ_ADMIN") !== -1;    
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }

  viewTemplates(id: any) {
    const modalRef = this.modal.open(TemplateListComponent);
  }
  getTemplates () {
    this.apiService.getRequest('admin/template').subscribe(
      (response: any) => {
        if(response.success) {
          this.templates = response.data;
        }
      },
      (err: any) => console.log(err),
    );
  }
  copyTemplate () {
    for (let obj of this.templates) {
      if (obj.id == this.selectedTemp) {
        this.createAlertForm.controls['description'].setValue(obj.description);
        this.createAlertForm.controls['title'].setValue(obj.name);
      }
    }
    this.closeDialog();
  }
  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  onEnableMedia (event) {
    if (event.target.checked) {
      this.postToMedia = true;
    }
    if (!this.createAlertForm.value.facebook && !this.createAlertForm.value.twitter && !this.createAlertForm.value.linkedin) {
      this.postToMedia = false;
    }
    if (this.createAlertForm.value.instagram) {
      this.postToInsta = true;
      this.createAlertForm.controls['instagram_image'].setValidators([Validators.required]);
    }
    else{
      this.postToInsta = false;
    }
  }
  saveTemplate() {
    this.clicked = true;
    if (this.createAlertForm.value.name) {
      this.showError = false;
      let postdata = {
        "name" : this.createAlertForm.value.name,
        "description" : this.createAlertForm.value.description
      }
      this.apiService.postRequest('admin/template', postdata).subscribe(
        (response: any) => {
            if (response.success) {
              this.closeDialog();
              this.getTemplates();
              this._flashMessagesService.show('Template Saved!', { cssClass: 'alert-success' });
            } else {
              this.closeDialog();
              this._flashMessagesService.show('Template Save Failed!!', { cssClass: 'alert-danger' });
            }
            this.clicked = false;
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    console.log(postdata);
    } else {
      this.showError = true;
    }
  }
  checkMediaTokens(){
    this.apiService.getRequest('admin/accounts-statuses').subscribe(
      (response: any) => {
        if(response.success) {
          this.facebookLinked = response.data.facebook;
          this.instagramLinked = response.data.facebook;
          this.twitterLinked = response.data.twitter;
          this.linkedinLinked = response.data.linkedin;
          if (this.facebookLinked || this.twitterLinked || this.linkedinLinked) {
            this.postToMedia = true;
            this.facebookLinked && this.hasSocialMediaAcess ? this.createAlertForm.controls['facebook'].setValue(true) : this.createAlertForm.controls['facebook'].setValue(false); 
            this.createAlertForm.controls['instagram'].setValue(false);
            this.twitterLinked && this.hasSocialMediaAcess ? this.createAlertForm.controls['twitter'].setValue(true) : this.createAlertForm.controls['twitter'].setValue(false);
            this.linkedinLinked && this.hasSocialMediaAcess ? this.createAlertForm.controls['linkedin'].setValue(true) : this.createAlertForm.controls['linkedin'].setValue(false);
          }
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    console.log(this.createAlertForm.value.facebook);
    this.isAlertSubmitted = true;
    if (this.createAlertForm.valid) {
      let postData = {
        'title': this.createAlertForm.value.title,
        'description': this.createAlertForm.value.description,
        'expiry_date': this.createAlertForm.value.expiryDate,
        'facebook' : this.createAlertForm.value.facebook,
        'instagram' : this.createAlertForm.value.instagram,
        'twitter' : this.createAlertForm.value.twitter,
        'linkedin' : this.createAlertForm.value.linkedin,
        "test_alert" : this.createAlertForm.value.test_alert,
        'thumbnail' : this.imageUploaded['thumbnail'] ? this.imageUploaded['thumbnail'] : null,
        'instagram_image' : this.instaImageUploaded['instaImage'] ? this.instaImageUploaded['instaImage'] : null,
        'zipcode' : this.createAlertForm.value.zipcode
      }
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      this.apiService.postRequest('admin/public-alert', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this.closeModal();
                this.router.navigate(['public-alert']);
                this._flashMessagesService.show(response.message ? response.message : 'Alert Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this.closeModal();
                this._flashMessagesService.show('Alert Creation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
                this.isAlertSubmitted = false;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onImageUpload(files: FileList){
    let file = files.item(0);
    this.imageToUpload = file;
    let postD = new FormData();
    postD.append('image', this.imageToUpload);
    this.apiService.postRequest('admin/app-image',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.imageUploaded = {
          'image_name': response.file_name,
          'image_link': response.path,
          'thumbnail': response.image,
        }
        this.isImageUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeImage() {
    this.isImageUploaded = false;
    this.imageUploaded = {
      'image_name': null,
      'image_link': null,
      'thumbnail': null,
    };
  }
  onInstaImageUpload(files: FileList){
    let file = files.item(0);
    this.imageToUpload = file;
    let postD = new FormData();
    postD.append('image', this.imageToUpload);
    this.apiService.postRequest('admin/insta-image',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.instaImageUploaded = {
          'image_name': response.file_name,
          'image_link': response.path,
          'instaImage': response.image,
        }
        this.isInstaImageUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeInstaImage() {
    this.isInstaImageUploaded = false;
    this.instaImageUploaded = {
      'image_name': null,
      'image_link': null,
      'instaImage': null,
    };
  }
  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/upload-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    this.location.back();
  }
  confirmationPopup() {
    this.accoModalRef = this.modal.open(this.accoViewModal);
  }
  closeModal(result?: any) {
    this.accoModalRef.close();
  }
  onMouseEnter() {
    this.showZipNote = true;
  }
  onMouseLeave() {
    this.showZipNote = false;
  }
}
