import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { UploadAdaptor } from "../../upload-adapter";
import { ValidationService } from '../../shared/services/validation.service';
import { MustMatch } from '../../validator';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';  // Import map from rxjs/operators

@Component({
  selector: 'app-dynamic-content-management-create',
  templateUrl: './dynamic-content-management-create.component.html',
  styleUrls: ['./dynamic-content-management-create.component.scss']
})
export class DynamicContentManagementCreateComponent implements OnInit {
  dropdownList = [];
  dropdownSettings = {};
  pageCreateForm: FormGroup;
  url = '';
  templeteUrl = '';
  errorMessage = null;
  destinationComponent = '';
  isImageUploaded = false;
  imageObject: any;
  description: string = '';

  constructor(private location: Location,private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder, private http: HttpClient) { }

  ngOnInit() {
    this.dropdownList = [];
    this.dropdownSettings = { 
      singleSelection: true, 
      text:"Select Template",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };

    this.apiService.getRequest('admin/content-templates').subscribe(
      (response: any) => {
        if(response.success) {
          var templateList = response.data;
          templateList.forEach((template: any) => {
            this.dropdownList.push({"id":template.id,"itemName":template.title});
            // this.dropdownList.push({"id":template.id,"title":template.title, "image": template.images, "thumbImage": template.images, alt: 'Image alt'});
          });
        }
      },
      (err: any) => console.log(err),
    );

    this.pageCreateForm = this.formBuilder.group({
      title: ['', Validators.required],
      template: ['', Validators.required],
      icon: [''],
    },
    {});
  }

  chooseTemplate(event) {
    this.apiService.getRequest('admin/get-page-template/'+ event.target.value).subscribe(
      (response: any) => {
          if (response.success) {
            var template = response.data;
            this.url = template.icon;
            this.templeteUrl = this.url;
            this.description = template.description;
            this.getImages(event.target.value).subscribe(images => {
              if (images && images.length > 0) {
                this.imageObject = images.map((img, index) => ({
                  image: `assets/template-images/${event.target.value}/${img}`,    // Full-size image
                  thumbImage: `assets/template-images/${event.target.value}/${img}`, // Thumbnail (could be the same as image)
                  alt: template.title,    // Alt text
                  // title: template.title,
                }));
              } else {
                console.error('No images found or folder is empty');
              }
            });
            
            this.destinationComponent = template.redirect_url;
            document.getElementById('icon-container').style.display = 'block';
            document.getElementById('prvw-container').style.display = 'block';
            // this.imageObject.push({"id":templateImageItem.id,"title":templateImageItem.title, "image": templateImageItem.images, "thumbImage": templateImageItem.images, alt: 'Image alt'});
          }
      },
      err => {
          console.log(err);
      },
    );
  }

  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('icon',event.target.files[0]);
      this.apiService.postRequest('admin/upload-template-icon', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
              this.isImageUploaded = true;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }

  onSubmit() {
    if (this.pageCreateForm.valid) {
      let postData = {
        'title': this.pageCreateForm.value.title,
        'template': this.pageCreateForm.value.template,
        'icon': this.url,
      }
      this.apiService.postRequest('admin/save-content-page', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show("Created Successfully!", { cssClass: 'alert-success' });
                this.errorMessage = null;
                var content = response.data;
                var singlePages = ["KnowYourDepartmentComponent","CodesAndOrdinanceComponent","VictimsServicesComponent","InmateInfoComponent",
                "PraiseOrComplaintsListComponent","HopkinsvillePoliceComponent"];
                if(singlePages.includes(content.template_name)) {
                    this.router.navigate(['content-pages/',content.id + "-" + content.slug]);
                } else {
                    this.router.navigate(['content-pages/data/create/',content.id + "-" + content.slug]);
                }
                // location.reload();
              } else {
                this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

  removeImage() {
    this.url = this.templeteUrl;
    this.isImageUploaded = false;
  }

  
  getImages(folder: string): Observable<string[]> {
    console.log('folder =====',folder);
    return this.http.get<any>(`assets/image-manifest.json`).pipe(
      map(manifest => {
        console.log('manifest =====', manifest); // Log the manifest to ensure it is loaded

        // Check if manifest and the folder key exist
        if (!manifest) {
          console.error('Manifest is undefined or null');
          return [];
        }

        if (!manifest[folder]) {
          console.error(`Folder '${folder}' not found in manifest`);
          return [];
        }

        return manifest[folder];
      }),
      catchError(error => {
        console.error('Error loading manifest:', error);
        return of([]); // Return an empty array on error
      })    );
  }
}