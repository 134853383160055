import { Component, OnInit, ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver  } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceListComponent } from '../../resources/resource-list/resource-list.component';
import { KnowYourDepartmentComponent } from '../../know-your-department/know-your-department.component';
import { MostWantedListComponent } from '../../most-wanted/most-wanted-list/most-wanted-list.component';
import { CrimePreventionListComponent } from '../../crime-prevention/crime-prevention-list/crime-prevention-list.component';
import { EmploymentListComponent } from '../../employment/employment-list/employment-list.component';
import { CodesAndOrdinanceComponent } from '../../codes-and-ordinance/codes-and-ordinance.component';
import { VictimsServicesComponent } from '../../victims-services/victims-services.component';
import { InmateInfoComponent } from '../../inmate-info/inmate-info.component';
import { DepartmentListComponent } from '../../departments/department-list/department-list.component';
import { PermitListComponent } from '../../permits/permits-list/permits-list.component';
import { PraiseOrComplaintsListComponent } from '../../praise-or-complaints/praise-or-complaints-list/praise-or-complaints-list.component';
import { EventListComponent } from '../../events/event-list/event-list.component';
import { HopkinsvillePoliceComponent } from '../../hopkinsville-police/hopkinsville-police.component';

@Component({
  selector: 'app-dynamic-content-management-pages',
  templateUrl: './dynamic-content-management-pages.component.html',
  styleUrls: ['./dynamic-content-management-pages.component.scss']
})
export class DynamicContentManagementPagesComponent {
  apiLink:any;
  content:any;
  targetComponent : string;
  targetTitle : '';
  componentFactory : any;
  dataUrl : any;
  postUrl : any;
  createUrl : any;
  editUrl : any;
  paginateUrl : any;
  backUrl : any;

  // @ViewChild('dynamic', { read: ViewContainerRef, static: false })
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: false }) dynamicComponentContainer: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  constructor(private resolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
    this.apiLink = 'admin/get-content-page/' + params['slug'];
    this.apiService.getRequest(this.apiLink).subscribe(
        (response: any) => {
          if(response.success) {
            this.content = response.data;
            this.targetComponent = this.content.template_name;
            this.targetTitle = this.content.title;
            this.dynamicComponentContainer.clear();
            this.dataUrl = 'admin/get-page-data/' + this.content.id;
            this.postUrl = 'admin/update-page-data/' + this.content.id;
            this.createUrl = '/content-pages/data/create/'  +  this.content.id + '-' +  this.content.slug;
            this.editUrl = '/content-pages/data/edit/';
            this.paginateUrl = 'content-pages/' +  this.content.id + '-' +  this.content.slug;
            this.backUrl = '/pages/' +  this.content.id + '-' +  this.content.slug 
            switch(this.targetComponent) {
              case 'ResourceListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(ResourceListComponent);
                break;
              }
              case 'KnowYourDepartmentComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(KnowYourDepartmentComponent);
                break;
              }
              case 'CrimePreventionListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(CrimePreventionListComponent);
                break;
              }
              case 'EmploymentListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(EmploymentListComponent);
                break;
              }
              case 'CodesAndOrdinanceComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(CodesAndOrdinanceComponent);
                break;
              }
              case 'VictimsServicesComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(VictimsServicesComponent);
                break;
              }
              case 'InmateInfoComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(InmateInfoComponent);
                break;
              }
              case 'DepartmentListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(DepartmentListComponent);
                break;
              }
              case 'PermitListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(PermitListComponent);
                break;
              }
              case 'MostWantedListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(MostWantedListComponent);
                break;
              }
              case 'PraiseOrComplaintsListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(PraiseOrComplaintsListComponent);
                break;
              }
              case 'EventListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(EventListComponent);
                break;
              }
              case 'HopkinsvillePoliceComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(HopkinsvillePoliceComponent);
                break;
              }
            }
            this.componentRef = this.dynamicComponentContainer.createComponent(this.componentFactory);
            this.componentRef.instance.pageTitle = this.targetTitle;
            this.componentRef.instance.contentId = this.content.id;
            this.componentRef.instance.dataUrl = this.dataUrl;
            this.componentRef.instance.postUrl = this.postUrl;
            this.componentRef.instance.createUrl = this.createUrl;
            this.componentRef.instance.editUrl = this.editUrl;
            this.componentRef.instance.paginateUrl = this.paginateUrl;
            this.componentRef.instance.backUrl = this.backUrl;
          }
        },
        (err: any) => console.log(err),
      );
    });
  }

}
