import { Component, OnInit, TemplateRef, ViewChild,ViewChildren,ElementRef,QueryList,ComponentFactoryResolver, ComponentRef, ViewContainerRef, inject } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { AuthStorageService } from '../shared/services/auth.storage.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatDialog } from '@angular/material';
import { UploadAdaptor } from '../upload-adapter';
import { MatChipInputEvent} from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  username: string;
  daysFilter: number = 180;
  public_tips: number = 0;
  genuine_tips: number = 0;
  public_alerts: number = 0;
  staff_alerts: number = 0;
  total_staffs: number = 0;
  total_admins: number = 0;
  ios_apps:number = 0;
  android_apps:number = 0;
  total_licenses:number = 0;
  resource_links: any;
  marketing_materials: any;
  email_templates: any;
  uploadSubmitted: boolean = false;
  dashboardTrainingResourceForm: FormGroup;
  resourceModalRef: any;
  resourceViewModalRef: any;
  resourceVideo: string = '';
  resourceTitle: string = '';
  resourceType: string = '';
  fileToUpload: File = null;
  popoverTitle: string = 'Confirm Remove Resource';
  popoverMessage: string = 'Are you sure?';
  confianzAdmin: boolean = false;
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;
  marketingPdf : string = null;

  @ViewChild('resourceModal', {static: false}) resourceModal : TemplateRef<any>;
  @ViewChild('resourceViewModal', { static: false }) resourceViewModal: TemplateRef<any>;
  isPromotionStarted: boolean = false;  
  isResume: boolean = false;  
  startTitle: string = 'Confirm Start';
  startMessage: string = 'Are you sure?';
  page = 1;
  posters:any;
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  posterId: any;  
  posterImage: any;  
  posterTitle: any;  
  posterContent: any;  
  posterModalRef: any;
  posterScheduledDate: any;
  posterPostedDate: any;
  @ViewChild('confirmModal2', { static: false }) accoViewModal2: TemplateRef<any>;
  @ViewChild('confirmModal', { static: false }) accoViewModal: TemplateRef<any>;
  @ViewChild('posterViewModal', { static: false }) posterViewModal: TemplateRef<any>;
  @ViewChildren('posterImages') posterImages: QueryList<ElementRef>;
  slideIndex = 0;
  week = 1;
  day = 1;
  noNext : boolean = false;
  noPrev : boolean = false;
  accoModalRef: any;
  accoModalRef2: any;
  emailAddresses: File = null;
  public Editor = ClassicEditor;
  clicked :boolean = false;
  templates:any;
  ckConfig = {
    toolbar: ['heading', '|',
              'bold', 'italic','underline', 'link','|',
              'imageUpload', '|',
              'numberedList', 'bulletedList', '|',
              'blockQuote', '|',
              'undo', 'redo'
            ]
  };   
  selectedTemp = "";
  items = [];
  mailViewModalRef: any;
  mailSubmissionForm: FormGroup;
  isMailImported: boolean = false;
  mailImport = {
    'file_name': null,
  };
  errorMessage = null;
  mailAddessError = null;

  // pagination
  searchTerm: string = "";
  apiLink:any;
  itemsPerPage = 10;
  currentPage = 1;
  filteredItems = [];
  isNotEmailTemp: boolean = false;
  linkedAnySocialMedia : boolean = false;

  @ViewChild('mailViewModal', { static: false }) mailViewModal: TemplateRef<any>;

  constructor( private sanitizer: DomSanitizer, private viewContainerRef: ViewContainerRef,private resolver: ComponentFactoryResolver, private authService: AuthService, private modal: NgbModal, private formBuilder: FormBuilder,
    private authStorageService: AuthStorageService, private router: Router, private apiService: ApiService, 
    private _flashMessagesService: FlashMessagesService, private dialog: MatDialog, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.getDashboardData();
    this.getCurrentUser();
    this.resourceType = 'trainingResource';
    this.getSocialMediaPromotions();
    this.getTemplates();
    this.checkMediaTokens();
  }

  checkMediaTokens(checklinked = false){
    let route = '';
    if (checklinked) {
      route = 'admin/accounts-statuses?checklinked=true';
    } else {
      route = 'admin/accounts-statuses';

    }
    this.apiService.getRequest(route).subscribe(
      (response: any) => {
        if(response.success) {
          let facebookLinked = response.data.facebook;
          let twitterLinked = response.data.twitter;
          let linkedinLinked = response.data.linkedin;
          if(facebookLinked || twitterLinked || linkedinLinked) {
            this.linkedAnySocialMedia = true;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }

  nextSlide() {
    this.slideIndex = (this.slideIndex + 1) % this.posters.length;
    if(this.slideIndex >= (this.posters.length-1)) {
      this.noNext = true;
    } 
    this.noPrev = false;
    this.day += 2; 
    if(this.day > 5) {
      this.day = 1;
      this.week += 1;
    }
    if(this.week >= (this.posters.length/3)) {
      this.week = Math.round(this.posters.length/3);
    }
  }

  // Function to move to the previous slide
  prevSlide() {
    this.slideIndex = (this.slideIndex - 1 + this.posters.length) % this.posters.length;
    if(this.slideIndex < 1) {
      this.noPrev = true;
    } 
    this.noNext = false;
    this.day -= 2; 
    if(this.day < 1) {
      this.day = 5; 
       this.week -= 1;
    }
    if(this.week < 1) {
      this.week = 1;
    }
  }
  closeModal(result?: any) {
    this.posterModalRef.close();
  }
  getSocialMediaPromotions() {
    this.apiService.getRequest('admin/get-social-media-promotion').subscribe(
      (response: any) => {
        if(response.success) {
          this.isPromotionStarted = response.enabled_sns_promotion;
          this.posters = response.data;
          this.isResume = response.started;
          this.marketingPdf = response.pdf;
          // this.slides = response.imageArray;

          for (let i = 0; i < this.posters.length; i++) {
            this.posters[i]['image'] = this.sanitizer.bypassSecurityTrustHtml(this.posters[i]['view']);
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }
  startSocialMediaPromotion() {
    this.closeModalPopup();
    this.apiService.getRequest('admin/start-social-media-promotion').subscribe(
      (response: any) => {
        if(response.success) {
          this.isPromotionStarted = true;
          this._flashMessagesService.show('Social media promotion started successfully', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Unable to start Social media promotion!', { cssClass: 'alert-danger' });
        }
        this.getSocialMediaPromotions();
      },
      (err: any) => console.log(err),
    );
  }
  
  stopSocialMediaPromotion() {
    this.closeModalPopup2();
    this.apiService.getRequest('admin/stop-social-media-promotion').subscribe(
      (response: any) => {
        if(response.success) {
          this.isPromotionStarted = false;
          this.isResume = true;
          this._flashMessagesService.show('Social media promotion paused successfully', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Unable to pause Social media promotion!', { cssClass: 'alert-danger' });
        }
        this.getSocialMediaPromotions();
      },
      (err: any) => console.log(err),
    );
  }
  // getPostersListPage(pageNumber: any) {
  //   this.router.navigate(['social-media-promotions/posters', {page:pageNumber}]);
  // }
  /*
  function to get the current logged in user/user details
  */
  getCurrentUser() {
    let userdata = this.authService.getCurrentUser();
    let permissions = userdata.permission_list;
    this.username = userdata.first_name + " " + userdata.last_name;
    this.confianzAdmin = permissions.indexOf("CONFIANZ_ADMIN") !== -1;
  }
  /*
  function to logout( clears session token also redirtedcts to login)
  */
  logout() {
    this.authService.logout().subscribe((res: any) => {
    })
    this.authStorageService.removeToken();
    this.authStorageService.removePermissions();
    this.router.navigate(['login']);
  }
  /*
  function to get the dashboard data
  */
  getDashboardData() {
    this.apiService.getRequest('admin/dashboard?daysfilter=' + this.daysFilter).subscribe(
      (response: any) => {
        if(response.success) {
          this.public_tips = (response.data.public_tips) ? response.data.public_tips : 0;
          this.genuine_tips = (response.data.genuine_tips) ? response.data.genuine_tips : 0;
          this.public_alerts = (response.data.public_alerts) ? response.data.public_alerts : 0;
          this.staff_alerts = (response.data.staff_alerts) ? response.data.staff_alerts : 0;
          this.total_staffs = (response.data.total_staffs) ? response.data.total_staffs : 0;
          this.total_admins = (response.data.total_admins) ? response.data.total_admins : 0;
          this.ios_apps = (response.data.ios_apps) ? response.data.ios_apps : 0;
          this.android_apps = (response.data.android_apps) ? response.data.android_apps : 0;
          this.total_licenses = (response.data.total_licenses) ? response.data.total_licenses : 0;
          this.resource_links = (response.data.resource_links) ? response.data.resource_links : null;
          this.marketing_materials = (response.data.marketing_materials) ? response.data.marketing_materials : null;
          this.email_templates = (response.data.email_templates) ? response.data.email_templates : null;
          this.filteredItems = this.email_templates;
        }
      },
      (err: any) => console.log(err),
    );
  }

  daysFilterChanged(days) {
    this.daysFilter = days;
    this.getDashboardData();
  }

  addTrainingResource() {
    this.dashboardTrainingResourceForm = this.formBuilder.group({
      title: ['', Validators.required],
      resource_type: ['trainingResource', Validators.required],
      email_template: [''],
      resource: ['', Validators.required],
      subject: [''],
      template : [''],
      emailId: [''],
      importEmail: ['']
    },
    {});
    if(this.selectedTemp) {
      this.selectedTemp = "";
    }
    this.resourceType = 'trainingResource';
    this.removeDocument();
    this.getTemplates();
    this.resourceModalRef = this.modal.open(this.resourceModal,{ windowClass: 'my-own-styles' });
  }
  closeTrainingResource() {
    this.resourceType = 'trainingResource';
    this.resourceModalRef.close();
  }

  viewResource(resource) {
    this.resourceVideo = resource.resource_url;
    this.resourceTitle = resource.title;
    this.resourceViewModalRef = this.modal.open(this.resourceViewModal);
  }
  closeResourceView() {
    this.resourceViewModalRef.close();
  }

  onResourceUpload(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadResource() {
    if (this.dashboardTrainingResourceForm.valid) {
      this.uploadSubmitted = true;
      console.log(this.dashboardTrainingResourceForm.value);
      const postData = new FormData();
      postData.append('title', this.dashboardTrainingResourceForm.value.title);
      postData.append('resource_type', this.dashboardTrainingResourceForm.value.resource_type);
      postData.append('email_template', this.dashboardTrainingResourceForm.value.email_template);
      postData.append('subject', this.dashboardTrainingResourceForm.value.subject);
      postData.append('emailId', this.dashboardTrainingResourceForm.value.email_id);
      postData.append('importEmail', this.emailAddresses);

      // postData.append('size', this.dashboardTrainingResourceForm.value.size);
      postData.append('resource_name', this.documentUploaded['doc_name']);
      postData.append('resource', this.documentUploaded['document']);
        this.apiService.postRequest('admin/training-resource', postData).subscribe(
          (response: any) => {
              this.uploadSubmitted = false;
              if (response.success) {
                  this.getDashboardData();
                  this.closeTrainingResource();
                  this._flashMessagesService.show('Training Resource Added Successfully', { cssClass: 'alert-success' });
                  this.resourceType = 'trainingResource';
                  this.searchTerm = "";
              } else {
                  this.closeTrainingResource();
                  this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              }
          },
          err => {
              console.log(err);
              this.closeTrainingResource();
              this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          },
        );
    }
  }

  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let email_temp = this.dashboardTrainingResourceForm.value.email_template;
    let subject = this.dashboardTrainingResourceForm.value.subject;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/upload-training',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        if (document.getElementById('nav-email-tab')) {
          document.getElementById('nav-email-tab').classList.add('disabled');
        }
        if (this.resourceType == 'trainingResource') {
          document.getElementById('marketingMaterial')['disabled'] = true;
        }
        else{
          document.getElementById('trainingResource')['disabled'] = true;
        }
        if (email_temp || subject) {
          document.getElementById('nav-upload-tab')['disabled'] = true;
        }
        
        this.isDocUploaded = true;
        this.isNotEmailTemp = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }

  removeDocument() {
    this.isDocUploaded = false;
    this.isNotEmailTemp = false;

    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
    if (document.getElementById('nav-email-tab')) {
      document.getElementById('nav-email-tab').classList.remove('disabled');
    }
    if (this.resourceType == 'trainingResource') {
      if (document.getElementById('trainingResource')) {
        document.getElementById('marketingMaterial')['disabled'] = false;
      }
    }
    else{
      if (document.getElementById('marketingMaterial')) {
        document.getElementById('trainingResource')['disabled'] = false;
      }
    }
  }

  changeFormat(value: any){
    let selectedId = value.target.id;
    if (selectedId == "trainingResource") {
      document.getElementById('resource')['accept'] = "";
      this.resourceType = 'trainingResource';
    }
    else{
      document.getElementById('resource')['accept'] = ".jpeg,.png,.gif,.webp,.webm,.mp4,.mov";
      this.resourceType = 'marketingMaterial';
    }
  }

  clickAgree(value: any){
    let isChecked = value.target.checked;
    if (isChecked) {
      document.getElementById('startAutomation')['disabled'] = false;
    }
    else{
      document.getElementById('startAutomation')['disabled'] = true;
    }
  }

  saveEmailTemplate(value: any){
    if(value.trim().length != 0){
      this.isDocUploaded = true;
      document.getElementById('trainingResource')['disabled'] = true;
      document.getElementById('nav-upload-tab').classList.add('disabled');
      this.dashboardTrainingResourceForm.controls['resource'].setValidators(null);
      this.dashboardTrainingResourceForm.controls['resource'].updateValueAndValidity();
      console.log(this.dashboardTrainingResourceForm.controls);
    }
    else{
      this.isDocUploaded = false;
      this.isNotEmailTemp = false;
      document.getElementById('trainingResource')['disabled'] = false;
      document.getElementById('nav-upload-tab').classList.remove('disabled');
      this.dashboardTrainingResourceForm.controls['resource'].setValidators([Validators.required]);
    }
  }

  removeResourceDocument(id: any) {
    this.apiService.deleteRequest('admin/training-resource/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Training Resource Deleted successful', { cssClass: 'alert-success' });
          this.getTemplates();
          this.getDashboardData();
        } else {
          this._flashMessagesService.show('Training Resource Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }

  confirmationPopup() {
    this.accoModalRef = this.modal.open(this.accoViewModal);
  }
  
  confirmationPopup2() {
    this.accoModalRef2 = this.modal.open(this.accoViewModal2);
  }
  closeModalPopup(result?: any) {
    this.accoModalRef.close();
  }
  
  closeModalPopup2(result?: any) {
    this.accoModalRef2.close();
  }
  printComponent(cmpName) {
    document.getElementById('a'+cmpName).style.display = "none";
    let printContent = document.getElementById(cmpName).innerHTML;
    const WindowPrt = window.open('', '', '');
    WindowPrt.document.write(printContent);
    WindowPrt.document.close();
    var style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = '@page { size: auto;  margin: 0mm; }';
    WindowPrt.document.body.appendChild(style);
    WindowPrt.alert("Please wait, this might take some time. Don't close or reload the page. \n\nClick ok to proceed");
    WindowPrt.addEventListener('load', function() {
      WindowPrt.focus();
      WindowPrt.print();
    }, true);
    document.getElementById('a'+cmpName).style.removeProperty( 'display' );
  }
  printAllComponent(){
    let printContent = document.getElementById('poster-data0').innerHTML;
    let i = 1;
    for(i;i<12;i++) {
      printContent+= document.getElementById('poster-data'+i).innerHTML;
    }    
    const WindowPrt = window.open('', '', '');
    WindowPrt.document.write(printContent);
    WindowPrt.document.close();
    var style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = '@page { size: auto;  margin: 0mm; }';
    WindowPrt.document.body.appendChild(style);
    WindowPrt.alert("Please wait, this might take some time. Don't close or reload the page. \n\nClick ok to proceed");
    WindowPrt.addEventListener('load', function() {
      WindowPrt.focus();
      WindowPrt.print();
    }, true);
  }
  saveTemplate() {
    this.clicked = true;
    if (this.dashboardTrainingResourceForm.value.subject) {
      let postdata = {
        "subject" : this.dashboardTrainingResourceForm.value.subject,
        "content" : this.dashboardTrainingResourceForm.value.email_template
      }
      this.apiService.postRequest('admin/email-template', postdata).subscribe(
        (response: any) => {
            if (response.success) {
              this.isDocUploaded = true;
              this.closeDialog();
              this.getTemplates();
              this._flashMessagesService.show('Template Saved!', { cssClass: 'alert-success' });
            } else {
              this.isDocUploaded = false;
              this.isNotEmailTemp = false;
              this.closeDialog();
              this._flashMessagesService.show('Template Save Failed!!', { cssClass: 'alert-danger' });
            }
            this.clicked = false;
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    console.log(this.isDocUploaded);
    } else {
      // this.showError = true;
    }
  }

  copyTemplate (type: string) {
    for (let obj of this.templates) {
      if (obj.id == this.selectedTemp) {
        if(type == "save_mail") {
          this.dashboardTrainingResourceForm.controls['email_template'].setValue(obj.email_template);
          this.dashboardTrainingResourceForm.controls['subject'].setValue(obj.subject);
        }
        else {
          this.mailSubmissionForm.controls['email_template'].setValue(obj.email_template);
          this.mailSubmissionForm.controls['subject'].setValue(obj.subject);
        }
        
      }
    }
    this.isTemplateAdded();
    this.closeDialog();
  }

  getTemplates () {
    this.apiService.getRequest('admin/email-template').subscribe(
      (response: any) => {
        if(response.success) {
          this.templates = response.data;
        }
      },
      (err: any) => console.log(err),
    );
  }

  get totalItems() {
    if(this.filteredItems.length > 0) {
      return this.filteredItems.length;
    }
    if(this.email_templates) {
      return this.email_templates.length;
    }
  }

  getTemplatesList(search: any) {
    this.searchTerm = search;
    this.apiLink = 'admin/email-template?search=' + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          console.log(response.data)
          this.email_templates = response.data;
        }
      },
      (err: any) => console.log(err),
    );
  }
  applySearchFilter = () => {
    if (!this.searchTerm || this.searchTerm.trim() === '') {
      this.filteredItems = [...this.email_templates]; // Reset filteredItems if searchTerm is empty
    } else {
      this.filteredItems = this.email_templates.filter(item => {
        return item.title.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    }
    this.currentPage = 1;
    this.cdr.detectChanges();
  }

  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  isTemplateAdded() {
    let subject = this.dashboardTrainingResourceForm.controls['subject'].value;
    let content = this.dashboardTrainingResourceForm.controls['email_template'].value;
    if(subject && content.trim().length != 0) {
      this.isDocUploaded = true;
      this.dashboardTrainingResourceForm.controls['resource'].setValidators(null);
      this.dashboardTrainingResourceForm.controls['resource'].updateValueAndValidity();
    }
    else {
      this.dashboardTrainingResourceForm.controls['resource'].setValidators([Validators.required]);
      this.isDocUploaded = false;
      this.isNotEmailTemp = false;
    }
    if(subject || content.trim().length != 0) {
      document.getElementById('nav-upload-tab').classList.add('disabled');
      document.getElementById('trainingResource')['disabled'] = true;
    }
    else {
      document.getElementById('nav-upload-tab').classList.remove('disabled');
      document.getElementById('trainingResource')['disabled'] = false;
    }
  }
  onFileSelected(files: FileList) {
    this.emailAddresses = files.item(0);
    this.isMailImported = true;
    this.errorMessage = null;
    this.mailImport = {
      'file_name': this.emailAddresses.name,
    }
  }
  viewMailSubmissionForm(id: number) {
    // this.resourceVideo = resource.resource_url;
    // this.resourceTitle = resource.title;
    this.mailSubmissionForm = this.formBuilder.group({
      email_template: ['', Validators.required],
      subject: ['', Validators.required],
      template : [''],
      email_ids: ['', this.validateEmailList],
      import_email: ['']
    },
    {});
    this.getTemplate(id);
  }
  validateEmailList(control) {
    const emailList = control.value.split(',').map(email => email.trim());
    const isValid = emailList.every(email => Validators.email(new FormControl(email)));

    if(emailList[0] != '') {
      return isValid ? null : { 'invalidEmailList': true };
    }
    else {
      return { 'invalidEmailList': true }
    }
  }
  closeMailView() {
    this.removeMailImport();
    this.mailViewModalRef.close();
  }

  getTemplate (templateId: number): any {
    console.log('called getTemplate');
    this.apiService.getRequest('admin/email-template/' + templateId).subscribe(
      (response: any) => {
        if(response.success) {
          console.log(response.data);
          // return response.data;
          this.mailSubmissionForm.controls['email_template'].setValue(response.data.email_template);
          this.mailSubmissionForm.controls['subject'].setValue(response.data.subject);
          this.mailViewModalRef = this.modal.open(this.mailViewModal,{ windowClass: 'my-own-styles' });
        }
      },
      (err: any) => console.log(err),
    );
  }

  sendMail() {

    const postData = new FormData();
    postData.append('email_template', this.mailSubmissionForm.value.email_template);
    postData.append('subject', this.mailSubmissionForm.value.subject);
    postData.append('email_ids', this.mailSubmissionForm.value.email_ids);
    postData.append('import_email', this.emailAddresses);

    let emailValid = this.validateEmailList(this.mailSubmissionForm.controls['email_ids']);
    this.mailAddessError = null;
    if(emailValid == null) {
      this.mailAddessError = 'Invalid email format';
    }
    else if(!this.mailSubmissionForm.value.email_ids && !this.emailAddresses) {
      this.errorMessage = 'Add/import email address(es) to submit.';
    }
    else {
      this.errorMessage = null;
      this.apiService.postRequest('admin/send-email', postData).subscribe(
        (response: any) => {
          if(response.success) {
            console.log(response.data);
            this._flashMessagesService.show('Mail Sent!', { cssClass: 'alert-success' });
            this.closeMailView();
          }
          else {
            this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          }
        },
        (err: any) => console.log(err),
      );
    }
    
  }

  removeMailImport() {
    this.isMailImported = false;
    this.mailImport = {
      'file_name': null,
    };
    this.emailAddresses = null;
    if (document.getElementById('nav-email-tab')) {
      document.getElementById('nav-email-tab').classList.remove('disabled');
    }
    if (this.resourceType == 'trainingResource') {
      if (document.getElementById('trainingResource')) {
        document.getElementById('marketingMaterial')['disabled'] = false;
      }
    }
    else{
      if (document.getElementById('marketingMaterial')) {
        document.getElementById('trainingResource')['disabled'] = false;
      }
    }
  }
}
