import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthPermissionDirective } from '../shared/auth/auth.permission.directive';

import { ValidationService } from './services/validation.service';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { AuthStorageService } from './services/auth.storage.service';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */

@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule],
  declarations: [HeaderComponent, SidebarComponent, AuthPermissionDirective],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, HeaderComponent, SidebarComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [ApiService, ValidationService, AuthService, AuthStorageService]
    };
  }
}
