import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit {
  users: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  userId: any ;
    // pagination
    page = 1;
    search = "";
    collectionSize: any;
    pageSize: any;
    lastPage: any;  
    apiLink:any;
    hasSuperAdminAccess : boolean;
  

  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    let userdata = this.authService.getCurrentUser();
    let permissions = userdata.permission_list;
    this.hasSuperAdminAccess = permissions.indexOf("SUPER_ADMIN_ACCESS") !== -1;

    // this.getUsers();
    this.getCurrentUser();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getUsers(this.page,this.search);
      }else {
        this.router.navigate(['admin-user', {page:1}]);
      }
    });

  }

  getUsers(page:any,search:any) {
    if (search == "") {
      this.activatedRoute.params.subscribe((params) => {
        page = params['page'];
      });
    }
    this.search = search;
    this.apiLink = 'admin/admin-user?page=' + page + "&search=" + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.users = response.data;
          this.users = response.data.data;
          if(this.users.length == 0 && this.page > 1) {
            this.page -= 1;
            this.router.navigate(['admin-user', {page:this.page, search:this.search}]);
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteUser(id: any) {
    this.apiService.deleteRequest('admin/admin-user/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getUsers(this.page,this.search);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  
  getCurrentUser() {
    let user = this.authService.getCurrentUser();
    this.userId = user.id;
  }
  getUsersListPage(pageNumber: any) {
    this.router.navigate(['admin-user', {page:pageNumber,search:this.search}]);
  }
  checkIfCurrentUser(userId){
    if(this.userId != userId){
      return true;
    }
    else{
      return false;
    } 
  }
}
