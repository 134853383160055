import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-mindful-learning-list',
  templateUrl: './mindful-learning-list.component.html',
  styleUrls: ['./mindful-learning-list.component.scss']
})
export class MindfulLearningListComponent implements OnInit {
  listData: any;
  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  employmentId: any;
  // pagination
  page = 1;
  collectionSize: any;
  pageSize: any;
  lastPage: any;
  apiLink: any;
  @Input() pageTitle: string;
  @Input() dataUrl: string;
  @Input() createUrl: string;
  @Input() editUrl: string;
  @Input() paginateUrl: string;
  @Input() restricted: string;
  @Input() author: string;

  lesson : string = "";
  userId: any;
  hasTopicPermission : boolean = false;

  constructor(private location: Location, public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    let user = this.authService.getCurrentUser();
    this.userId = user.id;
    // this.getEmployments();
    this.activatedRoute.params.subscribe((params) => {
      if (params['page']) {
        this.page = params['page'];
      }
      this.getListData(this.page);
      this.getLesson();
    });
  }

  getLesson() {
    let locationPath = this.location.path();
    let locationSegments: any[];
    if (locationPath.length) locationSegments = locationPath.split('/');
    this.lesson = locationSegments[2];
    if(!this.restricted || this.author == this.userId) {
      this.hasTopicPermission = true;
    }
  }

  getListData(page: any) {
    this.apiLink = this.dataUrl + '?page=' + page;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if (response.success) {
          // this.employments = response.data;
          this.listData = response.data.data;
          if(this.listData.length == 0 && this.page > 1) { // to handle deletion of single record page
            this.page -= 1;
            this.getListDataWithPage(this.page)
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteItem(id: any) {
    this.apiService.deleteRequest('admin/mindful-learning/' + id).subscribe(
      (response: any) => {
        if (response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getListData(this.page);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }

  getListDataWithPage(pageNumber: any) {
    this.router.navigate([this.paginateUrl, { page: pageNumber }]);
  }

  onBackClick() {
    this.router.navigate(['wellness-pages/mindful']);
  }
}
