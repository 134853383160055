import { Component, OnInit, ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver  } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceCreateComponent } from '../../resources/resource-create/resource-create.component';
import { KnowYourDepartmentComponent } from '../../know-your-department/know-your-department.component';
import { MostWantedCreateComponent } from '../../most-wanted/most-wanted-create/most-wanted-create.component';
import { CrimePreventionCreateComponent } from '../../crime-prevention/crime-prevention-create/crime-prevention-create.component';
import { EmploymentCreateComponent } from '../../employment/employment-create/employment-create.component';
import { CodesAndOrdinanceComponent } from '../../codes-and-ordinance/codes-and-ordinance.component';
import { VictimsServicesComponent } from '../../victims-services/victims-services.component';
import { InmateInfoComponent } from '../../inmate-info/inmate-info.component';
import { DepartmentCreateComponent } from '../../departments/department-create/department-create.component';
import { PermitCreateComponent } from '../../permits/permits-create/permits-create.component';
import { PraiseOrComplaintsListComponent } from '../../praise-or-complaints/praise-or-complaints-list/praise-or-complaints-list.component';
import { EventCreateComponent } from '../../events/event-create/event-create.component';
import { HopkinsvillePoliceComponent } from '../../hopkinsville-police/hopkinsville-police.component';

@Component({
  selector: 'app-dynamic-content-management-pages-data-create',
  templateUrl: './dynamic-content-management-pages-data-create.component.html',
  styleUrls: ['./dynamic-content-management-pages-data-create.component.scss']
})
export class DynamicContentManagementPagesDataCreateComponent {
  apiLink:any;
  content:any;
  targetComponent : string;
  targetTitle : '';
  componentFactory : any;
  private componentRef: ComponentRef<any>;
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: false }) dynamicComponentContainer: ViewContainerRef;
  createUrl : any;
  backUrl : any;

  constructor(private resolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.apiLink = 'admin/get-content-page/' + params['slug'];
      this.apiService.getRequest(this.apiLink).subscribe(
          (response: any) => {
            if(response.success) {
              this.content = response.data;
              this.targetComponent = this.content.template_name;
              this.targetTitle = this.content.title;
              this.createUrl = 'admin/page-data/create/' + this.content.id+'-'+ this.content.slug 
              this.backUrl = 'content-pages/'+ this.content.id+'-'+ this.content.slug 
              switch(this.targetComponent) {
                case 'ResourceListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(ResourceCreateComponent);
                  break;
                }
                case 'KnowYourDepartmentComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(KnowYourDepartmentComponent);
                  break;
                }
                case 'CrimePreventionListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(CrimePreventionCreateComponent);
                  break;
                }
                case 'EmploymentListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(EmploymentCreateComponent);
                  break;
                }
                case 'CodesAndOrdinanceComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(CodesAndOrdinanceComponent);
                  break;
                }
                case 'VictimsServicesComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(VictimsServicesComponent);
                  break;
                }
                case 'InmateInfoComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(InmateInfoComponent);
                  break;
                }
                case 'DepartmentListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(DepartmentCreateComponent);
                  break;
                }
                case 'PermitListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(PermitCreateComponent);
                  break;
                }
                case 'MostWantedListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(MostWantedCreateComponent);
                  break;
                }
                case 'PraiseOrComplaintsListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(PraiseOrComplaintsListComponent);
                  break;
                }
                case 'EventListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(EventCreateComponent);
                  break;
                }
                case 'HopkinsvillePoliceComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(HopkinsvillePoliceComponent);
                  break;
                }
              }
              this.componentRef = this.dynamicComponentContainer.createComponent(this.componentFactory);
              this.componentRef.instance.createUrl = this.createUrl;
              this.componentRef.instance.pageTitle = this.targetTitle;
              this.componentRef.instance.isStatic = false;
              this.componentRef.instance.backUrl = this.backUrl;
            }
          },
          (err: any) => console.log(err),
        );
        
      });
  }

}
