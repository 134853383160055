import { Component, OnInit, ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver  } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceEditComponent } from '../../resources/resource-edit/resource-edit.component';
import { MostWantedEditComponent } from '../../most-wanted/most-wanted-edit/most-wanted-edit.component';
import { CrimePreventionEditComponent } from '../../crime-prevention/crime-prevention-edit/crime-prevention-edit.component';
import { EmploymentEditComponent } from '../../employment/employment-edit/employment-edit.component';
import { DepartmentEditComponent } from '../../departments/department-edit/department-edit.component';
import { PermitEditComponent } from '../../permits/permits-edit/permits-edit.component';
import { EventEditComponent } from '../../events/event-edit/event-edit.component';
import { HopkinsvillePoliceComponent } from '../../hopkinsville-police/hopkinsville-police.component';

@Component({
  selector: 'app-dynamic-content-management-pages-data-edit',
  templateUrl: './dynamic-content-management-pages-data-edit.component.html',
  styleUrls: ['./dynamic-content-management-pages-data-edit.component.scss']
})
export class DynamicContentManagementPagesDataEditComponent {

  apiLink:any;
  content:any;
  targetComponent : string;
  targetTitle : '';
  componentFactory : any;
  private componentRef: ComponentRef<any>;
  @ViewChild('dynamicEditComponentContainer', { read: ViewContainerRef, static: false }) dynamicEditComponentContainer: ViewContainerRef;
  updateUrl : any;
  backUrl : any;

  constructor(private resolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.apiLink = 'admin/page-by-id/' + params['id'];
      this.apiService.getRequest(this.apiLink).subscribe(
          (response: any) => {
            if(response.success) {
              this.content = response.data;
              this.targetComponent = this.content.template_name;
              this.targetTitle = this.content.title;
              this.updateUrl = 'admin/update-page-data/' + this.content.id 
              this.backUrl = 'content-pages/'+ this.content.id+'-'+ this.content.slug 
              switch(this.targetComponent) {
                case 'ResourceListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(ResourceEditComponent);
                  break;
                }
                case 'CrimePreventionListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(CrimePreventionEditComponent);
                  break;
                }
                case 'EmploymentListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(EmploymentEditComponent);
                  break;
                }
                
                case 'DepartmentListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(DepartmentEditComponent);
                  break;
                }
                case 'PermitListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(PermitEditComponent);
                  break;
                }
                case 'MostWantedListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(MostWantedEditComponent);
                  break;
                }
                case 'EventListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(EventEditComponent);
                  break;
                }
                case 'HopkinsvillePoliceComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(HopkinsvillePoliceComponent);
                  break;
                }
              }
              this.componentRef = this.dynamicEditComponentContainer.createComponent(this.componentFactory);
              this.componentRef.instance.updateUrl = this.updateUrl;
              this.componentRef.instance.pageTitle = this.targetTitle;
              this.componentRef.instance.isStatic = false;
              this.componentRef.instance.backUrl = this.backUrl;
            }
          },
          (err: any) => console.log(err),
        );
        
      });
  }

}
