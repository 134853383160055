import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { ValidationService } from '../../shared/services/validation.service';
import { MustMatch } from '../../validator';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin-user-create',
  templateUrl: './admin-user-create.component.html',
  styleUrls: ['./admin-user-create.component.css']
})
export class AdminUserCreateComponent implements OnInit {
  adminUserCreateForm: FormGroup;
  apiErrorMessage: string;
  showEmailError: boolean;
  imageUploaded = {
    'image_name': null,
    'image_link': null,
    'profile_pic': null,
  };
  isImageUploaded = false;
  imageToUpload: File = null;

  constructor(private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.showEmailError = false;
    this.adminUserCreateForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['',[Validators.required, Validators.pattern(/^(\d{10}|\d{3}-\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4}|\d{3} \d{3} \d{4}|\(\d{3}\) \d{3}-\d{4}|\+1 \d{3}-\d{3}-\d{4})$/)]],
      profile_pic: ['']
    },
    {});
  }

  onSubmit() {
    if (this.adminUserCreateForm.valid) {
        this.showEmailError = false;
        let postData = {
          'first_name': this.adminUserCreateForm.value.firstName,
          'last_name': this.adminUserCreateForm.value.lastName,
          'email': this.adminUserCreateForm.value.email,
          'phone': this.adminUserCreateForm.value.phone,
          'user_type': 1
        }
        postData['profile_pic'] = this.imageUploaded['profile_pic'];
  
        this.apiService.postRequest('admin/admin-user', postData).subscribe(
          (response: any) => {
              if (response.success) {
                  this.router.navigate(['admin-user']);
                  this._flashMessagesService.show('Administrator User Created Successfully', { cssClass: 'alert-success' });
              } else {
                  this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              }
          },
          err => {
              console.log(err);
              this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          },
        );
      
    }
  }
  
  onImageUpload(files: FileList){
    let file = files.item(0);
    this.imageToUpload = file;
    let postD = new FormData();
    postD.append('image', this.imageToUpload);
    this.apiService.postRequest('admin/upload-picture',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.imageUploaded = {
          'image_name': response.file_name,
          'image_link': response.path,
          'profile_pic': response.image,
        }
        this.isImageUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeImage() {
    this.isImageUploaded = false;
    this.imageUploaded = {
      'image_name': null,
      'image_link': null,
      'profile_pic': null,
    };
  }
  onBackClick() {
    this.location.back();
  }

}
