import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-admin-user-change-permission',
  templateUrl: './admin-user-change-permission.component.html',
  styleUrls: ['./admin-user-change-permission.component.scss']
})
export class AdminUserChangePermissionComponent implements OnInit {
  changePermissionForm: FormGroup;
  errorMessage = null;
  id: string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  permissionsList;
  selectedTeamOnly: boolean = false;
  formValid: boolean = false;
  closeResult: string;

  constructor(private apiService: ApiService, private router: Router, private modal: NgbActiveModal, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.dropdownList = [];
    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select Permission",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.changePermissionForm = this.formBuilder.group({
      'permissions': ['', Validators.required]
    });
    
    this.ngOnChanges();
  }
  ngOnChanges() {
    // this.apiService.getRequest('admin/admin-permissions').subscribe(
    //   (response: any) => {
    //     if(response.success) {
    //       var permissionList = response.data;
    //       permissionList.forEach((permission: any) => {
    //         this.dropdownList.push({"id":permission.id,"itemName":permission.title});
    //       });
    //     }
    //   },
    //   (err: any) => console.log(err),
    // );
    this.apiService.getRequest('admin/admin-selected-permissions/' + this.id).subscribe(
      (response: any) => {
        if(response.success) {
          var userpermissionList = response.data;
          userpermissionList.forEach((permission: any) => {
            this.dropdownList.push({"id":permission.id,"itemName":permission.title, 'checked': permission.checked});
          });
          this.changeSelection();
        }
      },
      (err: any) => console.log(err),
    );

  }
  onSubmit() {
    let checkedRoles = this.dropdownList.filter(x=>x.checked == true);
    if (checkedRoles.length > 0) {
      checkedRoles.forEach((permission: any) => {
        delete permission.checked;
      });
      let postData = {
        'permissions': checkedRoles,
      }

      this.apiService.postRequest('admin/permissions/' + this.id , postData).subscribe(
        (response: any) => {
          this.closeModal(response);
        },
        (error: any) => {
            this.errorMessage = error;
            this.closeModal(error);
        }
      );
    }
  }
  closeModal(result?: any) {
    this.modal.close(result);
  }

  CheckAllOptions() {
    if (this.dropdownList.every(val => val.checked == true))
      this.dropdownList.forEach(val => { val.checked = false });
    else
      this.dropdownList.forEach(val => { val.checked = true });
    this.changeSelection();
  }

  deselectAllOptions() {
    this.dropdownList.forEach(val => { val.checked = false })
    this.changeSelection();
  }

  changeSelection() {
    let checkedRoles = this.dropdownList.filter(x=>x.checked === true);
    if (checkedRoles.length > 0)
      this.formValid = true;
    else
      this.formValid = false;
  }

  toggleSelection(event,permissionCheckbox) {
    if(event.target.value == 1) {
      if(event.target.checked == true) {
        this.CheckAllOptions()
      } else {
        this.deselectAllOptions()
      }
    } else {
      if(event.target.checked == true) {
        permissionCheckbox.checked = true
        let checkedRoles = this.dropdownList.filter(x=>x.checked == true && x.id != 1);
        if(checkedRoles.length == (this.dropdownList.length -1 ))
          this.dropdownList[0]['checked'] = true
      } else {
          this.dropdownList[0]['checked'] = false
      }
    }
    // if(event.target.checked == true && event.target.value == 1) {
    //     this.CheckAllOptions()
    // }
    // else if(event.target.checked == true && event.target.value != 1) {
    //   this.dropdownList[0]['checked'] = false
    //   var element = <HTMLInputElement> document.getElementById("customSwitch0");
    //   //  if(element.checked == true) element.checked = false;
    // } 
    // this.changeSelection()
  }

}
