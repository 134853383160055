import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'contact-us-edit',
  templateUrl: './contact-us-edit.component.html',
  styleUrls: ['./contact-us-edit.component.scss']
})
export class ContactUsEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editPageForm: FormGroup;
  errorMessage = null;
  contactId: string;
  images = [];
  key:string = "";
  name: string;
  phone: string;
  email: string;
  fax: string;
  url:string = "";
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link','|','imageUpload']
  };    
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.contactId = params['id'];
    });
    this.getContact();
    this.editPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'fax': [''],
      'phone': ['', [Validators.required, Validators.pattern("[- +()0-9]+")]],
      'email': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
    });
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  // imageUpload(event) {
  //   if (event.target) {
  //     var formData = new FormData;
  //     formData.append('image',event.target.files[0]);
  //     this.apiService.postRequest('admin/app-image', formData).subscribe(
  //       (response: any) => {
  //           if (response.success) {
  //             this.url = response.path;
  //           }
  //       },
  //       err => {
  //           console.log(err);
  //       },
  //     );
  //   }
  // }
  getContact () {
    this.apiService.getRequest('admin/contact-us/' + this.contactId).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          this.fax = response.data.fax;
          this.phone = response.data.phone;
          this.email = response.data.email;
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editPageForm.valid) {
      let postData = {
        'name': this.editPageForm.value.name,
        'fax': this.editPageForm.value.fax,
        'phone': this.editPageForm.value.phone,
        'email': this.editPageForm.value.email,
      }
      this.apiService.putRequest('admin/contact-us/' + this.contactId, postData).subscribe(
        (response: any) => {
            if (response.success) {
              this.router.navigate(['contact-us']);
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

}
