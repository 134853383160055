import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ValidationService } from './../../shared/services/validation.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  adminProfileForm: FormGroup;
  apiErrorMessage: string;

  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;

  constructor(private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private modal: NgbModal) { }

  ngOnInit() {
    this.getUser();

    this.adminProfileForm = this.formBuilder.group({
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'email': ['', Validators.required],
      'contactNumber': ['', ''],
    });
  }

  onSubmit() {
    if (this.adminProfileForm.valid) {
      let putData = {
        'first_name': this.adminProfileForm.value.firstName,
        'last_name': this.adminProfileForm.value.lastName,
        'email': this.adminProfileForm.value.email,
        'contact_number': this.adminProfileForm.value.contactNumber,
      }

      this.apiService.putRequest('me', putData).subscribe(
        (response: any) => {
            if (response.success) {
                this.router.navigate(['dashboard']);
                // this.router.navigate(['public-alert']);
                this._flashMessagesService.show('Update successful', { cssClass: 'alert-success' });
            } else {
                this.apiErrorMessage = response.message;
                this._flashMessagesService.show('Update Failed!', { cssClass: 'alert-danger' });
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  getUser() {
    this.apiService.getRequest('admin/me').subscribe(
      (response: any) => {
        if(response.success) {
          this.firstName = response.data.first_name;
          this.lastName = response.data.last_name;
          this.email = response.data.email;
          this.contactNumber = response.data.contact_number;
          this.userId = response.data.id;
        }
      },
      (err: any) => console.log(err),
    );
  }

  changePassword() {
    const modalRef = this.modal.open(ChangePasswordComponent);
    modalRef.result.then((data: any) => {
        if(data.success) {
          this._flashMessagesService.show('Password changed', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Password change failed!', { cssClass: 'alert-danger' });
        }
    }).catch((e) => { console.log(e); });
  }

}
