import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.scss']
})
export class TeamManagementComponent implements OnInit {
  users: any;

  popoverTitle: string = 'Are you sure?';
  popoverMessage: string = 'Are you really sure you want to do this?';
  teamId: any;
  selectedMembers = [];
  temaName: any;
  hasStaffAccess: boolean;

  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    let userdata = this.authService.getCurrentUser();
    let permissions = userdata.permission_list;
    this.hasStaffAccess = permissions.indexOf("STAFF_ACCESS") !== -1;
    
    this.activatedRoute.params.subscribe((params) => {
      this.teamId = params['id'];
    });
    this.getTeam();
    this.getStaffs();
  }

  getStaffs() {
    this.apiService.getRequest('admin/staff').subscribe(
      (response: any) => {
        if (response.success) {
          this.users = response.data;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteTeam(id: any) {
    this.apiService.deleteRequest('admin/team/' + id).subscribe(
      (response: any) => {
        if (response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getStaffs();
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }

  userChecked(ev, id) {
    if (ev.target.checked) {
      if (!this.selectedMembers.includes(id)) {
        this.selectedMembers.push(id);
      }
    } else {
      if (this.selectedMembers.indexOf(id) > -1) {
        this.selectedMembers.splice(this.selectedMembers.indexOf(id), 1);
      }
    }

  }

  getTeam() {
    this.apiService.getRequest('admin/team/' + this.teamId + '/member').subscribe(
      (response: any) => {
        console.log(response);
        if (response.success) {
          this.temaName = response.data.name;
          this.selectedMembers = response.data.members.map((item) => {
            return item.id
          });
        }
      },
      (err: any) => console.log(err),
    );
  }

  onSubmit() {

    let putData = {
      'team_members' : this.selectedMembers 
    }

    this.apiService.putRequest('admin/team/' + this.teamId + '/member', putData).subscribe(
      (response: any) => {
        if (response.success) {
          this._flashMessagesService.show('Update successful', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Update Failed!', { cssClass: 'alert-danger' });
        }
      },
      err => {
        console.log(err);
        this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      },
    );

  }


}
