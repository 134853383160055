import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StaffChangePasswordComponent } from '../staff-change-password/staff-change-password.component';
import { ValidationService } from '../../shared/services/validation.service';
import { Location } from '@angular/common';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-staff-edit',
  templateUrl: './staff-edit.component.html',
  styleUrls: ['./staff-edit.component.scss']
})
export class StaffEditComponent implements OnInit {
  staffEditForm: FormGroup;
  apiErrorMessage: string;
  userId: string;
  loggedUserId : string;
  hasStaffAccess: boolean;
  isStaffAdmin: boolean;
  
  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  btnInvitDisable: boolean = false;
  lblInvitationBtn: string = "Resend Invitation Mail";
  emailVerified: number = 0;
  isQstnSelected: boolean = false;
  loggedIn: string = null;
  activeUser: boolean = false;

  constructor(private authService: AuthService,private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private modal: NgbModal) { }

  ngOnInit() {
    let userdata = this.authService.getCurrentUser();
    let permissions = userdata.permission_list;
    this.loggedUserId = userdata.id;
    this.hasStaffAccess = permissions.indexOf("STAFF_ACCESS") !== -1;
    this.activatedRoute.params.subscribe((params) => {
      this.userId = params['id'];
    });

    this.getAdmin();
    this.staffEditForm = this.formBuilder.group({
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'email': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      'phone': ['',[Validators.pattern(/^(\d{10}|\d{3}-\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4}|\d{3} \d{3} \d{4}|\(\d{3}\) \d{3}-\d{4}|\+1 \d{3}-\d{3}-\d{4})$/)]],
      'staffAdminAccess': [false]
    });
  }

  onSubmit() {
    if (this.staffEditForm.valid) {
      console.log(this.staffEditForm.value.staffAdminAccess);
        let putData = {
          'first_name': this.staffEditForm.value.firstName,
          'last_name': this.staffEditForm.value.lastName,
          'email': this.staffEditForm.value.email,
          'phone': this.staffEditForm.value.phone,
          'user_type': 2,
        }
        if(this.staffEditForm.value.staffAdminAccess != null) {
          putData['staff_admin_access'] = this.staffEditForm.value.staffAdminAccess;
        }
        console.log(putData);
        this.apiService.putRequest('admin/staff/' + this.userId, putData).subscribe(
          (response: any) => {
              if (response.success) {
                  this.router.navigate(['staff']);
                  this._flashMessagesService.show('Update successful', { cssClass: 'alert-success' });
              } else {
                  this.apiErrorMessage = response.message;
                  this._flashMessagesService.show('Update Failed!', { cssClass: 'alert-danger' });
              }
          },
          err => {
              console.log(err);
              this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          },
        );
      
    }
  }

  getAdmin() {
    this.apiService.getRequest('admin/staff/' + this.userId).subscribe(
      (response: any) => {
        if(response.success) {
          this.firstName = response.data.first_name;
          this.lastName = response.data.last_name;
          this.email = response.data.email;
          this.phone = response.data.phone;
          this.emailVerified = response.data.email_verified;
          this.isStaffAdmin = response.data.staff_admin_access;
          this.isQstnSelected = response.data.questions_selected;
          this.loggedIn = response.data.logged_in;
          if(this.loggedIn && this.isQstnSelected) { //checking if logged in at least once and set security questions
            console.log('logged in value =====> ',this.loggedIn);
            this.activeUser = true;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }

  changePassword(id: any) {
    const modalRef = this.modal.open(StaffChangePasswordComponent);
    modalRef.componentInstance.id = id;
    modalRef.result.then((data: any) => {
        if(data.success) {
          this._flashMessagesService.show('Staff password changed', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Password change failed!', { cssClass: 'alert-danger' });
        }
    }).catch((e) => { console.log(e); });
  }

  resendInvitationMail(id: any) {
    let putData = [];
    this.btnInvitDisable = true;
    this.apiService.putRequest('admin/send-invitation-email/' + this.userId, putData).subscribe(
      (response: any) => {
          if (response.success) {
              this.lblInvitationBtn = "Invitation Mail Sent";
              this._flashMessagesService.show('Invitation Mail Sent Successfully', { cssClass: 'alert-success' });
          } else {
              this.apiErrorMessage = response.message;
              this._flashMessagesService.show('Invitation Mail Failed!', { cssClass: 'alert-danger' });
          }
      },
      err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      },
    );
  }
  onBackClick() {
    this.location.back();
  }

}
