import { Component, OnInit, TemplateRef, ViewChild, HostListener, ElementRef } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlertViewComponent } from '../alert-view/alert-view.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss']
})
export class AlertListComponent implements OnInit {
  alerts:any;
  alertTitle: string;
  testAlert: boolean = false;
  alertDesc: string;
  expire: string;
  createdAt: string;
  documentName: string;
  documentUrl: string;
  alertModalRef: any;
  previewModalRef: any;
  alertImage:string;
  alertImageArr: any;
  zipcode: string;
  accoModalRef:any;
  todayDate:Date = new Date();
  dateForm: FormGroup;
  dateRange: any;
  errorMessage = null;
  minDate = null;
  
  facebook : any;
  instagram : any;
  twitter : any;
  linkedin : any;

  popoverTitle: string = 'Confirm Archive';
  popoverMessage: string = 'Are you sure?';

  // pagination
  page = 1;
  search = "";
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  apiLink:any;
  @ViewChild('alertViewModal', {static: false}) alertViewModal : TemplateRef<any>;
  @ViewChild('previewModal', {static: false}) previewModal : TemplateRef<any>;
  @ViewChild('dateRangeModel', { static: false }) dateRangeModel: TemplateRef<any>;

  constructor(private formBuilder: FormBuilder, public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(event.target.className == 'img-fluid my-2') {
      let dataId = event.target.getAttribute("data-id");
      this.showPreview(dataId);
    }
  }

  ngOnInit() {
    // this.getAlerts();
    this.dateForm = this.formBuilder.group({
      'start_date' : [''],
      'end_date' : [''],
      'include_archive' : [''],
    });
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getAlerts(this.page,this.search);
      }else {
        this.router.navigate(['public-alert', {page:1}]);
      }
    });

  }

  getAlerts(page:any,search:any) {
    if (search == "") {
      this.activatedRoute.params.subscribe((params) => {
        page = params['page'];
      });
    }
    this.search = search;
    this.apiLink = 'admin/public-alert?page=' + page + "&search=" + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          this.alerts = response.data.data;
          if(this.alerts.length == 0 && this.page > 1) {
            this.page -= 1;
            this.router.navigate(['public-alert', {page:this.page,search:this.search}]);
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  viewAlert(id: any) {
      this.alertImage = '';
      // const modalRef = this.modal.open(AlertViewComponent);
      this.alertModalRef = this.modal.open(this.alertViewModal, {size: 'lg'});
      // modalRef.componentInstance.id = id;
      // this.getAlerts();
      this.apiService.getRequest('admin/alert/'+ id).subscribe(
        (response: any) => {
            if(response.success) {
                this.alertTitle = response.data.title;
                this.testAlert = response.data.test_alert;
                this.alertDesc = response.data.description;
                this.expire = response.data.expiry_date;
                this.createdAt = response.data.createdAt;
                this.documentName = response.data.document_name;
                this.documentUrl = response.data.document_url;
                this.alertImageArr = (response.data.imageUrl) ? response.data.imageUrl : '';
                this.facebook = response.data.facebook_post_id;
                this.instagram = response.data.instagram;
                this.twitter = response.data.twitter_post_id;
                this.linkedin = response.data.linkedin_post_id;
                this.zipcode = response.data.zipcode;
            }
        });
  }

  deleteAlert(id: any) {
    this.apiService.deleteRequest('admin/alert/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getAlerts(this.page,this.search);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }

  closeModal(result?: any) {
    this.getAlerts(this.page,this.search);
    this.alertModalRef.close();
  }

  showPreview(imgIndex?: any) {
    if (imgIndex > 0) {
      imgIndex = imgIndex - 1;
    } else {
      imgIndex = 0;
    }
    if (this.alertImageArr[imgIndex]) {
      this.alertImage = this.alertImageArr[imgIndex];
    } else {
      this.alertImage = this.alertImageArr[0];
    }
    if(this.alertImage) {
      let windowClass = 'preview-modal-xl';
      this.previewModalRef = this.modal.open(this.previewModal, {size: 'lg', windowClass: windowClass });
    }
  }
  closePreview() {
    this.previewModalRef.close();
  }

  getAlertsListPage(pageNumber: any) {
    this.router.navigate(['public-alert', {page:pageNumber,search:this.search}]);
  }

  triggerDateRange() {
    this.dateForm.controls['include_archive'].setValue(false);  
    const modalOptions: NgbModalOptions = {
      windowClass: 'ngb-date-range-modal' // Add your custom class here
    };
    this.accoModalRef = this.modal.open(this.dateRangeModel, modalOptions);
  }

  closeModalRange(result?: any) {
    this.accoModalRef.close();
  }

  downloadPubicAlertExcel() {
    let startDate = this.dateForm.value.start_date;
    let endDate = this.dateForm.value.end_date;
    if(startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(startDate) + " to " + this.formatDateTime(endDate) + ")";
    } else if(startDate && !endDate) {
      this.dateRange = "(" +  this.formatDateTime(startDate) + ")";
    } else if(!startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(endDate) + ")";
    } else {
      this.dateRange = null
    }
    let postData = {
      'from_date' : this.dateForm.value.start_date,
      'to_date' : this.dateForm.value.end_date,
      'include_archive' : this.dateForm.value.include_archive,
    }
    
    this.apiService.postRequest('admin/public-alert/export',postData).subscribe(
      (response: any) => {
        if (response.success) {
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.setAttribute('href', response.data.exportUrl);
          link.setAttribute('download', response.data.fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          // this.closeModal();
          this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          this.errorMessage = response.message;
        }
      },
      (err: any) => console.log(err),
    );
    this.closeModalRange();
  }
  resetDates() {
    this.dateForm.controls['include_archive'].setValue(false);  
    this.dateForm.controls['start_date'].setValue(null); 
    this.dateForm.controls['end_date'].setValue(null); 
    this.dateRange = null;
  }

  formatDateTime(date) {
    if (!date) {
      return "";
    }
    if (!(moment(date).isValid())) {
      return "";
    }
    // return moment(date).tz("America/New_York").format('MM/DD/YYYY hh:mm:ss A');
    return moment(moment.utc(date).toDate()).local().format('MM/DD/YYYY');
  }

  setMinDate(event){
    this.minDate = event.value;
  }
}
