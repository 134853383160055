import { Component, OnInit, TemplateRef, ViewChild, Renderer2 } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TipViewComponent } from '../tip-view/tip-view.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-tip-list',
  templateUrl: './tip-list.component.html',
  styleUrls: ['./tip-list.component.scss']
})
export class TipListComponent implements OnInit {
  tips: any;
  tipId: number = 0;

  popoverTitle: string = 'Confirm Archive';
  popoverMessage: string = 'Are you sure?';
  previousTipCount: any;
  tipCount: any;
  tipCountSubscr: Subscription;

  incTime: string;
  tipDesc: string;
  tipImage: string;
  tipVideo: string;
  anonymous: boolean;
  lat: number;
  lng: number;
  zoom: number;
  name: string;
  phone: string;
  email: string;
  fileType: string;
  read: boolean;
  isImage: boolean = false;
  isVideo: boolean = false;
  imageUrl: string;
  format = "image";
  tipModalRef: any;
  previewModalRef: any;
  isValidTip: string = '';
  angle: number = 0;

  dropdownList = [];
  requestId : number;
  modalRef: any
  dropdownSettings = {};
  assignee: string;
  assignedBy: string;
  assignStaffForToTipForm: FormGroup;
  assignedUsers: string;

  selectedUsersOnly = false;
  selectedTeamOnly: boolean = false;
  hasStaff = true;
  hasTeam = true;
  selectedItems = [];
  selectedStaff = [];
  selectedTeam = [];
  tipAssigned: boolean = false;
  staffCount: number = 0;
  // pagination
  page = 1;
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  apiLink:any;

  selectedOption: string; // Hold the value of the selected option
  selectedOptionInfo: string; // Hold the data-info attribute value
  errorMessage = null;
  minDate = null;
  todayDate:Date = new Date();
  dateForm: FormGroup;
  dateRange: any;

  @ViewChild('tipViewModal', { static: false }) tipViewModal: TemplateRef<any>;
  @ViewChild('previewModal', { static: false }) previewModal: TemplateRef<any>;

  @ViewChild('assignModal', {static: false}) assignModal : TemplateRef<any>;

  @ViewChild('dateRangeModel', { static: false }) dateRangeModel: TemplateRef<any>;
  
  constructor(public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private renderer: Renderer2, private activatedRoute: ActivatedRoute, private router: Router,private formBuilder: FormBuilder) {
   this.tipCountSubscr = this.apiService.tipCount.subscribe((res) => {
      this.tipCount = res;
      if (this.previousTipCount != this.tipCount) {
        this.getTips(this.page);
        this.previousTipCount = this.tipCount;
      }
    });
  }

  ngOnInit() {
    this.dateForm = this.formBuilder.group({
      'start_date' : [''],
      'end_date' : [''],
      'include_archive' : [''],
    });

    this.assignStaffForToTipForm = this.formBuilder.group({
      'staff': ['', Validators.required],
    });
    this.selectedItems = [];
    this.dropdownList = [];
    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select one or more options:",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class",
      enableCheckAll: false
    };

    this.getStaffList();

    // this.getTips();
    this.previousTipCount = this.tipCount;
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getTips(this.page);
      }else {
        this.router.navigate(['tip', {page:1}]);
      }
    });
  }

  getTips(page:any) {
    this.apiLink = 'admin/tip?page=' + page;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if (response.success) {
          // this.tips = response.data;
          this.tips = response.data.data;
          if(this.tips.length == 0 && this.page > 1) { // to handle deletion of single record page
            this.page -= 1;
            this.router.navigate(['tip', {page:this.page}]);
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  viewTip(id: any) {
    this.tipId = id;
    this.incTime = '';
    this.tipDesc = '';
    this.tipImage = '';
    this.tipVideo = '';
    this.anonymous = false;
    this.lat = 0;
    this.lng = 0;
    this.zoom = 0;
    this.name = '';
    this.assignedBy = '';
    this.assignee = '';
    this.assignedUsers = '';
    this.phone = '';
    this.email = '';
    this.fileType = '';
    this.read = false;
    this.isImage = false;
    this.isVideo = false;
    this.imageUrl = '';
    this.format = "image";
    this.tipModalRef = null;
    this.previewModalRef = null;
    this.isValidTip = '';
    this.angle = 0;
    this.apiService.getRequest('admin/tip/' + id).subscribe(
      (response: any) => {
        if (response.success) {
          this.incTime = response.data.inc_time;
          this.tipDesc = response.data.tip_desc;
          this.fileType = response.data.file_type;
          if (this.fileType == 'image') {
            this.tipImage = response.data.image_url;
            this.isImage = true;
          }
          if (this.fileType == 'video') {
            this.isVideo = true;
            this.tipVideo = response.data.image_url;
          }
          this.lat = Number(response.data.latitude);
          this.lng = Number(response.data.longitude);
          if (this.lat && this.lng) {
            this.imageUrl = "https://maps.google.com/maps/api/staticmap?markers=" + this.lat + ", " + this.lng + "&zoom=15&size=500x200&scale=1&maptype= roadmap&key=" + environment.googleApiKey;
          }
          this.zoom = 15;
          this.anonymous = response.data.anonymous;
          this.name = response.data.name;
          this.phone = response.data.phone;
          this.email = response.data.email;
          this.read = response.data.read;
          if (response.data.valid_tip == 1) {
            this.isValidTip = 'Yes';
          }
          if (response.data.valid_tip == 0) {
            this.isValidTip = 'No';
          }
          this.assignee = response.data.assignee;
          this.assignedBy = response.data.assignedBy;
          this.assignedUsers = response.data.assignedUsers.split(',');

        }

        let putData = {};
        if (!this.read) {
          this.apiService.postRequest('admin/tip-read/' + response.data.id, putData).subscribe(
            (res: any) => {
              console.log("Marked as read!")
              if (response.success) {
                this.apiService.tipCount.next(response.unseen_tip_count);
              }
            },
            err => {
              console.log(err);
            },
          );
        }
      });
    this.tipModalRef = this.modal.open(this.tipViewModal);
    this.tipModalRef.result.then(() => {
      this.getTips(this.page);
    }).catch((e) => { this.getTips(this.page); });
  }

  deleteTip(id: any) {
    this.apiService.deleteRequest('admin/tip/' + id).subscribe(
      (response: any) => {
        if (response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getTips(this.page);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }


  toggleVideo(event: any) {
    let audioPlayer = <HTMLVideoElement>document.getElementById('tipVideo');
    audioPlayer.play();
  }
  closeModal(result?: any) {
    this.tipModalRef.close();
  }
  printComponent(cmpName) {
    const printContent = document.getElementById(cmpName).innerHTML;
    const WindowPrt = window.open('', '', '');
    WindowPrt.document.write(printContent);
    WindowPrt.document.getElementById('timeInc').style.display = 'block';
    if (WindowPrt.document.getElementById('print-button') !== null) {
      WindowPrt.document.getElementById('print-button').style.display = 'none';
      WindowPrt.document.getElementById('view_phone_element').style.display = 'none';
      WindowPrt.document.getElementById('view_email_element').style.display = 'none';
      WindowPrt.document.getElementById('print_phone_element').style.display = 'block';
      WindowPrt.document.getElementById('print_email_element').style.display = 'block';
    }
    if (this.lat && this.lng) {
      WindowPrt.document.getElementById('staticMap').style.display = 'block';
    }
    if (this.tipImage) {
      if (this.fileType == 'image') {
        WindowPrt.document.getElementById('btnDiv').style.display = 'none';
        WindowPrt.document.getElementById('tipImg').style.width = "300px";
        WindowPrt.document.getElementById('tipImg').style.height = "300px";
      }
      if (this.fileType == 'video') {
        WindowPrt.document.getElementById('tipVideo').style.display = 'none';
      }
    }
    WindowPrt.document.close();
    setTimeout(function() {
      WindowPrt.focus();
      WindowPrt.print();
    }, 250);
  }
  showPreview(videoFlag = false) {
    this.angle = 0;
    let windowClass = 'preview-modal-xl';
    if (videoFlag) {
      windowClass = 'preview-video-modal-xl';
    }
    this.previewModalRef = this.modal.open(this.previewModal, { size: 'lg', windowClass: windowClass });
  }
  closePreview() {
    this.angle = 450;
    this.rotateImage('left','tipImg');
    this.previewModalRef.close();
  }

  setTipValidStatus(validFlag: boolean) {
    let postData = {
      'valid_tip': validFlag,
    }
    this.apiService.postRequest('admin/tip-valid-status/' + this.tipId, postData).subscribe(
      (response: any) => {
        if (response.success) {
          this.closeModal();
          this._flashMessagesService.show('Tip marked successfully', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Tip marking failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  rotateImage(direction, imgId) {
    this.angle += direction == 'left' ? -90 : 90;
    if (this.angle == 360 || this.angle == -360) {
      this.angle = 0;
    }
    if (this.angle == 90 || this.angle == -90 || this.angle == 270 || this.angle == -270) {
      document.querySelector('#' + imgId).classList.add('vertical');
    } else {
      document.querySelector('#' + imgId).classList.remove('vertical');
    }
    this.renderer.setStyle(document.querySelector('#' + imgId), 'transform', `rotate(${this.angle}deg)`);
  }

  getTipListPage(pageNumber: any) {
    this.router.navigate(['tip', {page:pageNumber}]);
  }

  getStaffList() {
    this.apiService.getRequest('admin/staff').subscribe(
      (response: any) => {
        if(response.success) {
          var staffList = response.data;
          staffList.forEach((staff: any) => {
            this.dropdownList.push({"id":'s_' + staff.id,"itemName":staff.first_name + ' ' + staff.last_name,"type":"staff"});
          });
        }
        this.getTeamList();
      },
      (err: any) => console.log(err),
    );
  }

  getTeamList() {
    this.apiService.getRequest('admin/team').subscribe(
      (response: any) => {
        if(response.success) {
          var teamList = response.data;
          teamList.forEach((team: any) => {
            this.dropdownList.push({"id":'t_' + team.id,"itemName":team.name,"type":"team"});
          });
        }
      },
      (err: any) => console.log(err),
    );
  }

  assignTip(id: any) { 
    this.requestId = id;
    this.modalRef = this.modal.open(this.assignModal);
  }

  closeAssignModal(result?: any) {
    this.modalRef.close();
  }

  onSubmit() {
    if (this.assignStaffForToTipForm.valid) {
      this.selectedStaff = [];
      this.selectedTeam = [];
      this.assignStaffForToTipForm.value.staff.forEach((item: any)=> {
        if(item.type == "staff") {
          let staffId = item.id.split('_');
          this.selectedStaff.push(staffId[1]);
        }
        if(item.type == "team") {
          let teamId = item.id.split('_');
          this.selectedTeam.push(teamId[1]);
        }
      });
      let postData = {
        'staff': this.selectedStaff,
        'tip_id' : this.requestId,
        'team': this.selectedTeam
      }

      this.apiService.postRequest('admin/assign-tip', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this.modalRef.close();
                this._flashMessagesService.show('Staff allocated successfully', { cssClass: 'alert-success' });
                this.getTips(this.page);
                this.assignStaffForToTipForm.controls['staff'].setValue("");
                this.selectedItems = [];
            } else {
                this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
            this.modalRef.close();
        },
      );
    }
  }
  onItemSelect(item:any) {
    this.hasStaff = true;
    this.hasTeam = true;
    if(item.type == "staff") {
      var count = 0;
      this.selectedItems.filter(element => {
        if(element.type == "staff"){
          count +=1;
        }
      });
      if(this.staffCount == count) {
        document.getElementById('select-all-staff')['checked'] = true;
      }
    }
  }
  OnItemDeSelect(item:any) {
    if(item.type == "staff") {
      document.getElementById('select-all-staff')['checked'] = false;
    }
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
    document.getElementById('select-all-staff')['checked'] = false;
  }
  handleClick(event) {
    if(event.target.checked) {
      this.selectedItems = this.dropdownList.filter(element => element.type == "staff");
      this.staffCount = this.selectedItems.length;
    }
    else {
      this.selectedItems = this.selectedItems.filter(element => element.type != "staff");
    }
    
  }

  downloadTipExcel() {
    let startDate = this.dateForm.value.start_date;
    let endDate = this.dateForm.value.end_date;
    if(startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(startDate) + " to " + this.formatDateTime(endDate) + ")";
    } else if(startDate && !endDate) {
      this.dateRange = "(" +  this.formatDateTime(startDate) + ")";
    } else if(!startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(endDate) + ")";
    } else {
      this.dateRange = null
    }

    let postData = {
      'from_date' : this.dateForm.value.start_date,
      'to_date' : this.dateForm.value.end_date,
      'include_archive' : this.dateForm.value.include_archive,
    }

    this.apiService.postRequest('admin/tips/export',postData).subscribe(
      (response: any) => {
        if (response.success) {
          const link = document.createElement('a');
          console.log(response.data.path)
          link.href = response.data.path;
          link.setAttribute('download', response.data.file_name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          this.errorMessage = response.message;
        }
      },
      (err: any) => console.log(err),
    );
    this.closeModal();
  }

  tipExcelDownloadModal() {
    const modalOptions: NgbModalOptions = {
      windowClass: 'ngb-date-range-modal' // Add your custom class here
    };
    this.dateForm.controls['include_archive'].setValue(false);  
    this.tipModalRef = this.modal.open(this.dateRangeModel, modalOptions);
  }

  resetDates() {
    this.dateForm.controls['include_archive'].setValue(false);  
    this.dateForm.controls['start_date'].setValue(null); 
    this.dateForm.controls['end_date'].setValue(null); 
    this.dateRange = null;
  }

  formatDateTime(date) {
    if (!date) {
      return "";
    }
    if (!(moment(date).isValid())) {
      return "";
    }
    // return moment(date).tz("America/New_York").format('MM/DD/YYYY hh:mm:ss A');
    return moment(moment.utc(date).toDate()).local().format('MM/DD/YYYY');
  }
  
  setMinDate(event){
    this.minDate = event.value;
  }
}
