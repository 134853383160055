import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, Location } from '@angular/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss'],
  providers: [DatePipe]
})
export class EventCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  images = [];
  url: string  = "";
  key:string;
  name: string;
  // link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    

  allFilesUploaded=[];
  stringfiedAttachments = '';
  fileToUpload: File = null;
  uploadedDoc = false;
  imagestobeDeleted=[];
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;
  organizedByAdmin = false;
  userId: any;
  imageUploaded = {
    'image': null,
  };
  imageUrl: any;
  minEndDate = new Date();
  todayDate:Date = new Date();
  isCalendarEvent: boolean = false;
  timeZone: string;
  selectedDate: string;

  @Input() pageTitle: string ;
  @Input() createUrl: string ;
  apiLink:any;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;

  @ViewChild('imageElement', { static: false }) imageElement: ElementRef<HTMLImageElement>;

  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, 
    private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder, 
    private dialog: MatDialog, private authService: AuthService, private domSanitizer: DomSanitizer, private el: ElementRef,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.createPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      // 'image': [''],
      // 'link': ['',Validators.required],
      // 'link_name': ['']
      'images': [''],
      'document': [''],
      'desc': ['', Validators.required],
      'location': [''],
      'start_date': [''],
      'start_time': [''],
      // 'end_date': [''],
      'end_time': [''],
      'organizer_email': [''],
      'organizer': [''],
      'organizer_designation': [''],
      'organizer_image': [''],
      'is_calendar_event': [''],
      'created_by': ['']
    });
    // if(!this.pageTitle) {
    //   this.pageTitle = "Event";
    // }
    this.timeZone = this.getLocalTimezone();

    let user = this.authService.getCurrentUser();
    this.userId = user.id;
  }

  updateKey (eventData) {
    this.key = eventData.toLowerCase( ).replace(' ', '_');
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.files[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData: any = {
        'name': this.createPageForm.value.name,
        // 'image': this.url,
        // 'type': "event",
        'images': this.allFilesUploaded,
        'images_to_be_deleted': this.imagestobeDeleted,
        'desc': this.createPageForm.value.desc,
        'type' : this.createPageForm.value.name.toLowerCase( ).replace(' ', '_')
      }
      if(this.isCalendarEvent) {
        postData['location'] = this.createPageForm.value.location;
        postData['start_date'] = this.createPageForm.value.start_date ? this.selectedDate : null;
        postData['start_time'] = this.createPageForm.value.start_time;
        // postData['end_date'] = this.createPageForm.value.end_date;
        postData['end_time'] = this.createPageForm.value.end_time;
        postData['organizer'] = this.createPageForm.value.organizer;
        postData['organizer_email'] = this.createPageForm.value.organizer_email;
        postData['organizer_designation'] = this.createPageForm.value.organizer_designation;
        postData['organizer_image'] = this.imageUploaded['image'];
      }
      
      postData['created_by'] = this.userId;
      postData['is_calendar_event'] = this.isCalendarEvent;
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      postData['time_zone'] = this.timeZone;

      // if(!this.isStatic) {
        // postData['type'] = this.createPageForm.value.name.toLowerCase( ).replace(' ', '_')
      // } else {
      //   postData['type'] = 'event'
      // }
      // if(!this.createUrl) {
      //   this.createUrl = 'admin/standard-page?key=' + this.key;
      // }
      this.apiService.postRequest(this.createUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                // if(!this.isStatic) {
                  this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                // } else {
                //   this.router.navigate(['event']);
                // }
              }
              // this.router.navigate(['event']);
                this._flashMessagesService.show('Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.createPageForm.markAsPristine();
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  onImageAttachments(files: FileList){
    for (var i = 0; i < files.length; i += 1) {
      this.fileToUpload = files.item(i);
      let postD = new FormData();
      postD.append('image', this.fileToUpload);
      this.apiService.postRequest('admin/app-image',  postD).subscribe(
        (response: any) => {
        if(response.success){
          let resposeData = {
            'name': response.file_name,
            'image_link': response.path,
            'image': response.image,
            'id': null,
          }
          this.allFilesUploaded.push(resposeData);
        } else {
        }
        },
        (error: any) => {
        }
      );
    }
  }
  removeIndex(index) {
    this.allFilesUploaded.splice(index, 1);
  }

  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/app-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }
  setOrganizerValue(event) {
    let user = this.authService.getCurrentUser();
    this.userId = user.id;
    if(event.target.checked) {
      this.createPageForm.controls['organizer'].setValue(user.first_name);
      this.createPageForm.controls['organizer_email'].setValue(user.email);
      if(user.profile_pic_url) {
        this.imageUrl =  user.profile_pic_url;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', this.imageUrl);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          const blob = xhr.response;
          const fileName = 'image.png'; // You can change the file name
          const file = new File([blob], fileName, { type: 'image/png' });
          this.uploadImageCall(file);
          // Now you have the file object, you can send it to the server or store it locally
        };
        xhr.send();
        this.imageUploaded = {
          'image': user.profile_pic,
        }
      }
    }
    else {
      this.createPageForm.controls['organizer'].setValue('');
      this.createPageForm.controls['organizer_email'].setValue('');
      this.createPageForm.controls['organizer_image'].setValue('');
      this.imageUrl = '';
    }
    
    return true;
  }

  organizerImageUpload(event) {
    if (event.target) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        var URL = window.URL;
        this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
      }
      reader.readAsDataURL(file);
    
      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
      };
      this.uploadImageCall(file);
    }
  }

  uploadImageCall(fileData) {
    var formData = new FormData;
    formData.append('image',fileData);
    this.apiService.postRequest('admin/organizer-image', formData).subscribe(
      (response: any) => {
          if (response.success) {
            this.imageUploaded = {
              'image': response.image,
            }
          }
      },
      err => {
          console.log(err);
      },
    );
  }

  removeImage() {
    this.imageUrl = '';
    this.imageUploaded = {
      'image': null,
    };
  }
  modifyEndDate(event) {
    this.minEndDate = event.target.value;
    const date = new Date(event.value);
    if(date) {
      this.selectedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    }
  }
  showCalendarItems(event) {
    if(event.target.checked) {
      this.createPageForm.controls['is_calendar_event'].setValue(1);
      this.isCalendarEvent = true;

      this.createPageForm.controls['location'].setValidators([Validators.required]);
      this.createPageForm.controls['start_date'].setValidators([Validators.required]);
      this.createPageForm.controls['start_time'].setValidators([Validators.required]);
      // this.createPageForm.controls['end_date'].setValidators([Validators.required]);
      this.createPageForm.controls['end_time'].setValidators([Validators.required]);
      this.createPageForm.controls['organizer_email'].setValidators([Validators.required]);
      this.createPageForm.controls['organizer'].setValidators([Validators.required]);
      // this.createPageForm.controls['organizer_designation'].setValidators([Validators.required]);
    }
    else {
      this.createPageForm.controls['is_calendar_event'].setValue(0);
      this.isCalendarEvent = false;
       
      this.createPageForm.controls['location'].clearValidators();
      this.createPageForm.controls['location'].updateValueAndValidity();
      this.createPageForm.controls['start_date'].clearValidators();
      this.createPageForm.controls['start_date'].updateValueAndValidity();
      this.createPageForm.controls['start_time'].clearValidators();
      this.createPageForm.controls['start_time'].updateValueAndValidity();
      // this.createPageForm.controls['end_date'].clearValidators();
      // this.createPageForm.controls['end_date'].updateValueAndValidity();
      this.createPageForm.controls['end_time'].clearValidators();
      this.createPageForm.controls['end_time'].updateValueAndValidity();
      this.createPageForm.controls['organizer_email'].clearValidators();
      this.createPageForm.controls['organizer_email'].updateValueAndValidity();
      this.createPageForm.controls['organizer'].clearValidators();
      this.createPageForm.controls['organizer'].updateValueAndValidity();
      // this.createPageForm.controls['organizer_designation'].clearValidators();
      // this.createPageForm.controls['organizer_designation'].updateValueAndValidity();
    }
  }
  getLocalTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}

