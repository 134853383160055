import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/services/api.service';

@Component({
  selector: 'app-staff-alert-view',
  templateUrl: './staff-alert-view.component.html',
  styleUrls: ['./staff-alert-view.component.scss']
})
export class StaffAlertViewComponent implements OnInit {

  @Input() id: number;
  alertTitle: string;
  alertDesc: string;
  readBy: string;
  read: boolean;
  expire: string;
  createdAt: string;
  documentName: string;
  documentUrl: string;

  constructor(private apiService: ApiService, private modal: NgbActiveModal) { }

  ngOnInit() {
        this.apiService.getRequest('admin/alert/'+ this.id).subscribe(
        (response: any) => {
            if(response.success) {
                this.read = false;
                this.alertTitle = response.data.title;
                this.alertDesc = response.data.description;
                this.readBy = response.data.users_read;
                if(this.readBy.length > 0) {
                    this.read = true;
                }
                this.expire = response.data.expiry_date;
                this.createdAt = response.data.createdAt;
                this.documentName = response.data.document_name;
                this.documentUrl = response.data.document_url;
            }
        });
  }
  closeModal(result?: any) {
      this.modal.close();
  }
}
