import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgbModal, NgbModalOptions  } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-mindful-learning-report',
  templateUrl: './mindful-learning-report.component.html',
  styleUrls: ['./mindful-learning-report.component.scss']
})
export class MindfulLearningReportComponent implements OnInit {
  page = 1;
  collectionSize: any;
  pageSize: any;
  lastPage: any;
  lesson : string;
  title : string;
  chapterCount : any;
  learnersCount: any;
  completedLearners: any;
  listData: any;
  errorMessage = null;
  total_progress: any;
  @ViewChild('dateRangeModel', { static: false }) dateRangeModel: TemplateRef<any>;
  accoModalRef: any;
  todayDate:Date = new Date();
  dateForm: FormGroup;
  dateRange: any;

  constructor(private formBuilder: FormBuilder, private modal: NgbModal,private location: Location, public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.dateForm = this.formBuilder.group({
      'start_date' : [''],
      'end_date' : [''],
    });
    this.activatedRoute.params.subscribe((params) => {
      this.lesson = params['lesson'];
      if (params['page']) {
        this.page = params['page'];
      }
      this.getLesson(this.page);
    });
  }

  getLesson(page: any) {
    let postData = {
      'page': page,
      'from_date' : this.dateForm.value.start_date,
      'to_date' : this.dateForm.value.end_date,
    }
    this.apiService.postRequest('admin/mindful-learning/report/'+ this.lesson,postData).subscribe(
      (response: any) => {
        if (response.success) {
          this.title = response.data.page.title;
          this.chapterCount = response.data.total_chapters ? response.data.total_chapters : 0;
          this.learnersCount = response.data.learners_count ? response.data.learners_count : 0;
          this.completedLearners = response.data.full_completed_learners;
          this.listData = response.data.chapters.data;
          this.page = response.data.chapters.current_page;
          this.collectionSize = response.data.chapters.total;
          this.pageSize = response.data.chapters.per_page;
          this.lastPage = response.data.chapters.last_page;
          this.total_progress = response.data.total_progress;
        }
      },
      (err: any) => console.log(err),
    );
  }
  getMWtListPage(pageNumber: any) {
    this.router.navigate(['wellness-pages/mindful-learning/report/'+ this.lesson, {page:pageNumber}]);
  }
  
  downloadReport() {
    let postData = {
      'from_date' : this.dateForm.value.start_date,
      'to_date' : this.dateForm.value.end_date,
    }
    this.apiService.postRequest('admin/mindful-learning/report/download/'+ this.lesson,postData).subscribe(
      (response: any) => {
        if (response.success) {
          const link = document.createElement('a');
          link.href = response.path;
          link.setAttribute('download', response.file_name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          this.errorMessage = response.message;
        }
      },
      (err: any) => console.log(err),
    );
  }
  triggerDateRange() {
    const modalOptions: NgbModalOptions = {
      windowClass: 'ngb-date-range-modal' // Add your custom class here
    };
    this.accoModalRef = this.modal.open(this.dateRangeModel, modalOptions);
  }
  closeModal(result?: any) {
    this.accoModalRef.close();
  }
  filterList() {
    this.closeModal();
    let startDate = this.dateForm.value.start_date;
    let endDate = this.dateForm.value.end_date;
    if(startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(startDate) + " to " + this.formatDateTime(endDate) + ")";
    } else if(startDate && !endDate) {
      this.dateRange = "(" +  this.formatDateTime(startDate) + ")";
    } else if(!startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(endDate) + ")";
    } else {
      this.dateRange = null
    }

    this.getLesson(this.page);
  }
  resetDates() {
    this.dateForm.controls['start_date'].setValue(null); 
    this.dateForm.controls['end_date'].setValue(null); 
    this.dateRange = null
    this.getLesson(this.page);
    this.closeModal();
  }
  onBackClick() {
    this.location.back();
  }
  formatDateTime(date) {
    if (!date) {
      return "";
    }
    if (!(moment(date).isValid())) {
      return "";
    }
    // return moment(date).tz("America/New_York").format('MM/DD/YYYY hh:mm:ss A');
    return moment(moment.utc(date).toDate()).local().format('MM/DD/YYYY');
  }
}
