import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { AuthStorageService } from '../shared/services/auth.storage.service';
import { LoaderService } from '../loader.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private totalRequests: number = 0;
  
  constructor (private authService: AuthService, private authStorageService : AuthStorageService,
    private router: Router, private loaderService: LoaderService) {
  }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes("tip-unseen") && !req.url.includes("praise-or-complaints-count") && !req.url.includes("zone-check-unseen") && !req.url.includes("admin/content-pages")
    && !req.url.includes("admin/wellness-pages")) {
      this.loaderService.isLoading.next(true);
      this.totalRequests++;
    }
    if (this.authStorageService.getToken()) {
      req = this.addToken(req, this.authStorageService.getToken());
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.handleLoader(req);
      }),
      catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if(error.url == environment.apiUrl + 'refresh-token') {
          this.isRefreshing = false;
          this.handleAuthenticationError(error);
          return throwError(error);
        }
        return this.handle401Error(req, next);
      } else {
        return throwError(error);
      }
    }));

  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
  
      return this.authService.refreshToken().pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.token);
          return next.handle(this.addToken(request, response.token));
        }));
  
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private handleAuthenticationError(err: any) {
    this.authStorageService.removeToken();
    this.authStorageService.removePermissions();
    this.router.navigate(['/login']);
  }

  private handleLoader(req) {
    if (!req.url.includes("tip-unseen") && !req.url.includes("praise-or-complaints-count") && !req.url.includes("zone-check-unseen") && !req.url.includes("admin/content-pages")
    && !req.url.includes("admin/wellness-pages")) {
      this.totalRequests--;
    }
    if(this.totalRequests == 0) {
      this.loaderService.isLoading.next(false);
    }
  }

}
