import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dynamic-content-management-edit',
  templateUrl: './dynamic-content-management-edit.component.html',
  styleUrls: ['./dynamic-content-management-edit.component.scss']
})
export class DynamicContentManagementEditComponent implements OnInit {
  dropdownList = [];
  dropdownSettings = {};
  pageEditForm: FormGroup;
  url = '';
  errorMessage = null;
  pageId: string;
  title: string;
  template: string;
  icon: string;
  selectedTemplate = null;
  isImageUploaded = false;
  templeteUrl = '';
  templateIcon = '';
  constructor(private location: Location,private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.pageId = params['id'];
    });
    this.getPage();
    this.dropdownList = [];
    this.dropdownSettings = { 
      singleSelection: true, 
      text:"Select Template",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };

    this.pageEditForm = this.formBuilder.group({
      title: ['', Validators.required],
      template: [this.template],
      icon: [''],
    },
    {});
  }

  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('icon',event.target.files[0]);
      this.apiService.postRequest('admin/upload-template-icon', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
              this.isImageUploaded = true;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }

  getPage () {
    this.apiService.getRequest('admin/get-content-page-by-id/' + this.pageId).subscribe(
      (response: any) => {
        if(response.success) {
          this.title = response.data.title;
          this.selectedTemplate = this.template = response.data.template_id;
          this.url = response.data.icon;
          this.templeteUrl = response.data.defaultUrl+response.data.template.icon;
          this.templateIcon = response.data.templateIcon;
          this.pageEditForm.get('template').disable()

          this.apiService.getRequest('admin/content-templates').subscribe(
            (response: any) => {
              if(response.success) {
                var templateList = response.data;
                templateList.forEach((template: any) => {
                  this.dropdownList.push({"id":template.id,"itemName":template.title});
                  if ((template.id == this.selectedTemplate) && (template.icon != this.templateIcon)) {
                    this.isImageUploaded = true;
                  }
                });
              }
            },
            (err: any) => console.log(err),
          );
      }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.pageEditForm.valid) {
      let postData = {
        'title': this.pageEditForm.value.title,
        'template': this.pageEditForm.value.template,
        'icon': this.url,
      }
      this.apiService.putRequest('admin/get-content-page-by-id/' + this.pageId, postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
                this.errorMessage = null;
                this.router.navigate(['content-pages']);
                
              } else {
                this._flashMessagesService.show('Unable to update !!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }
  
  removeImage() {
    this.url = this.templeteUrl;
    this.isImageUploaded = false;
  }
  
}