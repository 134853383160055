import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { ValidationService } from '../../shared/services/validation.service';
import { MustMatch } from '../../validator';
import { Location } from '@angular/common';

@Component({
  selector: 'app-team-create',
  templateUrl: './team-create.component.html',
  styleUrls: ['./team-create.component.css']
})
export class TeamCreateComponent implements OnInit {
  teamCreateForm: FormGroup;
  apiErrorMessage: string;
  passwordPattern : string;

  constructor(private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.teamCreateForm = this.formBuilder.group({
      name: ['', Validators.required]
    },
    {});
  }

  onSubmit() {
    if (this.teamCreateForm.valid) {
      let postData = {
        'name': this.teamCreateForm.value.name,
      }

      this.apiService.postRequest('admin/team', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this.router.navigate(['team']);
                this._flashMessagesService.show('Team Created Successfully', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

}
