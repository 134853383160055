import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStorageService } from './auth.storage.service';
import { ApiService } from './api.service';
import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs-compat';

@Injectable()
export class AuthService {
  public user = new BehaviorSubject(null);

  constructor(private apiService: ApiService, private authStorageService: AuthStorageService, private router: Router,private httpClient: HttpClient) {
  }


  login(data: any) {
    const email = data.value.email;
    const password = data.value.password;
    const otp = data.value.otp;
    const rememberMe = data.value.remember_me;
    return this.apiService.login(email, password, otp);
  }

  isLoggedIn() {
    return !!this.authStorageService.getToken();
  }

  isAuthenticated(): boolean {
    let token = this.authStorageService.getToken();
    if (token) {
        return true;
    }
    return false;
  }
  logout() {
    return this.apiService.logout();
  }

  // getCurrentUser() {
  //   return this.apiService.getCurrentUser();
  // }

  forgotPassword(data: any) {
    return this.apiService.postRequest('forgotPassword', data);
  }
  
  isUserPermitted(allowedRoles) {
    let intersection, specialIntersection;
    if (allowedRoles) {
      let currentUserRole = this.getCurrentUserRole();
      let userSpecificRole = this.getUserSpecificRole();
      if (currentUserRole) {
        intersection = currentUserRole.filter(x => allowedRoles.includes(x));
      }
      if (userSpecificRole) {
        specialIntersection = userSpecificRole.filter(x => allowedRoles.includes(x));
      }
      if(intersection.length > 0 || specialIntersection.length > 0) {
        return true;
      } else {
        // start a new navigation to redirect to dashboard page
        this.router.navigate(['/dashboard']);
        // abort current navigation
        return false;
      }
    }
  }

  refreshToken() {
    return this.httpClient.post<any>(environment.apiUrl + 'refresh-token', null)
      .pipe(tap(
        (response: any) => {
          this.authStorageService.setToken(response.token);
        }
      ));
  }

  setCurrentUser() {
    const promise = new Promise((resolve, reject) => {
      this.apiService.getCurrentUser().subscribe(
        (res) => {
          if (res && res['success'] == true) {
            this.user.next(res['data']);

            resolve(true);
          } else {
            reject(false)
          }
        },
        (err) => {
          reject(false)
        }
      );
    });
    return promise;
  }

  getCurrentUser() {
    let currentUser = this.user.getValue();
    return currentUser;
  }

  getCurrentUserRole() {	
    let currentUser = this.user.getValue();
    if(currentUser) {
      return currentUser['permission_list'];
    }
    return false;
  }

  getUserSpecificRole() {	
    let currentUser = this.user.getValue();
    if(currentUser) {
      return currentUser['special_permissions'];
    }
    return false;
  }

}
