import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../upload-adapter";

@Component({
  selector: 'sex-offender',
  templateUrl: './sex-offender.component.html',
  styleUrls: ['./sex-offender.component.scss']
})
export class SexOffenderComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  images = [];
  // url: string  = "";
  link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  }; 
    
  constructor(private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.router.navigate(['content-pages']);
    this.getData();
    this.createPageForm = this.formBuilder.group({
      // 'name': [],
      'desc': ['',Validators.required],
      // 'image': [''],
      // 'file': [''],
      'link': ['',Validators.required],
      // 'link_name': ['']
    });

  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  // updateKey (eventData) {
  //   let key = eventData.toLowerCase( ).replace(' ', '_');
  //   this.createPageForm.controls['page_key'].setValue(key);
  // }
  changeType(eventData) {
    console.log(eventData);
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  getData () {
    this.apiService.getRequest('admin/standard-page?key=sex_offender').subscribe(
      (response: any) => {
        if(response.success) {
          // this.name = response.data.name;
          this.desc = response.data.desc;
          this.link = response.data.link;
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': "Sex Offender",
        'desc': this.createPageForm.value.desc,
        // 'image': this.url,
        'link': this.createPageForm.value.link,
        // 'link_name': this.createPageForm.value.link_name,
      }
      this.apiService.postRequest('admin/generic-page?key=sex_offender', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

}
