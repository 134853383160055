import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-offduty-list',
  templateUrl: './offduty-list.component.html',
  styleUrls: ['./offduty-list.component.scss']
})
export class OffDutyJobsListComponent implements OnInit {
  singleOffDutyTitle="";
  offDutyTitle="";
  offdutyjobs: any;
  offdutyjob_applicants: any;
  remaining_slots: any;
  createdBy:any;
  modalRef: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  userId: any ;
  // pagination
  page = 1;
  search = "";
  collectionSize: any;
  pageSize: any;
  lastPage: any;
  apiLink:any;
  accoModalRef:any;
  todayDate:Date = new Date();
  dateForm: FormGroup;
  dateRange: any;
  errorMessage = null;
  minDate = null;

  @ViewChild('appliedModal', {static: false}) appliedModal : TemplateRef<any>;
  @ViewChild('dateRangeModel', { static: false }) dateRangeModel: TemplateRef<any>;

  constructor(private formBuilder: FormBuilder,public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // this.getOffDutyJobs();
    this.offDutyTitle = environment.offDutyTitle;
    this.singleOffDutyTitle = environment.offDutyTitle;
    this.dateForm = this.formBuilder.group({
      'start_date' : [''],
      'end_date' : [''],
      'include_archive' : [''],
    });
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getOffDutyJobs(this.page,this.search);
      }else {
        this.router.navigate(['off-duty-jobs', {page:1}]);
      }
    });

  }

  getOffDutyJobs(page:any,search:any) {
    if (search == "") {
      this.activatedRoute.params.subscribe((params) => {
        page = params['page'];
      });
    }
    this.search = search;
    this.apiLink = 'admin/offdutyjobs?page=' + page + "&search=" + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.offdutyjobs = response.data;
          this.offdutyjobs = response.data.data;
          if(this.offdutyjobs.length == 0 && this.page > 1) { // to handle deletion of single record page
            this.page -= 1;
            this.router.navigate(['off-duty-jobs', {page:this.page, search:this.search}]);
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;

        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteOffDutyJob(id: any) {
    this.apiService.deleteRequest('admin/offdutyjobs/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getOffDutyJobs(this.page,this.search);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }

  triggerDateRange() {
    this.dateForm.controls['include_archive'].setValue(false);
    const modalOptions: NgbModalOptions = {
      windowClass: 'ngb-date-range-modal' // Add your custom class here
    };
    this.accoModalRef = this.modal.open(this.dateRangeModel, modalOptions);
  }
  closeModalRange(result?: any) {
    this.accoModalRef.close();
  }
  downloadOffdutyExcel() {
    let startDate = this.dateForm.value.start_date;
    let endDate = this.dateForm.value.end_date;
    if(startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(startDate) + " to " + this.formatDateTime(endDate) + ")";
    } else if(startDate && !endDate) {
      this.dateRange = "(" +  this.formatDateTime(startDate) + ")";
    } else if(!startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(endDate) + ")";
    } else {
      this.dateRange = null
    }
    let postData = {
      'from_date' : this.dateForm.value.start_date,
      'to_date' : this.addCurrentTime(this.dateForm.value.end_date),
      'include_archive' : this.dateForm.value.include_archive,
    }
    this.apiService.postRequest('admin/offdutyjobs/export',postData).subscribe(
      (response: any) => {
        if (response.success) {
          console.log(response.data.exportUrl)
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.setAttribute('href', response.data.exportUrl);
          link.setAttribute('download', response.data.fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          // this.closeModal();
          this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          this.errorMessage = response.message;
        }
      },
      (err: any) => console.log(err),
    );
    this.closeModalRange();
  }
  resetDates() {
    this.dateForm.controls['include_archive'].setValue(false);
    this.dateForm.controls['start_date'].setValue(null);
    this.dateForm.controls['end_date'].setValue(null);
    this.dateRange = null;
  }
  viewJobApplicants(id: any) {
    this.apiService.getRequest('admin/offdutyjobs/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this.remaining_slots = response.data.remaining_slots;
          this.createdBy = response.data.assigned_by;
        }
      },
      (err: any) => console.log(err),
    );
    this.apiService.getRequest('admin/offdutyjobs-applied/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this.offdutyjob_applicants = response.data;
          this.modalRef = this.modal.open(this.appliedModal);
        } else {
          this._flashMessagesService.show('No applicants available!', { cssClass: 'alert-warning' });
        }
      },
      (err: any) => console.log(err),
    );
  }

  closeModal() {
    this.modalRef.close();
  }

  closeOffDutyJob(id: any) {
    this.apiService.postRequest('admin/offdutyjobs-close/' + id, []).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show(this.offDutyTitle + ' closed successfully', { cssClass: 'alert-success' });
          this.getOffDutyJobs(this.page,this.search);
        } else {
          this._flashMessagesService.show(this.offDutyTitle + ' closing failed!', { cssClass: 'alert-warning' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  duplicateOffDutyJob(id: any) {
    this.apiService.postRequest('admin/offdutyjobs-duplicate/' + id, []).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show(this.offDutyTitle + ' duplicated successfully', { cssClass: 'alert-success' });
          this.getOffDutyJobs(this.page,this.search);
        } else {
          this._flashMessagesService.show(this.offDutyTitle + ' duplicated failed!', { cssClass: 'alert-warning' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  getOffDutyJobtListPage(pageNumber: any) {
    this.router.navigate(['off-duty-jobs', {page:pageNumber,search:this.search}]);
  }

  formatDateTime(date) {
    if (!date) {
      return "";
    }
    if (!(moment(date).isValid())) {
      return "";
    }
    // return moment(date).tz("America/New_York").format('MM/DD/YYYY hh:mm:ss A');
    return moment(moment.utc(date).toDate()).local().format('MM/DD/YYYY');
  }

  setMinDate(event){
    this.minDate = event.value;
  }

  addCurrentTime(selectedDate: Date): Date {
    const currentDate = new Date();
    if(selectedDate){
      return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
    }
    return selectedDate;
  }
}
