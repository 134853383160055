import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'police-app-admin-ui';
  
  constructor(private titleService: Title) {
    // Set document title dynamically based on environment
    this.titleService.setTitle(environment.title || 'UCP APP BY STACKBENCH  | Admin');
    this.setFavicon(environment.favIcon ? environment.favIcon : 'favicon.ico');
  }

  setFavicon(iconUrl: string) {
    const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = "assets/images/" + iconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

}
