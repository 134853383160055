import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filterFunction: (item: any) => boolean): any {
    console.log('filter pipe =====> ',items);
    if (!items || !filterFunction) {
      return items;
    }
    return items.filter(filterFunction);
  }
}
