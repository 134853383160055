import { Injectable } from '@angular/core';

@Injectable()
export class AuthStorageService {

  constructor() {
  }
  /*
    function to get the current token
  */
  getToken() {
    return localStorage.getItem('session_token');
  }
  /*
  function to create a token
  */
  setToken(token: string, remember?: boolean) {
    localStorage.setItem('session_token', token);
  }
  /*
  set permissions in localstorage
  */
  setUserPermissions(permissions: Array<string>): void {
    localStorage.setItem('permissions', JSON.stringify(permissions));
  }
  /*
  get permissions from localstorage
  */
  getUserPermissions(): Array<string> {
    let permissions = new Array<string>();
    try {
      permissions = <string[]> JSON.parse(localStorage.getItem('permissions'));
    } catch (e) {
      
    }
    return permissions;
  }
  /*
  function to remove session token
  */
  removeToken() {
    localStorage.removeItem('session_token');
  }
  /*
  function to remove session permissons
  */
  removePermissions() {
    localStorage.removeItem('permissions');
    let user =localStorage.getItem('user');
    localStorage.clear();
    if(user)
    {
      localStorage.setItem('user', user);
    }
  }
  /*
  function to remember a user
  */
  rememberUser(data: any, remember: boolean) {
    data['remember_me'] = remember;
    if(remember){
      localStorage.setItem('user', JSON.stringify(data));
    } else {
      localStorage.removeItem('user');
    }
  }
  /*
  function to get the current user
  */
  getUser() {
    let user = new Array<string>();
    try {
      user = <string[]> JSON.parse(localStorage.getItem('user'));
    } catch (e) {
      
    }
    return user;
  }
}
