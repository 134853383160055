import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../upload-adapter";
import { Location } from '@angular/common';

@Component({
  selector: 'employee-assistance-program',
  templateUrl: './employee-assistance-program.component.html',
  styleUrls: ['./employee-assistance-program.component.scss']
})
export class EmployeeAssistanceProgramComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  images = [];
  // url: string  = "";
  link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };


  allFilesUploaded=[];
  stringfiedAttachments = '';
  fileToUpload: File = null;
  uploadedDoc = false;
  imagestobeDeleted=[];
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;
  @Input() pageTitle: string ;
  @Input() dataUrl: string ;
  @Input() postUrl: string ;
  apiLink:any;
  @Input() backUrl: string ;

  constructor(private location: Location, private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.getData();
    this.createPageForm = this.formBuilder.group({
      // 'name': [],
      'desc': ['',Validators.required],
      // 'image': [''],
      // 'file': [''],
      // 'link': ['',Validators.required],
      // 'link_name': ['']
      'document': [''],
    });

  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  // updateKey (eventData) {
  //   let key = eventData.toLowerCase( ).replace(' ', '_');
  //   this.createPageForm.controls['page_key'].setValue(key);
  // }
  changeType(eventData) {
    console.log(eventData);
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  getData () {
    this.apiService.getRequest(this.dataUrl).subscribe(
      (response: any) => {
        if(response.success) {
          // this.name = response.data.name;
          this.desc = response.data.desc;
          // this.link = response.data.link;
          if(response.data.document_name && response.data.document_url && response.data.document){
            this.documentUploaded = {
              'doc_name': response.data.document_name,
              'doc_link': response.data.document_url,
              'document': response.data.document,
            }
            this.isDocUploaded = true;
          }
        }
      },
      (err: any) => console.log(err),
    );

  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': this.pageTitle,
        'desc': this.createPageForm.value.desc,
        // 'image': this.url,
        // 'link': this.createPageForm.value.link,
        // 'link_name': this.createPageForm.value.link_name,
      }
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      this.apiService.postRequest(this.postUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }

  }

  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/app-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    this.router.navigate(['wellness-pages']);
  }
}
