import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'warrents-list',
  templateUrl: './warrents-list.component.html',
  styleUrls: ['./warrents-list.component.scss']
})
export class WarrentsListComponent implements OnInit {
  warrents: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  warrentId: any ;
  // pagination
  page = 1;
  search = "";
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  apiLink:any;

  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.router.navigate(['content-pages']);
    // this.getWarrents();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getWarrents(this.page,this.search);
      }else {
        this.router.navigate(['warrents', {page:1}]);
      }
    });
  }

  getWarrents(page:any,search:any) {
    if (search == "") {
      this.activatedRoute.params.subscribe((params) => {
        page = params['page'];
      });
    }
    this.search = search;
    this.apiLink = 'admin/warrents?page=' + page + "&search=" + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        console.log(response);
        if(response.success) {
          // this.warrents = response.data;
          this.warrents = response.data.data;
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deletewarrent(id: any) {
    this.apiService.deleteRequest('admin/app-page/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getWarrents(this.page,this.search);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  getMWtListPage(pageNumber: any) {
    this.router.navigate(['warrents', {page:pageNumber,search:this.search}]);
  }

}
