import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StaffChangePasswordComponent } from 'src/app/staff/staff-change-password/staff-change-password.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-staff-family-edit',
  templateUrl: './staff-family-edit.component.html',
  styleUrls: ['./staff-family-edit.component.css']
})
export class StaffFamilyEditComponent implements OnInit {

  staffEditForm: FormGroup;
  apiErrorMessage: string;
  userId: string;
  loggedUserId : string;
  hasStaffAccess: boolean;
  staffId: string;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  btnInvitDisable: boolean = false;
  lblInvitationBtn: string = "Resend Invitation Mail";
  emailVerified: number = 0;

  constructor(private authService: AuthService,private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private modal: NgbModal) { }

  ngOnInit() {
    let userdata = this.authService.getCurrentUser();
    let permissions = userdata.permission_list;
    this.loggedUserId = userdata.id;
    this.hasStaffAccess = permissions.indexOf("STAFF_ACCESS") !== -1;
    this.activatedRoute.params.subscribe((params) => {
      this.userId = params['id'];
      console.log('userId', this.userId);

    });
    this.activatedRoute.params.subscribe((params) => {
      this.staffId = params['staff-id'];
      console.log('staffId', this.staffId);
    });

    this.getAdmin();
    this.staffEditForm = this.formBuilder.group({
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'email': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      'phone': ['',[Validators.pattern("[0-9]+")]]
    });
  }

  onSubmit() {
    if (this.staffEditForm.valid) {

        let putData = {
          'first_name': this.staffEditForm.value.firstName,
          'last_name': this.staffEditForm.value.lastName,
          'email': this.staffEditForm.value.email,
          'phone': this.staffEditForm.value.phone,
          'user_type': 3,
        }

        this.apiService.putRequest('admin/staff-family/'+ this.staffId+ '/edit/' + this.userId, putData).subscribe(
          (response: any) => {
              if (response.success) {
                  this.router.navigate(['staff-family/'+ this.staffId]);
                  this._flashMessagesService.show('Update successful', { cssClass: 'alert-success' });
              } else {
                  this.apiErrorMessage = response.message;
                  this._flashMessagesService.show('Update Failed!', { cssClass: 'alert-danger' });
              }
          },
          err => {
              console.log(err);
              this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          },
        );
      
    }
  }

  getAdmin() {
    this.apiService.getRequest('admin/staff-family/' + this.staffId+ '/edit/' + this.userId).subscribe(
      (response: any) => {
        if(response.success) {
          this.firstName = response.data.first_name;
          this.lastName = response.data.last_name;
          this.email = response.data.email;
          this.phone = response.data.phone;
          this.emailVerified = response.data.email_verified;
        }
      },
      (err: any) => console.log(err),
    );
  }

  changePassword(id: any) {
    const modalRef = this.modal.open(StaffChangePasswordComponent);
    modalRef.componentInstance.id = id;
    modalRef.result.then((data: any) => {
        if(data.success) {
          this._flashMessagesService.show('Staff family member password changed', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Password change failed!', { cssClass: 'alert-danger' });
        }
    }).catch((e) => { console.log(e); });
  }

  resendInvitationMail(id: any) {
    let putData = [];
    this.btnInvitDisable = true;
    this.apiService.putRequest('admin/send-invitation-email/' + this.userId, putData).subscribe(
      (response: any) => {
          if (response.success) {
              this.lblInvitationBtn = "Invitation Mail Sent";
              this._flashMessagesService.show('Invitation Mail Sent Successfully', { cssClass: 'alert-success' });
          } else {
              this.apiErrorMessage = response.message;
              this._flashMessagesService.show('Invitation Mail Failed!', { cssClass: 'alert-danger' });
          }
      },
      err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      },
    );
  }
  onBackClick() {
    this.location.back();
  }

}
