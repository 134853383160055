import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class CommonService {

  private userProfilePic = new Subject<any>();

  constructor(private apiService: ApiService) {}

  //function to return 16 digit id in xxxx-xxxx-xxxx-xxxx format
  formatPlantID(id: any) {
    return String(id).match(new RegExp('.{1,4}', 'g')).join("-");
  }
  getCurrentUser() {
    return this.apiService.getCurrentUser();
  }
  
  sendUpdate(data: string) { //the component that wants to update user profile pic, calls this fn
    this.userProfilePic.next({ data: data }); //next() will feed the value in Subject
  }

  getUpdate() { //the receiver component calls this function 
    return this.userProfilePic; //it returns a user profile pic url to which the receiver funtion will subscribe
  }

}
