import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ValidationService } from '../../shared/services/validation.service';
import { MustMatch } from '../../validator';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { env } from 'process';

@Component({
  selector: 'app-offduty-create',
  templateUrl: './offduty-create.component.html',
  styleUrls: ['./offduty-create.component.scss']
})
export class OffDutyJobsCreateComponent implements OnInit {
    public Editor = ClassicEditor;
  offDutyJobCreateForm: FormGroup;
  apiErrorMessage: string;
  passwordPattern : string;
  todayDate:Date = new Date();
  description;
  minEndDate = new Date();
  selectedUsersOnly = false;
  selectedTeamOnly: boolean = false;
  hasStaff = true;
  hasTeam = true;
  selectedItems = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  dropdownList = [];
  dropdownList2 = [];
  offDutyTitle = "";

  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link','|','imageUpload']
  };

  constructor(private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.dropdownList = [];
    this.offDutyTitle = environment.offDutyTitle;
    this.dropdownSettings = {
      singleSelection: false,
      text:"Select Staff",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      text:"Select Team",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.apiService.getRequest('admin/staff').subscribe(
      (response: any) => {
        if(response.success) {
          var staffList = response.data;
          staffList.forEach((staff: any) => {
            this.dropdownList.push({"id":staff.id,"itemName":staff.first_name + ' ' + staff.last_name});
          });
        }
      },
      (err: any) => console.log(err),
    );
    this.apiService.getRequest('admin/team').subscribe(
      (response: any) => {
        if(response.success) {
          var teams = response.data;
          teams.forEach((team: any) => {
            this.dropdownList2.push({"id":team.id,"itemName":team.name});
          });
        }
      },
      (err: any) => console.log(err),
    );
    this.offDutyJobCreateForm = this.formBuilder.group({
      title: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      payment: ['', Validators.required],
      location: ['', Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      description: ['', Validators.required],
      staff_slots: ['1'],
      alertType: ['1', Validators.required],
      staffs : [''],
      teams: [''],
    },
    {});
  }

  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }

  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.files[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              console.log(response.path);
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }

  onSubmit() {
    if (this.offDutyJobCreateForm.value.alertType == 2 && this.offDutyJobCreateForm.value.staffs.length == 0) {
      this.hasStaff = false;
    } else {
      this.hasStaff = true;
    }
    if (this.offDutyJobCreateForm.value.alertType == 3 && this.offDutyJobCreateForm.value.teams.length == 0) {
      this.hasTeam = false;
    } else {
      this.hasTeam = true;
    }
    if ((this.hasStaff && this.hasTeam) && this.offDutyJobCreateForm.valid) {
      let postData = {
        'title': this.offDutyJobCreateForm.value.title,
        'start_date': this.offDutyJobCreateForm.value.start_date,
        'end_date': this.offDutyJobCreateForm.value.end_date,
        'payment': this.offDutyJobCreateForm.value.payment,
        'location': this.offDutyJobCreateForm.value.location,
        'start_time': this.offDutyJobCreateForm.value.start_time,
        'end_time': this.offDutyJobCreateForm.value.end_time,
        'description': this.offDutyJobCreateForm.value.description,
        'staff_slots': this.offDutyJobCreateForm.value.staff_slots,
        'type': this.offDutyJobCreateForm.value.alertType,
        'staffs' : this.offDutyJobCreateForm.value.staffs,
        'teams' : this.offDutyJobCreateForm.value.teams
      }
      if (!this.selectedUsersOnly) {
        postData['staffs'] = [];
      }
      if (!this.selectedTeamOnly) {
        postData['teams'] = [];
      }
      this.apiService.postRequest('admin/offdutyjobs', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this.router.navigate(['off-duty-jobs']);
                this._flashMessagesService.show(this.offDutyTitle + ' Created Successfully', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

  modifyEndDate(event) {
    this.minEndDate = event.target.value
  }

  handleTypeChange(event) {
    if (event == 1) {
      this.selectedUsersOnly = false;
      this.selectedTeamOnly = false;
      this.hasStaff = true;
      this.hasTeam = true;
    } else if (event == 2) {
      this.selectedUsersOnly = true;
      this.selectedTeamOnly = false;
    } else if(event == 3) {
      this.selectedUsersOnly = false;
      this.selectedTeamOnly = true;
    }
  }

  onItemSelect(item:any){
    console.log(item);
    console.log(this.selectedItems);
    this.hasStaff = true;
    this.hasTeam = true;
  }
  OnItemDeSelect(item:any){
      console.log(item);
      console.log(this.selectedItems);
  }
  onSelectAll(items: any){
      console.log(items);
  }
  onDeSelectAll(items: any){
      console.log(items);
  }
}
