import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { Router } from '@angular/router';
import Chart from 'chart.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wellness-dashboard',
  templateUrl: './wellness-dashboard.component.html',
  styleUrls: ['./wellness-dashboard.component.scss']
})
export class WellnessDashboardComponent implements OnInit {

  totalTopics: number = 0;
  totalChapters: number = 0;
  totalViews: number = 0;
  totalAdmins: number = 0;
  totalStaffs: number = 0;
  daysFilter: number = 180;
  topTopics: any;
  chart: any = []
  chartData: any;
  chartDataCompleted: any;
  chartDataInProgress: any;
  chartDataStagnant: any;
  completedPercent: any;
  inProgressPercent: any;
  stagnantPercent: any;
  recentTopics: any;

  constructor(private router: Router, private apiService: ApiService) { }

  ngOnInit() {
    this.getDashboardData();
  }

  getDashboardData() {
    this.apiService.getRequest('admin/wellness-dashboard?daysfilter=' + this.daysFilter).subscribe(
      (response: any) => {
        if(response.success) {
          this.totalTopics = (response.data.totalTopics) ? response.data.totalTopics : 0;
          this.totalChapters = (response.data.totalChapters) ? response.data.totalChapters : 0;
          this.totalViews = (response.data.totalViews) ? response.data.totalViews : 0;
          this.totalAdmins = (response.data.totalAdmins) ? response.data.totalAdmins : 0;
          this.topTopics = (response.data.topTopics) ? response.data.topTopics : null;
          this.totalStaffs = (response.data.totalStaffs) ? response.data.totalStaffs : 0;
          this.recentTopics = (response.data.recentTopics) ? response.data.recentTopics : null;
          this.chartData = (response.data.chartData) ? response.data.chartData : null;
          this.chartDataCompleted = (response.data.chartData.completed) ? response.data.chartData.completed : "-";
          this.chartDataInProgress = (response.data.chartData.inProgress) ? response.data.chartData.inProgress : "-";
          this.chartDataStagnant = (response.data.chartData.stagnant) ? response.data.chartData.stagnant : "-";
          this.chartData = (response.data.chartData) ? response.data.chartData : null;
          this.completedPercent = (response.data.chartData.completedPercent) ? response.data.chartData.completedPercent : null;
          this.inProgressPercent = (response.data.chartData.inProgressPercent) ? response.data.chartData.inProgressPercent : null;
          this.stagnantPercent = (response.data.chartData.stagnantPercent) ? response.data.chartData.stagnantPercent : null;

        }
        this.createChart();
      },
      (err: any) => console.log(err),
    );
  }

  daysFilterChanged(days) {
    this.daysFilter = days;
    this.getDashboardData();
  }

  selectTopic(){
    document.getElementById('recentAssessments').style.display = 'none';
    document.getElementById('recentTopics').style.display = '';
    document.getElementById('recentTopicBtn').classList.add("selected");
    document.getElementById('recentAssessmentBtn').classList.remove("selected");
  }

  createChart(){

    this.chart = new Chart("MyChart", {
      type: 'doughnut', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: ['Completed', 'In-Progress','Stagnant'],
	      datasets: [{
          data: [this.completedPercent,this.inProgressPercent, this.stagnantPercent],
          backgroundColor: [
            '#44EA88',
            '#6E59FF',
            '#FED101',
          ]
        }],
      },
      options: {
        responsive: true,
        topicCount: this.totalTopics,
        aspectRatio:1.5,
        cutoutPercentage: 80,
        legend: {
          display: false
        },
        plugins: {
          legend: {
            display: false
          }
        }
      },
      plugins: [{
        id: 'text',
        beforeDraw: function(chart) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;

          ctx.restore();
          var fontSize = (height / 240).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";

          var text = chart.options.topicCount+"\nTopics",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;

          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }]

    });
  }
}
