import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-staff-create',
  templateUrl: './staff-create.component.html',
  styleUrls: ['./staff-create.component.css']
})
export class StaffCreateComponent implements OnInit {
  staffCreateForm: FormGroup;
  emailPattern: string;
  passwordPattern : string;

  constructor(private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.staffCreateForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      phone: ['',[Validators.pattern(/^(\d{10}|\d{3}-\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4}|\d{3} \d{3} \d{4}|\(\d{3}\) \d{3}-\d{4}|\+1 \d{3}-\d{3}-\d{4})$/)]],
      staffAdminAccess: [false]
    },
    {});
  }

  onSubmit() {
    if (this.staffCreateForm.valid) {
        let postData = {
          'first_name': this.staffCreateForm.value.firstName,
          'last_name': this.staffCreateForm.value.lastName,
          'email': this.staffCreateForm.value.email,
          'phone': this.staffCreateForm.value.phone,
          'user_type' : 2,
          'staff_admin_access': this.staffCreateForm.value.staffAdminAccess
        }
        this.apiService.postRequest('admin/staff', postData).subscribe(
          (response: any) => {
              if (response.success) {
                  this.router.navigate(['staff']);
                  this._flashMessagesService.show('Staff Created Successfully', { cssClass: 'alert-success' });
              } else {
                  this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
              }
          },
          err => {
              console.log(err);
              this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          },
        );
      
    }
  }
  onBackClick() {
    this.location.back();
  }

}
