import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminUserChangePasswordComponent } from '../admin-user-change-password/admin-user-change-password.component';
import { AdminUserChangePermissionComponent } from '../admin-user-change-permission/admin-user-change-permission.component';
import { ValidationService } from '../../shared/services/validation.service';
import { CommonService } from '../../shared/services/common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin-user-edit',
  templateUrl: './admin-user-edit.component.html',
  styleUrls: ['./admin-user-edit.component.scss']
})
export class AdminUserEditComponent implements OnInit {
  adminUserEditForm: FormGroup;
  apiErrorMessage: string;
  userId: string;
  hasSuperAdminAccess : boolean;
  hasConfianzAccess: boolean;
  firstName: string;
  lastName: string;
  email: string;
  showEmailError: boolean;
  isUserSuperAdmin: boolean;
  isUserConfianzAdmin: boolean;
  phone: string;
  imageUploaded = {
    'image_name': null,
    'image_link': null,
    'profile_pic': null,
  };
  isImageUploaded = false;
  imageToUpload: File = null;
  loggedUserId : string;

  constructor(private authService: AuthService,private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private commonService: CommonService,
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private modal: NgbModal) { 
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
     }

  ngOnInit() {
    let userdata = this.authService.getCurrentUser();
    let permissions = userdata.permission_list;
    this.hasSuperAdminAccess = permissions.indexOf("SUPER_ADMIN_ACCESS") !== -1;
    this.hasConfianzAccess = permissions.indexOf("CONFIANZ_ADMIN") !== -1;

    this.loggedUserId = userdata.id;
    this.showEmailError = false;
    this.activatedRoute.params.subscribe((params) => {
      this.userId = params['id'];
    });
    this.getAdmin();
    this.adminUserEditForm = this.formBuilder.group({
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'email': ['', Validators.required],
      'phone': ['',[Validators.required, Validators.pattern(/^(\d{10}|\d{3}-\d{3}-\d{4}|\d{3}\.\d{3}\.\d{4}|\d{3} \d{3} \d{4}|\(\d{3}\) \d{3}-\d{4}|\+1 \d{3}-\d{3}-\d{4})$/)]],
      'profile_pic': ['']
    });
    this.adminUserEditForm.markAllAsTouched();  // calling mark as touch every time.
  }

  onSubmit() {
    if (this.adminUserEditForm.valid) {

        this.showEmailError = false;
        let putData = {
          'first_name': this.adminUserEditForm.value.firstName,
          'last_name': this.adminUserEditForm.value.lastName,
          'email': this.adminUserEditForm.value.email,
          'phone': this.adminUserEditForm.value.phone,
          'user_type' : 1
        }
        putData['profile_pic'] = this.imageUploaded['profile_pic'];
        this.apiService.putRequest('admin/admin-user/' + this.userId, putData).subscribe(
          (response: any) => {
              if (response.success) {
                  this.router.navigate(['admin-user']);
                  this._flashMessagesService.show('Update successful', { cssClass: 'alert-success' });
              } else {
                  this.apiErrorMessage = response.message;
                  this._flashMessagesService.show('Update Failed!', { cssClass: 'alert-danger' });
              }
          },
          err => {
              console.log(err);
              this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
          }
        ,);
      
    }
  }
  getAdmin() {
    this.apiService.getRequest('admin/admin-user/' + this.userId).subscribe(
      (response: any) => {
        if(response.success) {
          this.firstName = response.data.first_name;
          this.lastName = response.data.last_name;
          this.email = response.data.email;
          this.phone = response.data.phone;
          this.isUserSuperAdmin = response.data.super_admin_access;
          this.isUserConfianzAdmin = response.data.confianz_admin_access;
          if (response.data.profile_pic) {
            this.isImageUploaded = true;
            this.imageUploaded.image_name = response.data.profile_pic;
            this.imageUploaded.image_link = response.data.profile_pic_url;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }

  changePassword(id: any) {
    const modalRef = this.modal.open(AdminUserChangePasswordComponent);
    modalRef.componentInstance.id = id;
    modalRef.result.then((data: any) => {
        if(data.success) {
          this._flashMessagesService.show('Admin user password changed', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Password change failed!', { cssClass: 'alert-danger' });
        }
    }).catch((e) => { console.log(e); });
  }
  changePermission(id: any) {
    const modalRef = this.modal.open(AdminUserChangePermissionComponent);
    modalRef.componentInstance.id = id;
    modalRef.result.then((data: any) => {
        if(data.success) {
          this._flashMessagesService.show('Administrator Permissions Assigned', { cssClass: 'alert-success' });
        } else {
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        }
    }).catch((e) => { console.log(e); });
  }

  onImageUpload(files: FileList){
    let file = files.item(0);
    this.imageToUpload = file;
    let postD = new FormData();
    postD.append('image', this.imageToUpload);
    this.apiService.postRequest('admin/upload-picture',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.imageUploaded = {
          'image_name': response.file_name,
          'image_link': response.path,
          'profile_pic': response.image,
        }
        this.isImageUploaded = true;
        if(response.logged_user_id == this.userId) {
          this.commonService.sendUpdate(response.path);
        }
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeImage() {
    this.isImageUploaded = false;
    this.imageUploaded = {
      'image_name': null,
      'image_link': null,
      'profile_pic': null,
    };
    this.commonService.sendUpdate(null);
  }
  onBackClick() {
    this.location.back();
  }
  
  provideSuperAdminAccess($event,id) {
    let status = 0;
    if($event.target.checked == 1) 
      status = 1;

    let postData = {
      'userId' : id,
      'status' : status
    }

    this.apiService.postRequest('admin/user/super-admin-access',  postData).subscribe(
      (response: any) => {
      if(response.success){
          this._flashMessagesService.show(response.data.message, { cssClass: 'alert-success' });
          this.isUserSuperAdmin = !this.isUserSuperAdmin
      } else {
        this._flashMessagesService.show(response.data.message, { cssClass: 'alert-danger' });
      }
      },
      (error: any) => {
        console.log(error);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      }
    );
  }

  provideConfianzAccess($event,id) {
    let status = 0;
    if($event.target.checked == 1) 
      status = 1;

    let postData = {
      'userId' : id,
      'status' : status
    }

    this.apiService.postRequest('admin/user/confianz-admin-access',  postData).subscribe(
      (response: any) => {
      if(response.success){
          this._flashMessagesService.show(response.data.message, { cssClass: 'alert-success' });
          this.isUserConfianzAdmin = !this.isUserConfianzAdmin
      } else {
        this._flashMessagesService.show(response.data.message, { cssClass: 'alert-danger' });
      }
      },
      (error: any) => {
        console.log(error);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
      }
    );
  }
}
