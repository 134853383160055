import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../shared/services/api.service';
import { MustMatch } from '../../validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  apiErrorMessage: string;
  passwordPattern : string;

  constructor(private apiService: ApiService, private modal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.passwordPattern = '(?=.*[A-Za-z])(?=.*\\d)(?=.*[ ?"#@^$%&/\[=+*!`~:;.,{}<>\-])[A-Za-z\\d@$!%*#?&.{}:;.,<>\/\\+-\[\\]`~]{8,}';
    this.changePasswordForm = this.formBuilder.group({
      'currentPassword': ['', Validators.required],
      'password': ['', Validators.pattern(this.passwordPattern)],
      'confirmPassword': ['', Validators.required]
    },
    {
      validator : MustMatch('password', 'confirmPassword')
    });
  }

  closeModal(result?: any) {
    this.modal.close(result);
  }

  onSubmit() {
    let putData = { 
        'current_password': this.changePasswordForm.value.currentPassword,
        'password': this.changePasswordForm.value.confirmPassword
    };
    this.apiService.putRequest('admin/me/change-password', putData).subscribe(
      (response: any) => {
        if(response.success) {
          this.closeModal(response);
        } else {
          this.apiErrorMessage = response.message;
        }
      },
      (error: any) => {
          this.closeModal(error);
      }
    );
  }

}
