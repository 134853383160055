import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidationService } from '../../shared/services/validation.service';
import { Location } from '@angular/common';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.css']
})
export class TeamEditComponent implements OnInit {
  teamEditForm: FormGroup;
  apiErrorMessage: string;
  teamId: string;

  name: string;
  hasStaffAccess: boolean;

  constructor(private authService: AuthService,private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private modal: NgbModal) { }

  ngOnInit() {
    let userdata = this.authService.getCurrentUser();
    let permissions = userdata.permission_list;
    this.hasStaffAccess = permissions.indexOf("STAFF_ACCESS") !== -1;

    this.activatedRoute.params.subscribe((params) => {
      this.teamId = params['id'];
    });
    this.getTeam();
    this.teamEditForm = this.formBuilder.group({
      'name': ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.teamEditForm.valid) {
      let putData = {
        'name': this.teamEditForm.value.name,
      }

      this.apiService.putRequest('admin/team/' + this.teamId, putData).subscribe(
        (response: any) => {
            if (response.success) {
                this.router.navigate(['team']);
                this._flashMessagesService.show('Update successful', { cssClass: 'alert-success' });
            } else {
                this.apiErrorMessage = response.message;
                this._flashMessagesService.show('Update Failed!', { cssClass: 'alert-danger' });
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  getTeam() {
    this.apiService.getRequest('admin/team/' + this.teamId).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
        }
      },
      (err: any) => console.log(err),
    );
  }
  onBackClick() {
    this.location.back();
  }

}
