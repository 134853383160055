import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'department-edit',
  templateUrl: './department-edit.component.html',
  styleUrls: ['./department-edit.component.scss']
})
export class DepartmentEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editPageForm: FormGroup;
  errorMessage = null;
  departmentId: string;
  images = [];
  key:string = "";
  name: string;
  phone: string;
  email: string;
  desc: string;
  url:string = "";
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  }; 
  @Input() pageTitle: string ;
  @Input() updateUrl: string ;   
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.departmentId = params['id'];
    });
    this.getDepartment();
    this.editPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'desc': [''],
      'image': [''],
      'phone': ['',Validators.pattern("[- +()0-9]+")],
      'email': ['',Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
      // 'link': ['',Validators.required],
      // 'link_name': ['']
    });
    // if(!this.pageTitle) {
    //   this.pageTitle = "Department";
    // }
    // if(!this.updateUrl) {
    //   this.updateUrl = 'admin/standard-page/' + this.departmentId;
    // }
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.files[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  getDepartment () {
    this.apiService.getRequest('admin/app-page/' + this.departmentId).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          this.desc = response.data.desc;
          this.phone = response.data.phone;
          this.email = response.data.email;
          this.url = response.data.image_url;
          this.key = response.data.page_key;
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editPageForm.valid) {
      let postData = {
         'id' : this.departmentId,
        'name': this.editPageForm.value.name,
        'desc': this.editPageForm.value.desc,
        'phone': this.editPageForm.value.phone,
        'email': this.editPageForm.value.email,
        'image' : this.url,
        'type': this.key
      }
      this.apiService.postRequest(this.updateUrl, postData).subscribe(
        (response: any) => {
              if (response.success) {
                if (response.data) {
                  var content = response.data;
                  // if(!this.isStatic) {
                    this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                  // } else {
                  //   this.router.navigate(['department']);
                  // }
                }
              // this.router.navigate(['department']);
              this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }

}
