import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-wellness-information-list',
  templateUrl: './wellness-information-list.component.html',
  styleUrls: ['./wellness-information-list.component.scss']
})
export class WellnessInformationListComponent implements OnInit {
  content:any;
  page = 1;
  search = "";
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  apiLink:any;
  popoverTitle: string = 'Deleting this page may cause data lose';
  popoverMessage: string = 'Are you sure?';
  @ViewChild('deleteModal', { static: false }) accoViewModal: TemplateRef<any>;
  accoModalRef: any;
  id : any;
  limitReached : boolean = false;
  wellness : any;
  mindful : any;
  mdfl_page : 1;
  wlns_page : 1;
  mdfl_collectionSize : any;
  wlns_collectionSize : any;
  mdfl_pageSize: any;
  wlns_pageSize: any;
  mdfl_lastPage: any;
  wlns_lastPage: any;
  type: any;
  userId:any;
  
  constructor(private authService: AuthService,public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    let user = this.authService.getCurrentUser();
    this.userId = user.id;
    this.activatedRoute.params.subscribe((params) => {
      if(params['type']){
        console.log("mindful")
        document.getElementById("nav-profile-tab").click()
      }
      if(params['page']){
        this.page = params['page'];
      }
      if(params['type']){
        this.type = params['type'];
      }
      this.getWellnessPages(this.page,this.search);
      this.getMindfulPages(this.page,this.search);
    });
  }
  getWellnessPages(page:any,search:any) {
    this.search = search;
    this.apiLink = 'admin/wellness/pages?page=' + page + "&search=" + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          this.content = response.data;
          this.wellness = this.content.data;
          this.wlns_page = this.content.current_page;
          this.wlns_collectionSize = this.content.total;
          this.wlns_pageSize = this.content.per_page;
          this.wlns_lastPage = this.content.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }
  getMindfulPages(page:any,search:any) {
    this.search = search;
    this.apiLink = 'admin/wellness/mindful?page=' + page + "&search=" + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          this.content = response.data;
          this.mindful = this.content.data;
          this.mdfl_page = this.content.current_page;
          this.mdfl_collectionSize = this.content.total;
          this.mdfl_pageSize = this.content.per_page;
          this.mdfl_lastPage = this.content.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }
  
  removePage(id: any) {
    this.accoModalRef = this.modal.open(this.accoViewModal);
    this.id = id
  }
  deletePage() {
    this.apiService.deleteRequest('admin/delete-wellness-page/' + this.id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show(response.message, { cssClass: 'alert-success' });
          this.id = null;
          this.closeModal();
          this.getWellnessPages(this.page,this.search);
          this.getMindfulPages(this.page,this.search);
          // this.limitReached = false;
        } else {
          this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  closeModal(result?: any) {
    this.id = null;
    this.accoModalRef.close();
  }
  getManagePageListPage(pageNumber: any,type : any) {
    console.log(pageNumber,type)
    if(type == 1) {
      this.getWellnessPages(pageNumber,this.search);
    } else {
      this.getMindfulPages(pageNumber,this.search);
    }
  }

  clearSearchMindful(){
    this.search = "";
    this.page = 1;
    this.getMindfulPages(this.page,this.search);
  }
 
  clearSearchWellness(){
    this.search = "";
    this.page = 1;
    this.getWellnessPages(this.page,this.search);
  }
}
