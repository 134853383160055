import { Component, OnInit,Input } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.scss']
})
export class DepartmentListComponent implements OnInit {
  departments: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  departmentId: any ;
    // pagination
    page = 1;
    collectionSize: any;
    pageSize: any;
    lastPage: any;  
    apiLink:any;
    search = "";

    @Input() pageTitle: string ;
    @Input() dataUrl: string ;
    @Input() createUrl: string ;
    @Input() editUrl: string ;
    @Input() paginateUrl: string ;
  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if(!this.dataUrl) {
      this.router.navigate(['content-pages']);
    }
    // this.getdepartments();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
      }
      this.getdepartments(this.page, this.search);
    });
    // if(!this.pageTitle) {
    //   this.pageTitle = "Department";
    // }
    // if(!this.editUrl) {
    //   this.editUrl = '/department/edit';
    // }
    // if(!this.createUrl) {
    //   this.createUrl = 'create';
    // }
    // if(!this.paginateUrl) {
    //   this.paginateUrl = 'department';
    // }
  }

  getdepartments(page:any, search:any) {
    if (search == "") {
      this.activatedRoute.params.subscribe((params) => {
        page = params['page'];
      });
    }
    this.search = search;
    // if(this.dataUrl) {
      this.apiLink = this.dataUrl+ '?page=' + page + "&search=" + search;
    // } else{
    //   this.apiLink = 'admin/department?page=' + page;
    // } 
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.departments = response.data;
          this.departments = response.data.data;
          if(this.departments.length == 0 && this.page > 1) { // to handle deletion of single record page
            this.page -= 1;
            this.getDeptListPage(this.page)
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteDepartment(id: any) {
    this.apiService.deleteRequest('admin/app-page/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getdepartments(this.page, this.search);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  getDeptListPage(pageNumber: any) {
    this.router.navigate([this.paginateUrl, {page:pageNumber}]);
  }
  onBackClick() {
    this.router.navigate(['content-pages']);
  }
}
