import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../upload-adapter";
import { Location } from '@angular/common';

@Component({
  selector: 'inmate-info',
  templateUrl: './inmate-info.component.html',
  styleUrls: ['./inmate-info.component.scss']
})
export class InmateInfoComponent implements OnInit {
  public Editor = ClassicEditor;
  createPageForm: FormGroup;
  errorMessage = null;
  images = [];
  // url: string  = "";
  link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic', 'underline', 'link', '|', 'imageUpload']
  };
  @Input() pageTitle: string;
  @Input() dataUrl: string;
  @Input() postUrl: string;
  apiLink: any;
  @Input() backUrl: string ;

  constructor(private location: Location, private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    if(!this.dataUrl) {
      this.router.navigate(['content-pages']);
    }
    this.getData();
    this.createPageForm = this.formBuilder.group({
      // 'name': [],
      'desc': ['', Validators.required],
      // 'image': [''],
      // 'file': [''],
      'link': ['', Validators.required],
      // 'link_name': ['']
    });
    // if (!this.pageTitle) {
    //   this.pageTitle = "Inmate Info";
    // }
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader, service);
    };
  }
  // updateKey (eventData) {
  //   let key = eventData.toLowerCase( ).replace(' ', '_');
  //   this.createPageForm.controls['page_key'].setValue(key);
  // }
  changeType(eventData) {
    console.log(eventData);
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image', event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
          if (response.success) {
            // this.url = response.path;
          }
        },
        err => {
          console.log(err);
        },
      );
    }
  }
  getData() {
    // if (this.dataUrl) {
      this.apiLink = this.dataUrl;
    // } else {
    //   this.apiLink = 'admin/standard-page?key=inmate_info'
    // }
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if (response.success) {
          // this.name = response.data.name;
          this.desc = response.data.desc;
          this.link = response.data.link;
        }
      },
      (err: any) => console.log(err),
    );

  }
  onSubmit() {
    if (this.createPageForm.valid) {
      let postData = {
        'name': this.pageTitle,
        'desc': this.createPageForm.value.desc,
        // 'image': this.url,
        'link': this.createPageForm.value.link,
        // 'link_name': this.createPageForm.value.link_name,
      }
      // if (!this.postUrl) {
      //   this.postUrl = 'admin/generic-page?key=inmate_info'
      // }
      this.apiService.postRequest(this.postUrl, postData).subscribe(
        (response: any) => {
          if (response.success) {
            this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
          } else {
            this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
            this.errorMessage = response.message;
          }
        },
        err => {
          console.log(err);
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }

  }
  onBackClick() {
    // if (!this.link) {
      this.router.navigate(['content-pages']);
    // } else {
    //   this.location.back();
    // }
  }
}
