import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'special-crime-alerts-list',
  templateUrl: './special-crime-alerts-list.component.html',
  styleUrls: ['./special-crime-alerts-list.component.scss']
})
export class SpecialCrimeAlertsListComponent implements OnInit {
  crimeAlerts: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  crimeAlertId: any ;
    // pagination
    page = 1;
    collectionSize: any;
    pageSize: any;
    lastPage: any;  
    apiLink:any;  

  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // this.getAlerts();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getAlerts(this.page);
      }else {
        this.router.navigate(['crime-alert', {page:1}]);
      }
    });
  }

  getAlerts(page:any) {
    this.apiLink = 'admin/crime-alert?page=' + page;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.crimeAlerts = response.data;
          this.crimeAlerts = response.data.data;
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteCrimeAlert(id: any) {
    this.apiService.deleteRequest('admin/app-page/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getAlerts(this.page);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  getCrimeAlerttListPage(pageNumber: any) {
    this.router.navigate(['crime-alert', {page:pageNumber}]);
  }

}
