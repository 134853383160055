import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'external-links-create',
  templateUrl: './external-links-create.component.html',
  styleUrls: ['./external-links-create.component.scss']
})
export class ExternalLinkCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createExternalLinkFom: FormGroup;
  errorMessage = null;
  name: string;
  link: string;   
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.createExternalLinkFom = this.formBuilder.group({
      'name': ['',Validators.required],
      'link': ['',Validators.required],
    });
  }
  onSubmit() {
    if (this.createExternalLinkFom.valid) {
      let postData = {
        'name': this.createExternalLinkFom.value.name,
        'link': this.createExternalLinkFom.value.link,
      }
      this.apiService.postRequest('admin/external-link', postData).subscribe(
        (response: any) => {
            if (response.success) {
              this.router.navigate(['external-links']);
                this._flashMessagesService.show('Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Creation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

}
