import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { UploadAdaptor } from "../../upload-adapter";
import { ValidationService } from '../../shared/services/validation.service';
import { MustMatch } from '../../validator';
import { Location } from '@angular/common';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-wellness-information-create',
  templateUrl: './wellness-information-create.component.html',
  styleUrls: ['./wellness-information-create.component.scss']
})
export class WellnessInformationCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  dropdownList = [];
  dropdownSettings = {};
  pageCreateForm: FormGroup;
  url = '';
  errorMessage = null;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link']
  }; 
  showTemplate : boolean = true;
  templateId : any;
  wellnessCount : any;
  capReached : boolean = false;

  constructor(private location: Location,private apiService: ApiService, private router: Router, private _flashMessagesService: FlashMessagesService, 
  private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.apiService.getRequest('admin/wellness/pages').subscribe(
      (response: any) => {
        if(response.success) {
            let pages = response.data;
            this.wellnessCount = pages.length;
            document.getElementById('wellness').click();
        }
      },
      (err: any) => console.log(err),
    );
    this.dropdownList = [];
    this.dropdownSettings = { 
      singleSelection: true, 
      text:"Select Template",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.pageCreateForm = this.formBuilder.group({
      title: ['', Validators.required],
      template: [''],
      category: [''],
      icon: [''],
      description: [''],
      restricted : ['']
    },
    {});
    // this.getTemplates('');
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  changeType(eventData) {
    console.log(eventData);
  }

  getTemplates(category: any ) {
    this.dropdownList = [];
    this.showTemplate = true;
    if(category == 2) {
      this.templateId = 6;
      this.apiService.getRequest('admin/get-wellness-template/'+ this.templateId).subscribe(
        (response: any) => {
            if (response.success) {
              var template = response.data;
              this.url = template.icon;
              document.getElementById('icon-container').style.display = 'block';
              if(template.id == 6) {
                document.getElementById('description-container').style.display = 'block';
                document.getElementById('restricted-container').style.display = 'block';
              }
            }
        },
        err => {
            console.log(err);
        },
      );
      this.showTemplate = false;
    } else {
      this.apiService.getRequest('admin/wellness-templates?category='+ category).subscribe(
        (response: any) => {
          if(response.success) {
            var templateList = response.data;
            templateList.forEach((template: any) => {
              this.dropdownList.push({"id":template.id,"itemName":template.title});
            });
            this.pageCreateForm.controls['template'].setValue("")
          }
        },
        (err: any) => console.log(err),
      );
    }
  }

  loadTemplates() {
    document.getElementById('icon-container').style.display = 'none';
    document.getElementById('description-container').style.display = 'none';
    document.getElementById('restricted-container').style.display = 'none';
    let category = this.pageCreateForm.value.category;
    // console.log(category)
    if(category != null) {
      this.getTemplates(category)
    }
  }
  chooseTemplate(event) {
    document.getElementById('icon-container').style.display = 'none';
    document.getElementById('description-container').style.display = 'none';
    this.templateId = event.target.value;
    if(!this.templateId) {
      return false
    }
    this.apiService.getRequest('admin/get-wellness-template/'+ event.target.value).subscribe(
      (response: any) => {
          if (response.success) {
            var template = response.data;
            this.url = template.icon;
            document.getElementById('icon-container').style.display = 'block';
            if(template.id == 6) {
              document.getElementById('description-container').style.display = 'block';
              document.getElementById('restricted-container').style.display = 'block';
            }
          }
      },
      err => {
          console.log(err);
      },
    );
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('icon',event.target.files[0]);
      this.apiService.postRequest('admin/upload-wellness-icon', formData).subscribe(
        (response: any) => {
            if (response.success) {
              this.url = response.path;
            }
        },
        err => { 
            console.log(err);
        },
      );
    }
  }
  onSubmit() {
    if (this.pageCreateForm.valid) {
      let postData = {
        'title': this.pageCreateForm.value.title,
        'template': this.templateId,
        'description': this.pageCreateForm.value.description,
        'icon': this.url,
        'restricted': this.pageCreateForm.value.restricted,
      }
      this.apiService.postRequest('admin/save-wellness-page', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show("Created Successfully!", { cssClass: 'alert-success' });
                this.errorMessage = null;
                var content = response.data;
                var singlePages = ["EmployeeAssistanceProgramComponent"];
                if(singlePages.includes(content.template_name)) {
                    this.router.navigate(['wellness-pages/content/',content.id + "-" + content.slug]);
                } else {
                    this.router.navigate(['wellness-pages/content-create/',content.id + "-" + content.slug]);
                }
                // location.reload();
              } else {
                this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }
}
