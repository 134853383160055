import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'recruitment-edit',
  templateUrl: './recruitment-edit.component.html',
  styleUrls: ['./recruitment-edit.component.scss']
})
export class RecruitmentEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editPageForm: FormGroup;
  errorMessage = null;
  recruitmentId: string;
  images = [];
  key:string = "";
  name: string;
  link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.recruitmentId = params['id'];
    });
    this.getRecruitment();
    this.editPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'desc': ['',Validators.required],
      // 'image': [''],
      // 'file': [''],
      'link': ['',Validators.required],
      // 'link_name': ['']
    });
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  getRecruitment () {
    this.apiService.getRequest('admin/app-page/' + this.recruitmentId).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          this.desc = response.data.desc;
          this.link = response.data.link;
          this.key = response.data.page_key;
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editPageForm.valid) {
      let postData = {
        'name': this.editPageForm.value.name,
        'desc': this.editPageForm.value.desc,
        // 'image': this.url,
        'link': this.editPageForm.value.link,
        // 'link_name': this.editPageForm.value.link_name,
        // 'link_name': this.editPageForm.value.link_name,
        'type': "recruitment"
      }
      this.apiService.postRequest('admin/standard-page/' + this.recruitmentId, postData).subscribe(
        (response: any) => {
            if (response.success) {
              this.router.navigate(['recruitment']);
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

}
