import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss'],
  providers: [DatePipe]
})
export class EventEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editPageForm: FormGroup;
  errorMessage = null;
  eventId: string;
  images = [];
  image :string;
  key:string = "";
  name: string;
  url:string = "";
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    

  allFilesUploaded=[];
  stringfiedAttachments = '';
  fileToUpload: File = null;
  uploadedDoc = false;
  imagestobeDeleted=[];
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;
  desc :string;

  imageUrl: any;
  start_date;
  end_date;
  startTime;
  endTime;
  loc: string;
  organizer: string;
  organizer_email: string;
  organizer_designation: string;
  created_by: any;
  imageUploaded = {
    'image': null,
  };
  minEndDate = new Date();
  todayDate:Date = new Date();
  isCalendarEvent: boolean = false;
  showCalenderItems: boolean = false;
  timeZone: string;
  selectedDate: string;

  userId: any;
  @Input() pageTitle: string ;
  @Input() updateUrl: string ;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, 
    private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder, private dialog: MatDialog,
    private authService: AuthService, private domSanitizer: DomSanitizer, private datePipe: DatePipe) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.eventId = params['id'];
    });
    this.timeZone = this.getLocalTimezone();
    this.getEvent();
    this.editPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      // 'image': [''],
      'desc': ['', Validators.required],
      // 'phone': ['',Validators.pattern("[- +()0-9]+")],
      // 'email': ['',Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
      // 'link': ['',Validators.required],
      // 'link_name': ['']
      'images': [''],
      'document': [''],
      'loc': [''],
      'start_date': [''],
      // 'end_date': [''],
      'start_time': [''],
      'end_time': [''],
      'organizer': [''],
      'organizer_email': [''],
      'organizer_designation': [''],
      'organizer_image': [''],
      'is_calendar_event': [''],
      'created_by': ['']
    });
    let userdata = this.authService.getCurrentUser();
    this.userId = userdata.id;
    // if(!this.pageTitle) {
    //   this.pageTitle = "Event";
    // }
    // if(!this.updateUrl) {
    //   this.updateUrl = 'admin/standard-page/' + this.eventId;
    // }
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  getEvent () {
    this.apiService.getRequest('admin/app-page/' + this.eventId + '/?timezone='+this.timeZone).subscribe(
      (response: any) => {
        if(response.success) {
          console.log(response.data);
          this.name = response.data.name;

          // const d = new Date(response.data.start_date);
          // // const d1 = new Date(response.data.end_date);
          // let date = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes() - d.getTimezoneOffset()).toISOString();          
          // let date1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), d1.getHours(), d1.getMinutes() - d1.getTimezoneOffset()).toISOString();          
          // this.start_date = date; // datepicker converting 1 day lesser issue
          // this.minEndDate = d;

          if(response.data.start_time) {
            let dateTimeStr = `${response.data.start_date}T${response.data.start_time}`;
            const d = new Date(dateTimeStr);
            let date = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()).toISOString();          
            this.start_date = date;
          }
         

          // this.start_date = new Date(response.data.start_date).toISOString();
          // console.log('start date', this.start_date);
          // this.minEndDate = this.start_date;
          // this.end_date = response.data.end_date ? date1 : null; // datepicker converting 1 day lesser issue

          this.key = response.data.page_key;
          this.desc = response.data.desc;
         
          this.startTime = response.data.start_time;
          this.endTime = response.data.end_time;
          this.loc = response.data.location;
          this.organizer = response.data.organizer;
          this.organizer_email = response.data.organizer_email;
          this.organizer_designation = response.data.organizer_designation;
          this.selectedDate = response.data.start_date;
          if(response.data.organizer_image) {
            this.imageUrl = environment.apiUrl +'images/organizer-images/'+ response.data.organizer_image;
          }
          this.created_by = response.data.created_by;
          this.isCalendarEvent = this.showCalenderItems  = response.data.is_calendar_event;
          
          for (var i = 0; i < response.data.images.length; i += 1) {
            let resposeData = {
              'name': response.data.images[i]['name'],
              'image_link': response.data.images[i]['image_url'],
              'image': response.data.images[i]['image'],
              'id': response.data.images[i]['id'],
            }
            this.allFilesUploaded.push(resposeData);
          }
          if(response.data.document_name && response.data.document_url && response.data.document){
            this.documentUploaded = {
              'doc_name': response.data.document_name,
              'doc_link': response.data.document_url,
              'document': response.data.document,
            }
            this.isDocUploaded = true;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editPageForm.valid) {
      // console.log(this.editPageForm.value);
      let postData: any = {
        'id' : this.eventId,
        'name': this.editPageForm.value.name,
        // 'image' : this.url,
        'type': this.key,
        'images': this.allFilesUploaded,
        'images_to_be_deleted': this.imagestobeDeleted,
        'desc': this.editPageForm.value.desc,
      }
      if(this.isCalendarEvent) {
        postData['location'] = this.editPageForm.value.loc;
        // postData['start_date'] = this.editPageForm.value.start_date;
        postData['start_date'] = this.selectedDate;

        postData['start_time'] = this.editPageForm.value.start_time;
        // postData['end_date'] = this.editPageForm.value.end_date;
        postData['end_time'] = this.editPageForm.value.end_time;
        postData['organizer'] = this.editPageForm.value.organizer;
        postData['organizer_email'] = this.editPageForm.value.organizer_email;
        postData['organizer_designation'] = this.editPageForm.value.organizer_designation;
        postData['organizer_image'] = this.imageUploaded['image'];
      }
      postData['is_calendar_event'] = this.isCalendarEvent;
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      postData['time_zone'] = this.timeZone;
      this.apiService.postRequest(this.updateUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                // if(!this.isStatic) {
                  this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                // } else {
                //   this.router.navigate(['event']);
                // }
              }
              // this.router.navigate(['event']);
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.editPageForm.markAsPristine();
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  onImageAttachments(files: FileList){
    for (var i = 0; i < files.length; i += 1) {
      this.fileToUpload = files.item(i);
      let postD = new FormData();
      postD.append('image', this.fileToUpload);
      this.apiService.postRequest('admin/app-image',  postD).subscribe(
        (response: any) => {
        if(response.success){
          let resposeData = {
            'name': response.file_name,
            'image_link': response.path,
            'image': response.image,
            'id': null,
          }
          this.allFilesUploaded.push(resposeData);
        } else {
        }
        },
        (error: any) => {
        }
      );
    }
  }
  removeIndex(index) {
    if(this.allFilesUploaded[index]['id']){
      // console.log(this.allFilesUploaded[index]);
      this.imagestobeDeleted.push(this.allFilesUploaded[index]['id'])
    }
    this.allFilesUploaded.splice(index, 1);
  }

  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/app-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }
  removeImage() {
    this.imageUrl = '';
    this.imageUploaded = {
      'image': null,
    };
  }

  modifyEndDate(event) {
    this.minEndDate = event.target.value;
    const date = new Date(event.value);
    if(date) {
      this.selectedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    }
  }

  setOrganizerValue(event) {
    let user = this.authService.getCurrentUser();
    this.userId = user.id;
    if(event.target.checked) {
      this.editPageForm.controls['organizer'].setValue(user.first_name);
      this.editPageForm.controls['organizer_email'].setValue(user.email);
      this.imageUrl =  user.profile_pic_url;
      // const src = document.getElementsByClassName("multi-files").nativeElement.src;
      // const src = this.el.nativeElement.src;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', this.imageUrl);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;
        const fileName = 'image.png'; // You can change the file name
        const file = new File([blob], fileName, { type: 'image/png' });
        this.uploadImageCall(file);
        // Now you have the file object, you can send it to the server or store it locally
      };
      xhr.send();
      this.imageUploaded = {
        'image': user.profile_pic,
      }
      // const reader = new FileReader();
      //   reader.onload = (e: any) => {
      //     var URL = window.URL;
      //     this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(user.profile_pic));
      //   }
      //   reader.readAsDataURL(user.profile_pic);
    }
    else {
      this.editPageForm.controls['organizer'].setValue('');
      this.editPageForm.controls['organizer_email'].setValue('');
      this.editPageForm.controls['organizer_image'].setValue('');
      this.imageUrl = '';
    }
    
    return true;
  }
  organizerImageUpload(event) {
    if (event.target) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        var URL = window.URL;
        this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
      }
      reader.readAsDataURL(file);
    
      reader.onerror = (error) => {
        console.error('Error reading the file:', error);
      };
      this.uploadImageCall(file);
    }
  }
  uploadImageCall(fileData) {
    var formData = new FormData;
    formData.append('image',fileData);
    this.apiService.postRequest('admin/organizer-image', formData).subscribe(
      (response: any) => {
          if (response.success) {
            this.imageUploaded = {
              'image': response.image,
            }
          }
      },
      err => {
          console.log(err);
      },
    );
  }

  showCalendarItems(event) {
    if(event.target.checked) {
      this.editPageForm.controls['is_calendar_event'].setValue(1);
      this.isCalendarEvent = true;

      this.editPageForm.controls['loc'].setValidators([Validators.required]);
      this.editPageForm.controls['start_date'].setValidators([Validators.required]);
      this.editPageForm.controls['start_time'].setValidators([Validators.required]);
      // this.editPageForm.controls['end_date'].setValidators([Validators.required]);
      this.editPageForm.controls['end_time'].setValidators([Validators.required]);
      this.editPageForm.controls['organizer_email'].setValidators([Validators.required]);
      this.editPageForm.controls['organizer'].setValidators([Validators.required]);
      // this.editPageForm.controls['organizer_designation'].setValidators([Validators.required]);
    }
    else {
      this.editPageForm.controls['is_calendar_event'].setValue(0);
      this.isCalendarEvent = false;
       
      this.editPageForm.controls['loc'].clearValidators();
      this.editPageForm.controls['loc'].updateValueAndValidity();
      this.editPageForm.controls['start_date'].clearValidators();
      this.editPageForm.controls['start_date'].updateValueAndValidity();
      this.editPageForm.controls['start_time'].clearValidators();
      this.editPageForm.controls['start_time'].updateValueAndValidity();
      // this.editPageForm.controls['end_date'].clearValidators();
      // this.editPageForm.controls['end_date'].updateValueAndValidity();
      this.editPageForm.controls['end_time'].clearValidators();
      this.editPageForm.controls['end_time'].updateValueAndValidity();
      this.editPageForm.controls['organizer_email'].clearValidators();
      this.editPageForm.controls['organizer_email'].updateValueAndValidity();
      this.editPageForm.controls['organizer'].clearValidators();
      this.editPageForm.controls['organizer'].updateValueAndValidity();
      // this.editPageForm.controls['organizer_designation'].clearValidators();
      // this.editPageForm.controls['organizer_designation'].updateValueAndValidity();
    }
  }
  getLocalTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
