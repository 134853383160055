import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../shared/services/api.service';

@Component({
  selector: 'app-staff-alert-notification',
  templateUrl: './staff-alert-notification.component.html',
  styleUrls: ['./staff-alert-notification.component.scss']
})
export class StaffAlertNotificationComponent implements OnInit {

  @Input() id: number;
  staffs: string;

  constructor(private apiService: ApiService, private modal: NgbActiveModal) { }

  ngOnInit() {
        this.apiService.getRequest('admin/alert/staff-list/'+ this.id).subscribe(
        (response: any) => {
            if(response.success) {
                this.staffs = response.data;
            }
        });
  }
  closeModal(result?: any) {
      this.modal.close();
  }
}
