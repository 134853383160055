
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from './shared/services/api.service';


export class UploadAdaptor {


    loader;  // your adapter communicates to CKEditor through this
    constructor(loader, private apiService: ApiService) {
      this.loader = loader;
    }
  
    upload() {
      return new Promise((resolve, reject) => {
        this.loader.file.then( uploadfile => {
            var formData = new FormData;
            formData.append('image',uploadfile);
            this.apiService.postRequest('admin/upload-image', formData).subscribe(
                (response: any) => {
                    if (response.success) {
                      var returnUrl = response.path;
                      resolve({ default: returnUrl });
                    }
                },
                err => {
                    console.log(err);
                },
              );
        });

      });
    }
    abort() {
      console.log('UploadAdapter abort');
    }
}
