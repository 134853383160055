import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TemplateListComponent} from "../../template-list/template-list.component"
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-staff-alert-create',
  templateUrl: './staff-alert-create.component.html',
  styleUrls: ['./staff-alert-create.component.scss']
})
export class StaffAlertCreateComponent implements OnInit {
  public Editor = ClassicEditor;
  createStaffAlertForm: FormGroup;
  errorMessage = null;
  selectedUsersOnly = false;
  hasStaff = true;
  hasTeam = true;
  dropdownList = [];
  dropdownList2 = [];
  selectedItems = [];
  selectedItems2 = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  staffList;
  teamList;
  clicked : boolean = false;
  showError = false;
  selectedTemp = "";
  templates:any;
  ckConfig = { 
        toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
      };  
  selectedTeamOnly: boolean = false;
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  imageUploaded = {
    'image': null,
    'file_type': null
  };
  isDocUploaded = false;
  fileToUpload: File = null;
  todayDate:Date = new Date();
  imageUrl: any;
  teamIds = [];
  staffIds = [];
  fileType: string = 'image';
  imageName: string;

  constructor(private dialog: MatDialog, private modal: NgbModal,private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.dropdownList = [];
    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select Staff",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.dropdownSettings2 = { 
      singleSelection: false, 
      text:"Select Team",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };
    this.apiService.getRequest('admin/staff').subscribe(
      (response: any) => {
        if(response.success) {
          var staffList = response.data;
          staffList.forEach((staff: any) => {
            this.dropdownList.push({"id":staff.id,"itemName":staff.first_name + ' ' + staff.last_name});
          });
        }
      },
      (err: any) => console.log(err),
    );
    this.apiService.getRequest('admin/team').subscribe(
      (response: any) => {
        if(response.success) {
          var teams = response.data;
          teams.forEach((team: any) => {
            this.dropdownList2.push({"id":team.id,"itemName":team.name});
          });
        }
      },
      (err: any) => console.log(err),
    );
    this.createStaffAlertForm = this.formBuilder.group({
      'title': ['', Validators.required],
      'description': ['', Validators.required],
      'expiryDate': [''],
      'alertType': ['1', Validators.required],
      'staffs': [''],
      'teams': [''],
      'name': [''],
      'template' : [''],
      'document' : [''],
      'image' : ['']
    });
    this.getTemplates();
  }

  viewTemplates(id: any) {
    const modalRef = this.modal.open(TemplateListComponent);
  }
  getTemplates () {
    this.apiService.getRequest('admin/template').subscribe(
      (response: any) => {
        if(response.success) {
          this.templates = response.data;
        }
      },
      (err: any) => console.log(err),
    );
  }
  copyTemplate () {
    for (let obj of this.templates) {
      if (obj.id == this.selectedTemp) {
        this.createStaffAlertForm.controls['description'].setValue(obj.description);
        this.createStaffAlertForm.controls['title'].setValue(obj.name);
      }
    }
    this.closeDialog();
  }
  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  saveTemplate() {
    this.clicked = true;
    if (this.createStaffAlertForm.value.name) {
      this.showError = false;
      let postdata = {
        "name" : this.createStaffAlertForm.value.name,
        "description" : this.createStaffAlertForm.value.description
      }
      this.apiService.postRequest('admin/template', postdata).subscribe(
        (response: any) => {
            if (response.success) {
              this.closeDialog();
              this._flashMessagesService.show('Template Saved!', { cssClass: 'alert-success' });
            } else {
              this.closeDialog();
              this._flashMessagesService.show('Template Save Failed!!', { cssClass: 'alert-danger' });
            }
            this.clicked = false;
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    console.log(postdata);
    } else {
      this.showError = true;
    }
  }
  onItemSelect(item:any){
    console.log(item);
    console.log(this.selectedItems);
    this.hasStaff = true;
    this.hasTeam = true;
  }
  OnItemDeSelect(item:any){
      console.log(item);
      console.log(this.selectedItems);
  }
  onSelectAll(items: any){
      console.log(items);
  }
  onDeSelectAll(items: any){
      console.log(items);
  }

  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  handleTypeChange(event) {
    if (event == 1) {
      this.selectedUsersOnly = false;
      this.selectedTeamOnly = false;
      this.hasStaff = true;
      this.hasTeam = true;
    } else if (event == 2) {
      this.selectedUsersOnly = true;
      this.selectedTeamOnly = false;
    } else if(event == 3) {
      this.selectedUsersOnly = false;
      this.selectedTeamOnly = true;
    }
  }
  onSubmit() {
    if (this.createStaffAlertForm.value.alertType == 2 && this.createStaffAlertForm.value.staffs.length == 0) {
      this.hasStaff = false;
    } else {
      this.hasStaff = true;
    }
    if (this.createStaffAlertForm.value.alertType == 3 && this.createStaffAlertForm.value.teams.length == 0) {
      this.hasTeam = false;
    } else {
      this.hasTeam = true;
    }
    if ((this.hasStaff && this.hasTeam) && this.createStaffAlertForm.valid) {
      const staffsControl = this.createStaffAlertForm.get('staffs');
      const teamsControl = this.createStaffAlertForm.get('teams');

      if(staffsControl.value) {
        this.staffIds = staffsControl.value.map(staff => staff.id);
      }
      if(teamsControl.value) {
        this.teamIds = teamsControl.value.map(team => team.id);
      }

      let postData = {
        'title': this.createStaffAlertForm.value.title,
        'description': this.createStaffAlertForm.value.description,
        'expiry_date': this.createStaffAlertForm.value.expiryDate,
        'notification_type': this.createStaffAlertForm.value.alertType,
        'staffs' : this.staffIds,
        'teams' : this.teamIds,
        'image' : this.imageUploaded['image'],
        'file_type': this.imageUploaded['file_type']
      }
      if (!this.selectedUsersOnly) {
        postData['staffs'] = [];
      }
      if (!this.selectedTeamOnly) {
        postData['teams'] = [];
      }
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      console.log(postData)
      this.apiService.postRequest('admin/staff-alert', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this.router.navigate(['staff-alert']);
                this._flashMessagesService.show('Alert Created Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Alert Creation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/upload-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  removeImage() {
    this.imageUrl = '';
    this.imageUploaded = {
      'image': null,
      'file_type': null,
    };
  }
  onBackClick() {
    this.location.back();
  }
  uploadImage(event): void {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          var URL = window.URL;
          this.imageUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
        }
        reader.readAsDataURL(file);
      
        reader.onerror = (error) => {
          console.error('Error reading the file:', error);
        };
        this.imageName = event.target.files[0].name;
        let postD = new FormData();
        postD.append('image', event.target.files[0]);
        this.apiService.postRequest('admin/alert-image',  postD).subscribe(
          (response: any) => {
          if(response.success){
            this.fileType = response.file_type;
            this.imageUploaded = {
              'image': response.image,
              'file_type': response.file_type
            }
          } else {
            this._flashMessagesService.show('Image/Video upload Failed!!', { cssClass: 'alert-danger' });
            this.errorMessage = response.message;
          }
          },
          (error: any) => {
          }
        );
    }
  }

  toggleVideo(event: any) {
    let audioPlayer = <HTMLVideoElement>document.getElementById('tipVideo');
    audioPlayer.play();
  }
}
