import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, ResponseContentType, Response } from '@angular/http';
// import { AuthHttp } from 'angular2-jwt';
import { JwtHttp } from 'angular2-jwt-refresh';
// import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import 'rxjs/Rx';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthStorageService } from './auth.storage.service';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class ApiService {
    apiBaseUrl = environment.apiUrl;
    pendingRequests = 0;
    public tipCount = new BehaviorSubject(0);
    public praiseCompCount = new BehaviorSubject(0);
    public zoneCheckCount = new BehaviorSubject(0);

    constructor(private http: Http, private jwtHttp: JwtHttp, private router: Router, private httpClient: HttpClient, private authStorageService: AuthStorageService) {
    }

    login(email: string, password: string, otp: string) {
        const path = this.apiBaseUrl + 'login-with-otp';
        const headers = { headers: new HttpHeaders({ 'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': '*', 'Content-Type': 'application/json'})};
        return this.httpClient
            .post(path, JSON.stringify({
                email: email,
                password: password,
                otp: otp,
                user_type:1
            }), headers);
    }

    logout(){
        const path = this.apiBaseUrl + 'logout';
        return this.httpClient.get(path);
    }
    
    getRequest(path: string, options?: object) {
        path = this.apiBaseUrl + path;
        return this.httpClient.get(path);
    }

    deleteRequest(path: string, options?: object) {
        path = this.apiBaseUrl + path;
        return this.httpClient.delete(path);
    }

    postRequest( path: string, data: object) {
        path = this.apiBaseUrl + path;
        return this.httpClient.post(path, data);
    }

    putRequest( path: string, data: object) {
        path = this.apiBaseUrl + path;
        return this.httpClient.put(path, data);
    }


    httpGetRequest( path: string) {
        return this.httpClient.get(path);
    }

    getCurrentUser() {
        const path = this.apiBaseUrl + 'admin/me';
        return this.httpClient.get(path);
    }
}
