import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { AuthGuard } from '../shared/auth/auth.guard';

import { LoginComponent } from '../login/login.component';
import { ProfileComponent } from '../profile/profile-edit/profile.component';
import { ChangePasswordComponent } from '../profile/change-password/change-password.component';
import { AlertListComponent } from '../alert/alert-list/alert-list.component';
import { AlertCreateComponent } from '../alert/alert-create/alert-create.component';
import { AlertViewComponent } from '../alert/alert-view/alert-view.component';
import { ManageAppPagesListComponent } from '../manage-app-pages/manage-app-pages-list/manage-app-pages-list.component';
import { ManageAppPagesCreateComponent } from '../manage-app-pages/manage-app-pages-create/manage-app-pages-create.component';
import { ManageAppPagesViewComponent } from '../manage-app-pages/manage-app-pages-view/manage-app-pages-view.component';
import { TipListComponent } from '../tip/tip-list/tip-list.component';
import { TipViewComponent } from '../tip/tip-view/tip-view.component';
import { StaffAlertCreateComponent } from '../staff-alert/staff-alert-create/staff-alert-create.component';
import { StaffAlertListComponent } from '../staff-alert/staff-alert-list/staff-alert-list.component';
import { StaffAlertViewComponent } from '../staff-alert/staff-alert-view/staff-alert-view.component';
import { StaffAlertNotificationComponent } from '../staff-alert/staff-alert-notification/staff-alert-notification.component';
import { StaffCreateComponent } from '../staff/staff-create/staff-create.component';
import { StaffListComponent } from '../staff/staff-list/staff-list.component';
import { StaffEditComponent } from '../staff/staff-edit/staff-edit.component';
import { StaffImportComponent } from '../staff/staff-import/staff-import.component';
import { StaffChangePasswordComponent } from '../staff/staff-change-password/staff-change-password.component';
import { AdminUserCreateComponent } from '../admin-user/admin-user-create/admin-user-create.component';
import { AdminUserListComponent } from '../admin-user/admin-user-list/admin-user-list.component';
import { AdminUserEditComponent } from '../admin-user/admin-user-edit/admin-user-edit.component';
import { AdminUserChangePasswordComponent } from '../admin-user/admin-user-change-password/admin-user-change-password.component';
import { AdminUserChangePermissionComponent } from '../admin-user/admin-user-change-permission/admin-user-change-permission.component';
import { TeamListComponent } from '../team/team-list/team-list.component';
import { TeamCreateComponent } from '../team/team-create/team-create.component';
import { TeamEditComponent } from '../team/team-edit/team-edit.component';
import { TeamManagementComponent } from '../team/team-management/team-management.component';
import { PermissionGuard } from '../shared/auth/permission.guard';
import { KnowYourSheriffComponent } from '../know-your-sheriff/know-your-sheriff.component';
import { InmateInfoComponent } from '../inmate-info/inmate-info.component';
import { SexOffenderComponent } from '../sex-offender/sex-offender.component';
import { JobCreateComponent } from '../jobs/job-create/job-create.component';
import { JobEditComponent } from '../jobs/job-edit/job-edit.component';
import { JobListComponent } from '../jobs/job-list/job-list.component';
import { PermitCreateComponent } from '../permits/permits-create/permits-create.component';
import { PermitEditComponent } from '../permits/permits-edit/permits-edit.component';
import { PermitListComponent } from '../permits/permits-list/permits-list.component';
import { KnowYourDepartmentComponent } from '../know-your-department/know-your-department.component';
import { KnowYourPoliceComponent } from '../know-your-police-chief/know-your-police-chief.component';
import { CrimeTrendsComponent } from '../crime-trends/crime-trends.component';
import { PraiseOrComplaintsListComponent } from '../praise-or-complaints/praise-or-complaints-list/praise-or-complaints-list.component';
import { PraiseOrComplaintsViewComponent } from '../praise-or-complaints/praise-or-complaints-view/praise-or-complaints-view.component';
import { EmploymentListComponent } from '../employment/employment-list/employment-list.component';
import { EmploymentEditComponent} from '../employment/employment-edit/employment-edit.component';
import { EmploymentCreateComponent } from '../employment/employment-create/employment-create.component';
import { RecruitmentListComponent } from '../recruitment/recruitment-list/recruitment-list.component';
import { RecruitmentEditComponent} from '../recruitment/recruitment-edit/recruitment-edit.component';
import { RecruitmentCreateComponent } from '../recruitment/recruitment-create/recruitment-create.component';
import { DepartmentListComponent } from '../departments/department-list/department-list.component';
import { DepartmentCreateComponent } from '../departments/department-create/department-create.component';
import { DepartmentEditComponent } from '../departments/department-edit/department-edit.component';
import { MostWantedListComponent } from '../most-wanted/most-wanted-list/most-wanted-list.component';
import { MostWantedCreateComponent } from '../most-wanted/most-wanted-create/most-wanted-create.component';
import { MostWantedEditComponent } from '../most-wanted/most-wanted-edit/most-wanted-edit.component';
import { SpecialCrimeAlertsListComponent } from '../special-crime-alerts/special-crime-alerts-list/special-crime-alerts-list.component';
import { SpecialCrimeAlertsCreateComponent } from '../special-crime-alerts/special-crime-alerts-create/special-crime-alerts-create.component';
import { SpecialCrimeAlertsEditComponent } from '../special-crime-alerts/special-crime-alerts-edit/special-crime-alerts-edit.component';
import { CrimePreventionListComponent } from '../crime-prevention/crime-prevention-list/crime-prevention-list.component';
import { CrimePreventionCreateComponent } from '../crime-prevention/crime-prevention-create/crime-prevention-create.component';
import { CrimePreventionEditComponent } from '../crime-prevention/crime-prevention-edit/crime-prevention-edit.component';
import { CrimePreventionTipsListComponent } from '../crime-prevention-tips/crime-prevention-tips-list/crime-prevention-tips-list.component';
import { CrimePreventionTipsCreateComponent } from '../crime-prevention-tips/crime-prevention-tips-create/crime-prevention-tips-create.component';
import { CrimePreventionTipsEditComponent } from '../crime-prevention-tips/crime-prevention-tips-edit/crime-prevention-tips-edit.component';
import { ContactUsListComponent } from '../contact-us/contact-us-list/contact-us-list.component';
import { ContactUsCreateComponent } from '../contact-us/contact-us-create/contact-us-create.component';
import { ContactUsEditComponent } from '../contact-us/contact-us-edit/contact-us-edit.component';
import { TemplateListComponent } from '../template-list/template-list.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ExternalLinkListComponent } from '../external-links/external-links-list/external-links-list.component';
import { ExternalLinkCreateComponent } from '../external-links/external-links-create/external-links-create.component';
import { ExternalLinkEditComponent } from '../external-links/external-links-edit/external-links-edit.component';
import { OffDutyJobsListComponent } from '../offdutyjobs/offdutyjobs-list/offduty-list.component';
import { OffDutyJobsCreateComponent } from '../offdutyjobs/offdutyjobs-create/offduty-create.component';
import { OffDutyJobsEditComponent } from '../offdutyjobs/offdutyjobs-edit/offduty-edit.component';
import { ZoneCheckRequestListComponent } from '../zone-check-request/zone-check-request-list/zone-check-request-list.component';
import { ZoneCheckRequestViewComponent } from '../zone-check-request/zone-check-request-view/zone-check-request-view.component';
import { EventCreateComponent } from '../events/event-create/event-create.component';
import { EventEditComponent } from '../events/event-edit/event-edit.component';
import { EventListComponent } from '../events/event-list/event-list.component';
import { CodesAndOrdinanceComponent } from '../codes-and-ordinance/codes-and-ordinance.component';
import { BusinessCoalitionComponent } from '../business-coalition/business-coalition.component';
import { VictimsServicesComponent } from '../victims-services/victims-services.component';
import { MunicipalCourtComponent } from '../municipal-court/municipal-court.component';
import { ResourceListComponent } from '../resources/resource-list/resource-list.component';
import { ResourceCreateComponent } from '../resources/resource-create/resource-create.component';
import { ResourceEditComponent } from '../resources/resource-edit/resource-edit.component';
import { SettingsComponent } from '../settings/settings.component';
import { DomesticViolenceComponent } from '../domestic-violence/domestic-violence.component';
import { WarrentsListComponent } from '../warrents/warrents-list/warrents-list.component';
import { WarrentsCreateComponent } from '../warrents/warrents-create/warrents-create.component';
import { WarrentsEditComponent } from '../warrents/warrents-edit/warrents-edit.component';
import { PierSupportListComponent } from '../pier-support/pier-support-list/pier-support-list.component';
import { PierSupportCreateComponent } from '../pier-support/pier-support-create/pier-support-create.component';
import { PierSupportEditComponent } from '../pier-support/pier-support-edit/pier-support-edit.component';
import { ChaplainListComponent } from '../chaplains/chaplain-list/chaplain-list.component';
import { ChaplainCreateComponent } from '../chaplains/chaplain-create/chaplain-create.component';
import { ChaplainEditComponent } from '../chaplains/chaplain-edit/chaplain-edit.component';
import { CivilianCounselorsListComponent } from '../civilian-counselors/civilian-counselors-list/civilian-counselors-list.component';
import { CivilianCounselorCreateComponent } from '../civilian-counselors/civilian-counselors-create/civilian-counselor-create.component';
import { CivilianCounselorEditComponent } from '../civilian-counselors/civilian-counselors-edit/civilian-counselor-edit.component';
import { GeneralInsuranceCreateComponent } from '../general-insurance/general-insurance-create/general-insurance-create.component';
import { GeneralInsuranceEditComponent } from '../general-insurance/general-insurance-edit/general-insurance-edit.component';
import { GeneralInsuranceListComponent } from '../general-insurance/general-insurance-list/general-insurance-list.component';
import { EmployeeAssistanceProgramComponent } from '../employee-assistance-program/employee-assistance-program.component';
import { DynamicContentManagementListComponent } from '../dynamic-content-management/dynamic-content-management-list/dynamic-content-management-list.component';
import { DynamicContentManagementCreateComponent } from '../dynamic-content-management/dynamic-content-management-create/dynamic-content-management-create.component';
import { DynamicContentManagementPagesComponent } from '../dynamic-content-management/dynamic-content-management-pages/dynamic-content-management-pages.component';
import { DynamicContentManagementPagesDataCreateComponent } from '../dynamic-content-management/dynamic-content-management-pages-data-create/dynamic-content-management-pages-data-create.component';
import { DynamicContentManagementPagesDataEditComponent } from '../dynamic-content-management/dynamic-content-management-pages-data-edit/dynamic-content-management-pages-data-edit.component';
import { DynamicContentManagementEditComponent } from '../dynamic-content-management/dynamic-content-management-edit/dynamic-content-management-edit.component';
import { StaffFamilyListComponent } from '../staff-family/staff-family-list/staff-family-list.component';
import { StaffFamilyCreateComponent } from '../staff-family/staff-family-create/staff-family-create.component';
import { StaffFamilyEditComponent } from '../staff-family/staff-family-edit/staff-family-edit.component';
import { WellnessInformationListComponent } from '../wellness-information/wellness-information-list/wellness-information-list.component';
import { WellnessInformationCreateComponent } from '../wellness-information/wellness-information-create/wellness-information-create.component';
import { MindfulLearningCreateComponent } from '../mindful-learning/mindful-learning-create/mindful-learning-create.component';
import { MindfulLearningListComponent } from '../mindful-learning/mindful-learning-list/mindful-learning-list.component';
import { WellnessInformationPagesComponent } from '../wellness-information/wellness-information-pages/wellness-information-pages.component';
import { WellnessInformationEditComponent } from '../wellness-information/wellness-information-edit/wellness-information-edit.component';
import { WellnessInformationPagesDataCreateComponent } from '../wellness-information/wellness-information-pages-data-create/wellness-information-pages-data-create.component';
import { WellnessInformationPagesDataEditComponent } from '../wellness-information/wellness-information-pages-data-edit/wellness-information-pages-data-edit.component';
import { MindfulLearningReportComponent } from '../mindful-learning/mindful-learning-report/mindful-learning-report.component';
import { MindfulLearningEditComponent } from '../mindful-learning/mindful-learning-edit/mindful-learning-edit.component';
import { HopkinsvillePoliceComponent } from '../hopkinsville-police/hopkinsville-police.component';
import { WellnessDashboardComponent } from '../wellness-dashboard/wellness-dashboard.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '', component: HomeComponent, canActivate: [AuthGuard],canActivateChild:[AuthGuard], children: [
          { path: 'dashboard', component: DashboardComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'profile', component: ProfileComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'templates-list', component: TemplateListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'settings', component: SettingsComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'profile/change-password', component: ChangePasswordComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },

          { path: 'public-alert', component: AlertListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'PUBLIC_ALERT_ACCESS'} },
          { path: 'public-alert/create', component: AlertCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'PUBLIC_ALERT_ACCESS'} },
          { path: 'public-alert/view', component: AlertViewComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'PUBLIC_ALERT_ACCESS'} },

          { path: 'tip', component: TipListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'TIP_ACCESS'} },
          { path: 'tip/view', component: TipViewComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'TIP_ACCESS'} },
          
          { path: 'staff-alert', component: StaffAlertListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'STAFF_ALERT_ACCESS'} },
          { path: 'staff-alert/create', component: StaffAlertCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'STAFF_ALERT_ACCESS'} },
          { path: 'staff-alert/view', component: StaffAlertViewComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'STAFF_ALERT_ACCESS'} },
          { path: 'staff-alert/notification', component: StaffAlertNotificationComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'STAFF_ALERT_ACCESS'} },

          { path: 'app-page', component: ManageAppPagesListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'app-page/create', component: ManageAppPagesCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'app-page/:id', component: ManageAppPagesViewComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          
          // { path: 'job/create', component: JobCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
          // { path: 'job/edit/:id', component: JobEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
          // { path: 'job', component: JobListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
          
          { path: 'praise-or-complaint/view/:id', component: PraiseOrComplaintsViewComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
         
          { path: 'crime-alert', component: SpecialCrimeAlertsListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'crime-alert/create', component: SpecialCrimeAlertsCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'crime-alert/edit/:id', component: SpecialCrimeAlertsEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
         
          // { path: 'crime-prevention-tip', component: CrimePreventionTipsListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          // { path: 'crime-prevention-tip/create', component: CrimePreventionTipsCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          // { path: 'crime-prevention-tip/edit/:id', component: CrimePreventionTipsEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
         
          // { path: 'recruitment', component: RecruitmentListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          // { path: 'recruitment/create', component: RecruitmentCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          // { path: 'recruitment/edit/:id', component: RecruitmentEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          
          { path: 'external-links', component: ExternalLinkListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'external-links/create', component: ExternalLinkCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'external-links/edit/:id', component: ExternalLinkEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
         
          { path: 'contact-us', component: ContactUsListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'contact-us/create', component: ContactUsCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'contact-us/edit/:id', component: ContactUsEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          
          { path: 'staff/create', component: StaffCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'STAFF_ACCESS'} },
          { path: 'staff', component: StaffListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'staff/staff-import', component: StaffImportComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'STAFF_ACCESS'} },
          { path: 'staff/:id', component: StaffEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'staff/:id/change-password', component: StaffChangePasswordComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'STAFF_ACCESS'} },
          
          { path: 'staff-family/:staff-id', component: StaffFamilyListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'staff-family/:staff-id/create', component: StaffFamilyCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'staff-family/:staff-id/edit/:id', component: StaffFamilyEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },

          { path: 'admin-user/create', component: AdminUserCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'admin-user', component: AdminUserListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'admin-user/:id', component: AdminUserEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'admin-user/:id/change-password', component: AdminUserChangePasswordComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },

          { path: 'admin-user/:id/change-permission', component: AdminUserChangePermissionComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SUPER_ADMIN_ACCESS'} },
         
          { path: 'team', component: TeamListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'team/create', component: TeamCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'STAFF_ACCESS'} },
          { path: 'team/:id', component: TeamEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          { path: 'team/manage-member/:id', component: TeamManagementComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'COMMON_ACCESS'} },
          
          { path: 'off-duty-jobs', component: OffDutyJobsListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'OFF_DUTY_ALLOW'} },
          { path: 'off-duty-jobs/create', component: OffDutyJobsCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'OFF_DUTY_ALLOW'} },
          { path: 'off-duty-jobs/:id', component: OffDutyJobsEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'OFF_DUTY_ALLOW'} },
         
          { path: 'zone-check-request', component: ZoneCheckRequestListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'ZONE_CHECK_ALLOW'} },
          { path: 'zone-check-request/view', component: ZoneCheckRequestViewComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'ZONE_CHECK_ALLOW'} },
         
          //dynamic component routes
          { path: 'content-pages', component: DynamicContentManagementListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'content-pages/create', component: DynamicContentManagementCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'content-pages/:id/edit', component: DynamicContentManagementEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'content-pages/:slug', component: DynamicContentManagementPagesComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'content-pages/data/create/:slug', component: DynamicContentManagementPagesDataCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'content-pages/data/edit/:id', component: DynamicContentManagementPagesDataEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },

          // template urls
          { path: 'know-your-department', component: KnowYourDepartmentComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'most-wanted', component: MostWantedListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'inmate-info', component: InmateInfoComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'praise-or-complaint', component: PraiseOrComplaintsListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'employment', component: EmploymentListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'crime-prevention', component: CrimePreventionListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'event', component: EventListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'codes-and-ordinance', component: CodesAndOrdinanceComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'victims-services', component: VictimsServicesComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'resource', component: ResourceListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'department', component: DepartmentListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'permit', component: PermitListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'most-wanted/create', component: MostWantedCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'most-wanted/edit/:id', component: MostWantedEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'employment/create', component: EmploymentCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'employment/edit/:id', component: EmploymentEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'event/create', component: EventCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'event/edit/:id', component: EventEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'department/create', component: DepartmentCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'department/edit/:id', component: DepartmentEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'permit/create', component: PermitCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'permit/edit/:id', component: PermitEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'resource/create', component: ResourceCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'resource/edit/:id', component: ResourceEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'crime-prevention/create', component: CrimePreventionCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          { path: 'crime-prevention/edit/:id', component: CrimePreventionEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          //apply for HopkinsvillePolice component
          { path: 'hopkinsville-police', component: HopkinsvillePoliceComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'CONTENT_ACCESS'} },
          
      
          // component urls commented
          // { path: 'know-your-sheriff', component: KnowYourSheriffComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
          // { path: 'sex-offender', component: SexOffenderComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
          // { path: 'business-coalition', component: BusinessCoalitionComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'UCP,LAURENS'} },
          // { path: 'crime-trends', component: CrimeTrendsComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'POLICE,UCP,MARION'} },
          // { path: 'municipal-court', component: MunicipalCourtComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'MARION'} },
           // { path: 'domestic-violence', component: DomesticViolenceComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
           // { path: 'warrents', component: WarrentsListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
          // { path: 'warrents/create', component: WarrentsCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
          // { path: 'warrents/edit/:id', component: WarrentsEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'SHERIFF'} },
          // { path: 'know-your-police-chief', component: KnowYourPoliceComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'POLICE,UCP'} },
          
          //wellness pages
          { path: 'wellness-pages', component: WellnessInformationListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'wellness-pages/:type', component: WellnessInformationListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'wellness-pages-create', component: WellnessInformationCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'wellness-pages/content/:slug', component: WellnessInformationPagesComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'wellness-pages/:id/edit', component: WellnessInformationEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'wellness-pages/content-create/:slug', component: WellnessInformationPagesDataCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'wellness-pages/content/edit/:id', component: WellnessInformationPagesDataEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'wellness-pages/content/edit/:id/:type', component: WellnessInformationPagesDataEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'wellness-pages/mindful-learning/report/:lesson', component: MindfulLearningReportComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },

          { path: 'mindful-learning', component: MindfulLearningListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'mindful-learning/create', component: MindfulLearningCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'mindful-learning/edit', component: MindfulLearningEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          
          //wellness page menu
          { path: 'peer-support', component: PierSupportListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'peer-support/:id/edit', component: PierSupportEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'peer-support/create', component: PierSupportCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },

          { path: 'chaplains', component: ChaplainListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'chaplain/:id/edit', component: ChaplainEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'chaplain/create', component: ChaplainCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },

          { path: 'civilian-counselors', component: CivilianCounselorsListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'civilian-counselor/:id/edit', component: CivilianCounselorEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'civilian-counselor/create', component: CivilianCounselorCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },

          { path: 'general-insurance', component: GeneralInsuranceListComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'general-insurance/:id/edit', component: GeneralInsuranceEditComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'general-insurance/create', component: GeneralInsuranceCreateComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },
          { path: 'employee-assistance-program', component: EmployeeAssistanceProgramComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },

          //wellness dashboard
          { path: 'wellness-dashboard', component: WellnessDashboardComponent, canActivate: [PermissionGuard], data: {allowedRoles: 'WELLNESS_ACCESS'} },

          //social media promotions
          
          { path: '**', component: DashboardComponent },

        ]
      }
    ])
  ],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
