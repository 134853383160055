import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'external-links-list',
  templateUrl: './external-links-list.component.html',
  styleUrls: ['./external-links-list.component.scss']
})
export class ExternalLinkListComponent implements OnInit {
  externalLinks: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  externalLinkId: any ;
  // pagination
  page = 1;
  search = "";
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  apiLink:any;

  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // this.getExternalLinks();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getExternalLinks(this.page,this.search);
      }else {
        this.router.navigate(['external-links', {page:1}]);
      }
    });

  }

  getExternalLinks(page:any,search:any) {
    if (search == "") {
      this.activatedRoute.params.subscribe((params) => {
        page = params['page'];
      });
    }
    this.search = search;
    this.apiLink = 'admin/external-link?page=' + page + "&search=" + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.externalLinks = response.data;
          this.externalLinks = response.data.data;
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;

        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteExternalLink(id: any) {
    this.apiService.deleteRequest('admin/external-link/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getExternalLinks(this.page,this.search);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  getLinktListPage(pageNumber: any) {
    this.router.navigate(['external-links', {page:pageNumber,search:this.search}]);
  }

}
