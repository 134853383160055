import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-staff-import',
  templateUrl: './staff-import.component.html',
  styleUrls: ['./staff-import.component.css']
})
export class StaffImportComponent implements OnInit {
  fileUploadForm: FormGroup;
  apiErrorMessage: string;
  fileToUpload: File = null;

  constructor(private apiService: ApiService, private location: Location, private router: Router, private _flashMessagesService: FlashMessagesService, 
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.fileUploadForm = this.formBuilder.group({
      'file': ['', Validators.required],
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    // this.onSubmit();
  }

  onSubmit() {
    if (this.fileUploadForm.valid) {
      let postData = {
        'file_up': this.fileToUpload
      }
      let postD = new FormData();
      postD.append('file_up',this.fileToUpload);

      this.apiService.postRequest('admin/import-staffs',  postD).subscribe(
        (response: any) => {
          
          if (response.success) {
            if (response.success_message != '') {
              this.router.navigate(['staff']);
              this._flashMessagesService.show(response.success_message, { cssClass: 'alert-success', timeout: 5000 });
              
              if (response.error_message) {
                this._flashMessagesService.show(response.success_message + response.error_message, { cssClass: 'alert-danger', timeout: 5000 });
              }
            } 
          } else {
            this.apiErrorMessage = response.error_message;
            if(response.message) {
              this.apiErrorMessage = this.apiErrorMessage + response.message;
            }
            this._flashMessagesService.show(response.error_message, { cssClass: 'alert-danger', timeout: 5000 });
          }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }


}
