import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-staff-family-list',
  templateUrl: './staff-family-list.component.html',
  styleUrls: ['./staff-family-list.component.scss']
})
export class StaffFamilyListComponent implements OnInit {

  users: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  userId: any ;
  // pagination
  page = 1;
  collectionSize: any;
  pageSize: any;
  lastPage: any;  
  apiLink:any;
  hasStaffAccess: boolean;

  staffId: number;
  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private location: Location, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    let user = this.authService.getCurrentUser();
    let permissions = user.permission_list;
    this.hasStaffAccess = permissions.indexOf("STAFF_ACCESS") !== -1;
    this.getCurrentUser();
    this.activatedRoute.params.subscribe((params) => {
      this.staffId = params['staff-id'];
      if(params['page']){
        this.page = params['page'];
        this.getStaffFamily(this.page);
      } else {
        this.router.navigate(['staff-family/' + this.staffId, { page:1 }]);
      }
    });
  }

  getStaffFamily(page:any) {
    this.apiLink = 'admin/staff-family/' + this.staffId + '?page=' + page;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.users = response.data;
          this.users = response.data.data;
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteUser(id: any) {
    this.apiService.deleteRequest('admin/staff-family/' + this.staffId + '/delete/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getStaffFamily(this.page);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  
  getCurrentUser() {
    
    let user = this.authService.getCurrentUser();
    this.userId = user.id;
  }

  getUsersListPage(pageNumber: any) {
    this.router.navigate(['staff-family/' + this.staffId, { page:pageNumber }]);
  }
  onBackClick() {
    this.router.navigate(['staff']);
  }
}
