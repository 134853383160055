import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'crime-prevention-edit',
  templateUrl: './crime-prevention-edit.component.html',
  styleUrls: ['./crime-prevention-edit.component.scss']
})
export class CrimePreventionEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editPageForm: FormGroup;
  errorMessage = null;
  crimePreventionId: string;
  images = [];
  key:string = "";
  name: string;
  link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline','link','|','imageUpload']
  };    
  @Input() pageTitle: string ;
  @Input() updateUrl: string ;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.crimePreventionId = params['id'];
    });
    this.getCrimeAlert();
    this.editPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'desc': ['',Validators.required],
      // 'image': [''],
      // 'file': [''],
      // 'link': ['',Validators.required],
      // 'link_name': ['']
    });
    // if(!this.pageTitle) {
    //   this.pageTitle = "Crime Prevention";
    // }
    // if(!this.updateUrl) {
    //   this.updateUrl = 'admin/standard-page/' + this.crimePreventionId;
    // }
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  getCrimeAlert () {
    this.apiService.getRequest('admin/app-page/' + this.crimePreventionId).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          this.desc = response.data.desc;
          // this.link = response.data.link;
          this.key = response.data.page_key;
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editPageForm.valid) {
      let postData = {
        'id' : this.crimePreventionId,
        'name': this.editPageForm.value.name,
        'desc': this.editPageForm.value.desc,
        // 'image': this.url,
        // 'link': this.editPageForm.value.link,
        // 'link_name': this.editPageForm.value.link_name,
        // 'link_name': this.editPageForm.value.link_name,
        'type': this.key
      }
      this.apiService.postRequest(this.updateUrl, postData).subscribe(
        (response: any) => {
            if (response.data) {
              if (response.success) {
                var content = response.data;
                // if(!this.isStatic) {
                  this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                // } else {
                //   this.router.navigate(['crime-prevention']);
                // }
              }
              // this.router.navigate(['crime-prevention']);
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }
}
