import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'praise-or-complaints-view',
  templateUrl: './praise-or-complaints-view.component.html',
  styleUrls: ['./praise-or-complaints-view.component.scss']
})
export class PraiseOrComplaintsViewComponent implements OnInit {
  
  @Input() id: number;
  officerName: string;
  citizenName: string;
  description: string;
  createdAt: string;
  read: boolean;

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private modal: NgbActiveModal) { }

  ngOnInit() {
    this.apiService.getRequest('admin/praise-or-complaints/'+ this.id).subscribe(
      (response: any) => {
          if(response.success) {
              this.officerName = response.data.officer_name;
              this.citizenName = response.data.citizen_name;
              this.description = response.data.description;
              this.createdAt = response.data.createdAt;
              this.read = response.data.read;
              if (!this.read) {
                let putData = {};
                this.apiService.postRequest('admin/praise-or-complaints-read/' + response.data.id, putData).subscribe(
                  (res: any) => {
                    if (response.success) {
                      this.apiService.praiseCompCount.next(response.unseen_praise_complaints_count);
                    }
                  },
                  err => {
                    console.log(err);
                  },
                );
              }
          }
      });
  }
  closeModal(result?: any) {
    this.modal.close();
  }

}
