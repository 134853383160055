import { Component, OnInit, ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver  } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { PierSupportCreateComponent } from '../../pier-support/pier-support-create/pier-support-create.component';
import { ChaplainCreateComponent } from '../../chaplains/chaplain-create/chaplain-create.component';
import { CivilianCounselorCreateComponent } from '../../civilian-counselors/civilian-counselors-create/civilian-counselor-create.component';
import { GeneralInsuranceCreateComponent } from '../../general-insurance/general-insurance-create/general-insurance-create.component';
import { MindfulLearningCreateComponent } from '../../mindful-learning/mindful-learning-create/mindful-learning-create.component';

@Component({
  selector: 'app-wellness-information-pages-data-create',
  templateUrl: './wellness-information-pages-data-create.component.html',
  styleUrls: ['./wellness-information-pages-data-create.component.scss']
})
export class WellnessInformationPagesDataCreateComponent {
  apiLink:any;
  content:any;
  targetComponent : string;
  targetTitle : '';
  componentFactory : any;
  private componentRef: ComponentRef<any>;
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: false }) dynamicComponentContainer: ViewContainerRef;
  createUrl : any;
  backUrl : any;
  restricted : any;

  constructor(private resolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.apiLink = 'admin/get-wellness-page/' + params['slug'];
      this.apiService.getRequest(this.apiLink).subscribe(
          (response: any) => {
            if(response.success) {
              this.content = response.data;
              this.targetComponent = this.content.template_name;
              this.targetTitle = this.content.title;
              this.restricted = this.content.restricted
              this.createUrl = 'admin/wellness-content-create/' + this.content.id+'-'+ this.content.slug 
              this.backUrl = 'wellness-pages/content/'+ this.content.id+'-'+ this.content.slug 
              switch(this.targetComponent) {
                case 'PierSupportListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(PierSupportCreateComponent);
                  break;
                }
                case 'ChaplainListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(ChaplainCreateComponent);
                  break;
                }
                case 'CivilianCounselorsListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(CivilianCounselorCreateComponent);
                  break;
                }
                case 'GeneralInsuranceListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(GeneralInsuranceCreateComponent);
                  break;
                }
                case 'MindfulLearningListComponent' : {
                  this.componentFactory = this.resolver.resolveComponentFactory(MindfulLearningCreateComponent);
                  break;
                }
              }
              this.componentRef = this.dynamicComponentContainer.createComponent(this.componentFactory);
              this.componentRef.instance.nextChapter = this.content.next_chapter; //only for mindful page
              this.componentRef.instance.createUrl = this.createUrl;
              this.componentRef.instance.pageTitle = this.targetTitle;
              this.componentRef.instance.isStatic = false;
              this.componentRef.instance.backUrl = this.backUrl;
              this.componentRef.instance.lessonRestricted = this.restricted;
            }
          },
          (err: any) => console.log(err),
        );
        
      });
  }

}
