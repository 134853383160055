import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})

export class AdminService {

    private offdutyGlobalVariableKey = 'OffdutyGlobalVariable'; // key for localStorage

  // Initialize the BehaviorSubject with the value from localStorage if it exists
  private offdutyItemVisible = new BehaviorSubject<any>(this.getFromLocalStorage());

  // Observable to allow other components to subscribe to visibility changes
  offdutyItemVisibility$ = this.offdutyItemVisible.asObservable();

  constructor(private apiService: ApiService) {
    //store the value in localStorage when it changes
    this.offdutyItemVisible.subscribe(value => {
      if (value !== null && value !== undefined) {
        console.log('Setting localStorage value:', value);
        localStorage.setItem(this.offdutyGlobalVariableKey, JSON.stringify(value));
      }
    });
  }

  hideOffdutyMenu() {
    this.offdutyItemVisible.next(false);
  }

  showOffdutyMenu() {
    this.offdutyItemVisible.next(true);
  }

  private getFromLocalStorage() {
    const storedValue = localStorage.getItem(this.offdutyGlobalVariableKey);
    console.log('Stored value from localStorage: ', storedValue);
    return storedValue ? JSON.parse(storedValue) : true;
  }

  fetchSettings() {
    return this.apiService.getRequest('admin/settings').subscribe(
      (settings: any) => {
        console.log('fetchSettings value:', settings);
        this.setOffdutyMenuVisibility(settings.data.offduty_master_link);
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
  }

  // Set the global variable
  setOffdutyMenuVisibility(isVisible: any) {
    console.log('setOffdutyMenuVisibility value:', isVisible);
    if(isVisible != null) {
        this.offdutyItemVisible.next(false);
    }
    else {
        this.offdutyItemVisible.next(true);
    }
  }
  removeFromLocalStorage() {
    if(this.offdutyGlobalVariableKey) {
        localStorage.removeItem(this.offdutyGlobalVariableKey);
    }
  }
}