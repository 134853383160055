import { NgModule, Inject } from '@angular/core';
import { Http, RequestOptions, Response } from '@angular/http';
import { AuthConfig } from 'angular2-jwt';
import { JwtConfigService, JwtHttp } from 'angular2-jwt-refresh';
import { environment } from '../../../environments/environment';
import { AuthStorageService } from '../services/auth.storage.service';
import { CookieService } from 'ngx-cookie-service';

export function getJwtHttp(http: Http, options: RequestOptions, authStorageService: AuthStorageService) {
  const jwtOptions = {
    endPoint: environment.apiUrl + 'refresh-token',
    // optional
    // payload: { type: 'refresh' },
    beforeSeconds: 10, // refresh tokeSn before 10 min
    tokenName: 'session_token',
    refreshTokenGetter: (() => authStorageService.getToken()),
    tokenSetter: ((res: Response): boolean | Promise<void> => {
      const json = res.json();
      authStorageService.setToken(json['token']);
      authStorageService.setUserPermissions(json['permission_list']);
      return true;
    })
  };

  const authConfig = new AuthConfig({
    noJwtError: true,
    tokenName: 'session_token',
    tokenGetter: (() => authStorageService.getToken()),
    globalHeaders: [{ 'Content-Type': 'application/json' }]
  });

  return new JwtHttp(
    new JwtConfigService(jwtOptions, authConfig),
    http,
    options
  );
}

@NgModule({
  providers: [CookieService, AuthStorageService,
    {
      provide: JwtHttp,
      useFactory: getJwtHttp,
      deps: [Http, RequestOptions, AuthStorageService]
    }
  ]
})
export class AuthModule { }
