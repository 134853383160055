import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'general-insurance-list',
  templateUrl: './general-insurance-list.component.html',
  styleUrls: ['./general-insurance-list.component.scss']
})
export class GeneralInsuranceListComponent implements OnInit {
  listData: any;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  employmentId: any ;
    // pagination
    page = 1;
    search = "";
    collectionSize: any;
    pageSize: any;
    lastPage: any;  
    apiLink:any;
    @Input() pageTitle: string;
    @Input() dataUrl: string;
    @Input() createUrl: string;
    @Input() editUrl: string;
    @Input() paginateUrl: string;

  constructor(private location: Location, public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // this.getEmployments();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.getListData(this.page,this.search);
      }
      this.getListData(this.page,this.search);
    });
  }

  getListData(page:any,search:any) {
    if (search == "") {
      this.activatedRoute.params.subscribe((params) => {
        page = params['page'];
      });
    }
    this.search = search;
    this.apiLink = this.dataUrl+ '?page=' + page + "&search=" + search;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.employments = response.data;
          this.listData = response.data.data;
          if(this.listData.length == 0 && this.page > 1) { // to handle deletion of single record page
            this.page -= 1;
            this.getListDataWithPage(this.page)
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
        }
      },
      (err: any) => console.log(err),
    );
  }

  deleteItem(id: any) {
    this.apiService.deleteRequest('admin/app-page/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getListData(this.page,this.search);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  
  getListDataWithPage(pageNumber: any) {
    this.router.navigate([this.paginateUrl, {page:pageNumber,search:this.search}]);
  }
  onBackClick() {
    this.router.navigate(['wellness-pages']);
  }
}
