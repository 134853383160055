import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'civilian-counselor-edit',
  templateUrl: './civilian-counselor-edit.component.html',
  styleUrls: ['./civilian-counselor-edit.component.scss']
})
export class CivilianCounselorEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editPageForm: FormGroup;
  errorMessage = null;
  id: string;
  images = [];
  key:string = "";
  name: string;
  link: string;
  desc: string;
  section: string;
  phone: string;
  email;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    
  @Input() pageTitle: string ;
  @Input() updateUrl: string ;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;

  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.getData();
    this.editPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'desc': [''],
      // 'image': [''],
      // 'file': [''],
      'link': ['',''],
      // 'link_name': ['']
      'phone': ['',[Validators.required, Validators.pattern("[- +()0-9]+")]],
      'email': ['',[Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      'section': ['',Validators.required],
    });
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  getData () {
    this.apiService.getRequest('admin/app-page/' + this.id).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          this.desc = response.data.desc;
          // this.link = response.data.link;
          this.key = response.data.page_key;
          this.section = response.data.section_or_religion;
          this.phone = response.data.phone;
          this.email = response.data.email;

        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editPageForm.valid) {
      let postData = {
        'name': this.editPageForm.value.name,
        'desc': this.editPageForm.value.desc,
        // 'image': this.url,
        // 'link': this.editPageForm.value.link,
        'section_or_religion': this.editPageForm.value.section,
        'phone': this.editPageForm.value.phone,
        'email': this.editPageForm.value.email,
        'type': this.key,
      }
      this.apiService.postRequest(this.updateUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              var content = response.data;
              this.router.navigate(['wellness-pages/content',content.id + "-" + content.slug]);
              // this.router.navigate(['civilian-counselors']);
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }
}
