import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from './../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  errorMessage = null;
  emailSent:boolean = false;
  clientName: string;
  loginBg;
  logo;

  constructor(private fb: FormBuilder,
              private apiService: ApiService,
              private flashMessagesService: FlashMessagesService,
              private router: Router) {
                this.loginBg = environment.loginBg;
                this.logo = environment.loginLogo;
               }

  ngOnInit() {
    this.apiService.getRequest('get-login-image?type=admin-login-image').subscribe(
      (response: any) => {
        if (response.success) {
          this.loginBg = response.file_name;
        }
      },
      (err: any) => {
      }
    );
    this.forgotPasswordForm = this.fb.group({
      'email': ['', [Validators.required, Validators.email]]
    });
  }

  submit() {
    this.clientName = environment.clientName;
    if (this.forgotPasswordForm.valid) {
      let postData = { 
        'email': this.forgotPasswordForm.value.email,
        'user_type' : 1
      };
      this.apiService.postRequest('forgot-password', postData).subscribe(
        (response: any) => {
          if(response.success) {
            this.flashMessagesService.show('An email has been sent to your email address. Follow the directions in the email to reset the password', { cssClass: 'alert-success', timeout: 8000 });
            this.router.navigate(['/login']);
          } else {
            this.flashMessagesService.show('Password reset email send failed!', { cssClass: 'alert-danger' });
            this.errorMessage = response.message;
          }
        },
        (error: any) => {
          this.flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        }
      );
    }
  }

}
