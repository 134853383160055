import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'most-wanted-edit',
  templateUrl: './most-wanted-edit.component.html',
  styleUrls: ['./most-wanted-edit.component.scss']
})
export class MostWantedEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editPageForm: FormGroup;
  errorMessage = null;
  mostWantedId: string;
  images = [];
  image :string;
  key:string = "";
  name: string;
  url:string = "";
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    

  allFilesUploaded=[];
  stringfiedAttachments = '';
  fileToUpload: File = null;
  uploadedDoc = false;
  imagestobeDeleted=[];
  documentUploaded = {
    'doc_name': null,
    'doc_link': null,
    'document': null,
  };
  isDocUploaded = false;
  desc :string;
  @Input() pageTitle: string ;
  @Input() updateUrl: string ;
  @Input() isStatic: boolean ;  
  @Input() backUrl: string ;
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    // if(!this.pageTitle) {
    //   this.pageTitle = "Most Wanted";
    // }
    this.activatedRoute.params.subscribe((params) => {
      this.mostWantedId = params['id'];
    });
    this.getMostWanted();
    this.editPageForm = this.formBuilder.group({
      'name': ['',Validators.required],
      // 'image': [''],
      'desc': [''],
      // 'phone': ['',Validators.pattern("[- +()0-9]+")],
      // 'email': ['',Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
      // 'link': ['',Validators.required],
      // 'link_name': ['']
      'images': [''],
      'document': [''],
    });
    // if(!this.updateUrl) {
    //   this.updateUrl = 'admin/standard-page/' + this.mostWantedId;
    // }
  }
  onReady(eventData) {
    var service = this.apiService;
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      return new UploadAdaptor(loader,service);
    };
  }
  imageUpload(event) {
    if (event.target) {
      var formData = new FormData;
      formData.append('image',event.target.file[0]);
      this.apiService.postRequest('admin/app-image', formData).subscribe(
        (response: any) => {
            if (response.success) {
              // this.url = response.path;
            }
        },
        err => {
            console.log(err);
        },
      );
    }
  }
  getMostWanted () {
    this.apiService.getRequest('admin/app-page/' + this.mostWantedId).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          // this.image = response.data.image;
          // this.url = response.data.image_url;
          this.key = response.data.page_key;
          this.desc = response.data.desc;
          for (var i = 0; i < response.data.images.length; i += 1) {
            let resposeData = {
              'name': response.data.images[i]['name'],
              'image_link': response.data.images[i]['image_url'],
              'image': response.data.images[i]['image'],
              'id': response.data.images[i]['id'],
            }
            this.allFilesUploaded.push(resposeData);
          }
          if(response.data.document_name && response.data.document_url && response.data.document){
            this.documentUploaded = {
              'doc_name': response.data.document_name,
              'doc_link': response.data.document_url,
              'document': response.data.document,
            }
            this.isDocUploaded = true;
          }
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editPageForm.valid) {
      let postData = {
        'id' : this.mostWantedId,
        'name': this.editPageForm.value.name,
        // 'image' : this.url,
        'type': this.key,
        'images': this.allFilesUploaded,
        'images_to_be_deleted': this.imagestobeDeleted,
        'desc': this.editPageForm.value.desc,
      }
      postData['document'] = this.documentUploaded['document'];
      postData['document_name'] = this.documentUploaded['doc_name'];
      this.apiService.postRequest(this.updateUrl, postData).subscribe(
        (response: any) => {
            if (response.success) {
              if (response.data) {
                var content = response.data;
                // if(!this.isStatic) {
                  this.router.navigate(['content-pages',content.id + "-" + content.slug]);
                // } else {
                //   this.router.navigate(['most-wanted']);
                // }
              }
              // this.router.navigate(['most-wanted']);
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.editPageForm.markAsPristine();
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }

  openDialog(target) {
    this.dialog.open(target);
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  onImageAttachments(files: FileList){
    for (var i = 0; i < files.length; i += 1) {
      this.fileToUpload = files.item(i);
      let postD = new FormData();
      postD.append('image', this.fileToUpload);
      this.apiService.postRequest('admin/app-image',  postD).subscribe(
        (response: any) => {
        if(response.success){
          let resposeData = {
            'name': response.file_name,
            'image_link': response.path,
            'image': response.image,
            'id': null,
          }
          this.allFilesUploaded.push(resposeData);
        } else {
        }
        },
        (error: any) => {
        }
      );
    }
  }
  removeIndex(index) {
    if(this.allFilesUploaded[index]['id']){
      // console.log(this.allFilesUploaded[index]);
      this.imagestobeDeleted.push(this.allFilesUploaded[index]['id'])
    }
    this.allFilesUploaded.splice(index, 1);
  }

  onDocumentAttachment(files: FileList){
    let file = files.item(0);
    this.fileToUpload = file;
    let postD = new FormData();
    postD.append('document', this.fileToUpload);
    this.apiService.postRequest('admin/app-document',  postD).subscribe(
      (response: any) => {
      if(response.success){
        this.documentUploaded = {
          'doc_name': response.file_name,
          'doc_link': response.path,
          'document': response.document,
        }
        this.isDocUploaded = true;
      } else {
      }
      },
      (error: any) => {
      }
    );
  }
  removeDocument() {
    this.isDocUploaded = false;
    this.documentUploaded = {
      'doc_name': null,
      'doc_link': null,
      'document': null,
    };
  }
  onBackClick() {
    if(!this.isStatic) {
      if(this.backUrl)  {
        this.router.navigate([this.backUrl]);
      }
    } else {
      this.location.back();
    }
  }

}
