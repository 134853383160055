import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient, HttpHeaders  } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AuthService } from './shared/services/auth.service';
import { ApiService } from './shared/services/api.service';
import { CommonService } from './shared/services/common.service';
import { AuthModule } from '../app/shared/auth/auth.module';
import { AuthGuard } from './shared/auth/auth.guard';
import { PermissionGuard } from './shared/auth/permission.guard';

import { ApiModule } from '../app/shared/api.module';
import { RefreshTokenInterceptor } from './helpers/refresh-token-interceptor';

import { FlashMessagesModule } from 'angular2-flash-messages';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import 'bootstrap/dist/css/bootstrap.css';
// import { NgxBarcode6Module } from 'ngx-barcode6';
import { SafeHtmlPipe } from './safe-html.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ValidationComponent } from './validation/validation.component';
import { AlertViewComponent } from './alert/alert-view/alert-view.component';
import { AlertListComponent } from './alert/alert-list/alert-list.component';
import { AlertCreateComponent } from './alert/alert-create/alert-create.component';
import { ManageAppPagesViewComponent } from './manage-app-pages/manage-app-pages-view/manage-app-pages-view.component';
import { ManageAppPagesListComponent } from './manage-app-pages/manage-app-pages-list/manage-app-pages-list.component';
import { ManageAppPagesCreateComponent } from './manage-app-pages/manage-app-pages-create/manage-app-pages-create.component';
import { TipViewComponent } from './tip/tip-view/tip-view.component';
import { TipListComponent } from './tip/tip-list/tip-list.component';
import { StaffAlertCreateComponent } from './staff-alert/staff-alert-create/staff-alert-create.component';
import { StaffAlertListComponent } from './staff-alert/staff-alert-list/staff-alert-list.component';
import { StaffAlertViewComponent } from './staff-alert/staff-alert-view/staff-alert-view.component';
import { StaffAlertNotificationComponent } from './staff-alert/staff-alert-notification/staff-alert-notification.component';
import { ProfileComponent } from './profile/profile-edit/profile.component';
import { StaffCreateComponent } from './staff/staff-create/staff-create.component';
import { StaffListComponent } from './staff/staff-list/staff-list.component';
import { StaffEditComponent } from './staff/staff-edit/staff-edit.component';
import { StaffImportComponent } from './staff/staff-import/staff-import.component';
import { StaffChangePasswordComponent } from './staff/staff-change-password/staff-change-password.component';
import { AdminUserCreateComponent } from './admin-user/admin-user-create/admin-user-create.component';
import { AdminUserListComponent } from './admin-user/admin-user-list/admin-user-list.component';
import { AdminUserEditComponent } from './admin-user/admin-user-edit/admin-user-edit.component';
import { AdminUserChangePasswordComponent } from './admin-user/admin-user-change-password/admin-user-change-password.component';
import { AdminUserChangePermissionComponent } from './admin-user/admin-user-change-permission/admin-user-change-permission.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { LoaderComponent } from './loader/loader.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AgmCoreModule } from '@agm/core';
import { HomeModule } from './home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { TeamListComponent } from './team/team-list/team-list.component';
import { TeamEditComponent } from './team/team-edit/team-edit.component';
import { TeamCreateComponent } from './team/team-create/team-create.component';
import { TeamManagementComponent } from './team/team-management/team-management.component';
import { environment } from 'src/environments/environment';
import { KnowYourSheriffComponent } from './know-your-sheriff/know-your-sheriff.component';
import { InmateInfoComponent } from './inmate-info/inmate-info.component';
import { SexOffenderComponent } from './sex-offender/sex-offender.component';
import { JobCreateComponent } from './jobs/job-create/job-create.component';
import { JobEditComponent } from './jobs/job-edit/job-edit.component';
import { JobListComponent } from './jobs/job-list/job-list.component';
import { PermitCreateComponent } from './permits/permits-create/permits-create.component';
import { PermitEditComponent } from './permits/permits-edit/permits-edit.component';
import { PermitListComponent } from './permits/permits-list/permits-list.component';
import { KnowYourDepartmentComponent } from './know-your-department/know-your-department.component';
import { KnowYourPoliceComponent } from './know-your-police-chief/know-your-police-chief.component';
import { CrimeTrendsComponent } from './crime-trends/crime-trends.component';
import { PraiseOrComplaintsListComponent } from './praise-or-complaints/praise-or-complaints-list/praise-or-complaints-list.component';
import { PraiseOrComplaintsViewComponent } from './praise-or-complaints/praise-or-complaints-view/praise-or-complaints-view.component';
import { EmploymentListComponent } from './employment/employment-list/employment-list.component';
import { EmploymentEditComponent } from './employment/employment-edit/employment-edit.component';
import { EmploymentCreateComponent } from './employment/employment-create/employment-create.component';
import { RecruitmentListComponent } from './recruitment/recruitment-list/recruitment-list.component';
import { RecruitmentEditComponent } from './recruitment/recruitment-edit/recruitment-edit.component';
import { RecruitmentCreateComponent } from './recruitment/recruitment-create/recruitment-create.component';
import { SpecialCrimeAlertsListComponent } from './special-crime-alerts/special-crime-alerts-list/special-crime-alerts-list.component';
import { SpecialCrimeAlertsEditComponent } from './special-crime-alerts/special-crime-alerts-edit/special-crime-alerts-edit.component';
import { SpecialCrimeAlertsCreateComponent } from './special-crime-alerts/special-crime-alerts-create/special-crime-alerts-create.component';
import { DepartmentCreateComponent } from './departments/department-create/department-create.component';
import { DepartmentEditComponent } from './departments/department-edit/department-edit.component';
import { DepartmentListComponent } from './departments/department-list/department-list.component';
import { MostWantedCreateComponent } from './most-wanted/most-wanted-create/most-wanted-create.component';
import { MostWantedEditComponent } from './most-wanted/most-wanted-edit/most-wanted-edit.component';
import { MostWantedListComponent } from './most-wanted/most-wanted-list/most-wanted-list.component';
import { CrimePreventionCreateComponent } from './crime-prevention/crime-prevention-create/crime-prevention-create.component';
import { CrimePreventionEditComponent } from './crime-prevention/crime-prevention-edit/crime-prevention-edit.component';
import { CrimePreventionListComponent } from './crime-prevention/crime-prevention-list/crime-prevention-list.component';
import { CrimePreventionTipsCreateComponent } from './crime-prevention-tips/crime-prevention-tips-create/crime-prevention-tips-create.component';
import { CrimePreventionTipsEditComponent } from './crime-prevention-tips/crime-prevention-tips-edit/crime-prevention-tips-edit.component';
import { CrimePreventionTipsListComponent } from './crime-prevention-tips/crime-prevention-tips-list/crime-prevention-tips-list.component';
import { ContactUsListComponent } from './contact-us/contact-us-list/contact-us-list.component';
import { ContactUsCreateComponent } from './contact-us/contact-us-create/contact-us-create.component';
import { ContactUsEditComponent } from './contact-us/contact-us-edit/contact-us-edit.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { OffDutyJobsListComponent } from './offdutyjobs/offdutyjobs-list/offduty-list.component';
import { OffDutyJobsCreateComponent } from './offdutyjobs/offdutyjobs-create/offduty-create.component';
import { OffDutyJobsEditComponent } from './offdutyjobs/offdutyjobs-edit/offduty-edit.component';
import { ExternalLinkCreateComponent } from './external-links/external-links-create/external-links-create.component';
import { ExternalLinkEditComponent } from './external-links/external-links-edit/external-links-edit.component';
import { ExternalLinkListComponent } from './external-links/external-links-list/external-links-list.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TwoDecimalFormatDirective } from './two-decimal-format-directive';
import { ZoneCheckRequestListComponent } from './zone-check-request/zone-check-request-list/zone-check-request-list.component';
import { ZoneCheckRequestViewComponent } from './zone-check-request/zone-check-request-view/zone-check-request-view.component';
import { EventCreateComponent } from './events/event-create/event-create.component';
import { EventEditComponent } from './events/event-edit/event-edit.component';
import { EventListComponent } from './events/event-list/event-list.component';
import { BusinessCoalitionComponent } from './business-coalition/business-coalition.component';
import { MunicipalCourtComponent } from './municipal-court/municipal-court.component';
import { VictimsServicesComponent } from './victims-services/victims-services.component';
import { CodesAndOrdinanceComponent } from './codes-and-ordinance/codes-and-ordinance.component';
import { ResourceListComponent } from './resources/resource-list/resource-list.component';
import { ResourceCreateComponent } from './resources/resource-create/resource-create.component';
import { ResourceEditComponent } from './resources/resource-edit/resource-edit.component';
import { SettingsComponent } from './settings/settings.component';
import { DomesticViolenceComponent } from './domestic-violence/domestic-violence.component';
import { WarrentsCreateComponent } from './warrents/warrents-create/warrents-create.component';
import { WarrentsEditComponent } from './warrents/warrents-edit/warrents-edit.component';
import { WarrentsListComponent } from './warrents/warrents-list/warrents-list.component';

import { PierSupportListComponent } from './pier-support/pier-support-list/pier-support-list.component';
import { PierSupportCreateComponent } from './pier-support/pier-support-create/pier-support-create.component';
import { PierSupportEditComponent } from './pier-support/pier-support-edit/pier-support-edit.component';

import { ChaplainListComponent } from './chaplains/chaplain-list/chaplain-list.component';
import { ChaplainCreateComponent } from './chaplains/chaplain-create/chaplain-create.component';
import { ChaplainEditComponent } from './chaplains/chaplain-edit/chaplain-edit.component';

import { CivilianCounselorsListComponent } from './civilian-counselors/civilian-counselors-list/civilian-counselors-list.component';
import { CivilianCounselorCreateComponent } from './civilian-counselors/civilian-counselors-create/civilian-counselor-create.component';
import { CivilianCounselorEditComponent } from './civilian-counselors/civilian-counselors-edit/civilian-counselor-edit.component';

import { GeneralInsuranceCreateComponent } from './general-insurance/general-insurance-create/general-insurance-create.component';
import { GeneralInsuranceEditComponent } from './general-insurance/general-insurance-edit/general-insurance-edit.component';
import { GeneralInsuranceListComponent } from './general-insurance/general-insurance-list/general-insurance-list.component';
import { EmployeeAssistanceProgramComponent } from './employee-assistance-program/employee-assistance-program.component';
import { DynamicContentManagementListComponent } from './dynamic-content-management/dynamic-content-management-list/dynamic-content-management-list.component';
import { DynamicContentManagementCreateComponent } from './dynamic-content-management/dynamic-content-management-create/dynamic-content-management-create.component';
import { DynamicContentManagementPagesComponent } from './dynamic-content-management/dynamic-content-management-pages/dynamic-content-management-pages.component';
import { DynamicContentManagementPagesDataCreateComponent } from './dynamic-content-management/dynamic-content-management-pages-data-create/dynamic-content-management-pages-data-create.component';
import { DynamicContentManagementPagesDataEditComponent } from './dynamic-content-management/dynamic-content-management-pages-data-edit/dynamic-content-management-pages-data-edit.component';
import { DynamicContentManagementEditComponent } from './dynamic-content-management/dynamic-content-management-edit/dynamic-content-management-edit.component';
import { StaffFamilyListComponent } from './staff-family/staff-family-list/staff-family-list.component';
import { StaffFamilyCreateComponent } from './staff-family/staff-family-create/staff-family-create.component';
import { StaffFamilyEditComponent } from './staff-family/staff-family-edit/staff-family-edit.component';
import { WellnessInformationPagesComponent } from './wellness-information/wellness-information-pages/wellness-information-pages.component';
import { WellnessInformationListComponent } from './wellness-information/wellness-information-list/wellness-information-list.component';
import { WellnessInformationCreateComponent } from './wellness-information/wellness-information-create/wellness-information-create.component';
import { MindfulLearningCreateComponent } from './mindful-learning/mindful-learning-create/mindful-learning-create.component';
import { MindfulLearningListComponent } from './mindful-learning/mindful-learning-list/mindful-learning-list.component';
import { WellnessInformationPagesDataCreateComponent } from './wellness-information/wellness-information-pages-data-create/wellness-information-pages-data-create.component';
import { WellnessInformationPagesDataEditComponent } from './wellness-information/wellness-information-pages-data-edit/wellness-information-pages-data-edit.component';
import { MindfulLearningEditComponent } from './mindful-learning/mindful-learning-edit/mindful-learning-edit.component';
import { WellnessInformationEditComponent } from './wellness-information/wellness-information-edit/wellness-information-edit.component';
import { MindfulLearningReportComponent } from './mindful-learning/mindful-learning-report/mindful-learning-report.component';
import { AngMusicPlayerModule } from  'ang-music-player';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { HopkinsvillePoliceComponent } from './hopkinsville-police/hopkinsville-police.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FilterPipe } from './filter.pipe';
import { WellnessDashboardComponent } from './wellness-dashboard/wellness-dashboard.component';
import { NgImageSliderModule } from 'ng-image-slider';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    ValidationComponent,
    AlertViewComponent,
    AlertListComponent,
    AlertCreateComponent,
    ManageAppPagesViewComponent,
    ManageAppPagesListComponent,
    ManageAppPagesCreateComponent,
    TipViewComponent,
    TipListComponent,
    StaffAlertCreateComponent,
    StaffAlertListComponent,
    StaffAlertViewComponent,
    StaffAlertNotificationComponent,
    ProfileComponent,
    StaffCreateComponent,
    StaffListComponent,
    StaffEditComponent,
    StaffImportComponent,
    StaffChangePasswordComponent,
    AdminUserCreateComponent,
    AdminUserListComponent,
    AdminUserEditComponent,
    AdminUserChangePasswordComponent,
    AdminUserChangePermissionComponent,
    ChangePasswordComponent,
    LoaderComponent,
    PasswordResetComponent,
    TeamListComponent,
    TeamEditComponent,
    TeamCreateComponent,
    TeamManagementComponent,
    KnowYourSheriffComponent,
    InmateInfoComponent,
    SexOffenderComponent,
    JobCreateComponent,
    JobEditComponent,
    JobListComponent,
    PermitCreateComponent,
    PermitEditComponent,
    PermitListComponent,
    KnowYourDepartmentComponent,
    KnowYourPoliceComponent,
    CrimeTrendsComponent,
    PraiseOrComplaintsListComponent,
    PraiseOrComplaintsViewComponent,
    EmploymentListComponent,
    EmploymentEditComponent,
    EmploymentCreateComponent,
    RecruitmentListComponent,
    RecruitmentEditComponent,
    RecruitmentCreateComponent,
    SpecialCrimeAlertsListComponent,
    SpecialCrimeAlertsEditComponent,
    SpecialCrimeAlertsCreateComponent,
    DepartmentCreateComponent,
    DepartmentEditComponent,
    DepartmentListComponent,
    MostWantedCreateComponent,
    MostWantedEditComponent,
    MostWantedListComponent,
    WarrentsCreateComponent,
    WarrentsEditComponent,
    WarrentsListComponent,
    CrimePreventionCreateComponent,
    CrimePreventionEditComponent,
    CrimePreventionListComponent,
    CrimePreventionTipsCreateComponent,
    CrimePreventionTipsEditComponent,
    CrimePreventionTipsListComponent,
    ContactUsListComponent,
    ContactUsCreateComponent,
    ContactUsEditComponent,
    OffDutyJobsListComponent,
    OffDutyJobsCreateComponent,
    OffDutyJobsEditComponent,
    ExternalLinkCreateComponent,
    ExternalLinkEditComponent,
    ExternalLinkListComponent,
    TwoDecimalFormatDirective,
    ZoneCheckRequestListComponent,
    ZoneCheckRequestViewComponent,
    EventListComponent,
    EventEditComponent,
    EventCreateComponent,
    CodesAndOrdinanceComponent,
    BusinessCoalitionComponent,
    MunicipalCourtComponent,
    VictimsServicesComponent,
    DomesticViolenceComponent,
    ResourceListComponent,
    ResourceEditComponent,
    ResourceCreateComponent,
    TemplateListComponent,
    PierSupportListComponent,
    PierSupportCreateComponent,
    PierSupportEditComponent,
    ChaplainListComponent,
    ChaplainCreateComponent,
    ChaplainEditComponent,
    CivilianCounselorsListComponent,
    CivilianCounselorCreateComponent,
    CivilianCounselorEditComponent,
    GeneralInsuranceCreateComponent,
    GeneralInsuranceEditComponent,
    GeneralInsuranceListComponent,
    EmployeeAssistanceProgramComponent,
    SafeHtmlPipe,
    TwoDecimalFormatDirective,
    SettingsComponent,
    DynamicContentManagementListComponent,
    DynamicContentManagementCreateComponent,
    DynamicContentManagementPagesComponent,
    DynamicContentManagementPagesDataCreateComponent,
    DynamicContentManagementPagesDataEditComponent,
    DynamicContentManagementEditComponent,
    StaffFamilyListComponent,
    StaffFamilyCreateComponent,
    StaffFamilyEditComponent,
    WellnessInformationPagesComponent,
    WellnessInformationListComponent,
    WellnessInformationCreateComponent,
    MindfulLearningCreateComponent,
    MindfulLearningListComponent,
    WellnessInformationPagesDataCreateComponent,
    WellnessInformationPagesDataEditComponent,
    MindfulLearningEditComponent,
    WellnessInformationEditComponent,
    MindfulLearningReportComponent,
    HopkinsvillePoliceComponent,
    FilterPipe,
    WellnessDashboardComponent
  ],
  imports: [
    NgCircleProgressModule.forRoot({
      "backgroundPadding": 0,
      "radius": 86,
      "space": -18,
      "outerStrokeWidth": 18,
      "outerStrokeColor": "#5f56ce",
      "innerStrokeColor": "#e3e8f9",
      "innerStrokeWidth": 18,
      "titleFontSize": "10",
      "unitsFontSize": "10",
      "subtitleFontSize": "10",
      "titleFontWeight": "100",
      "unitsFontWeight": "100",
      "subtitleFontWeight": "100",
      "animationDuration": 500,
    }),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    AuthModule,
    FormsModule,
    NgbModule,
    CKEditorModule,
    AngularMultiSelectModule,
    FlashMessagesModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey
    }),
    ApiModule,
    // NgxBarcode6Module,
    HomeModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    AngMusicPlayerModule,
    DragDropModule,
    NgImageSliderModule
  ],
  providers: [AuthService, ApiService, AuthGuard, PermissionGuard, CommonService, {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
