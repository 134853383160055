import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JWT_OPTIONS, JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { AuthStorageService } from './services/auth.storage.service';
import { environment } from '../../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RefreshTokenInterceptor } from '../helpers/refresh-token-interceptor';
import { ɵa } from 'ang-music-player';
export function jwtOptionsFactory (AuthStorageService: AuthStorageService) {
  return {
    tokenGetter: () => {
      return AuthStorageService.getToken();
    },
    blacklistedRoutes: [],
    whitelistedDomains: environment.whitelist,
  };
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthStorageService]
      }
    })
  ],
  providers: [
    AuthStorageService,
    JwtInterceptor, // Providing JwtInterceptor allow to inject JwtInterceptor manually into RefreshTokenInterceptor
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    }
  ],
  declarations: [
    ɵa,
  ]
})
export class ApiModule {
}