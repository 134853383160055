import { Component, OnInit, ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver  } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ActivatedRoute, Router } from '@angular/router';
import { PierSupportListComponent } from '../../pier-support/pier-support-list/pier-support-list.component';
import { ChaplainListComponent } from '../../chaplains/chaplain-list/chaplain-list.component';
import { CivilianCounselorsListComponent } from '../../civilian-counselors/civilian-counselors-list/civilian-counselors-list.component';
import { GeneralInsuranceListComponent } from '../../general-insurance/general-insurance-list/general-insurance-list.component';
import { EmployeeAssistanceProgramComponent } from '../../employee-assistance-program/employee-assistance-program.component';
import { MindfulLearningListComponent } from '../../mindful-learning/mindful-learning-list/mindful-learning-list.component';

@Component({
  selector: 'app-wellness-information-pages',
  templateUrl: './wellness-information-pages.component.html',
  styleUrls: ['./wellness-information-pages.component.scss']
})
export class WellnessInformationPagesComponent {
  apiLink:any;
  content:any;
  targetComponent : string;
  targetTitle : '';
  componentFactory : any;
  dataUrl : any;
  postUrl : any;
  createUrl : any;
  editUrl : any;
  paginateUrl : any;
  backUrl : any;
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: false }) dynamicComponentContainer: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  constructor(private resolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef, public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe((params) => {
    this.apiLink = 'admin/get-wellness-page/' + params['slug'];
    this.apiService.getRequest(this.apiLink).subscribe(
        (response: any) => {
          if(response.success) {
            this.content = response.data;
            this.targetComponent = this.content.template_name;
            this.targetTitle = this.content.title;
            this.dynamicComponentContainer.clear();
            this.dataUrl = 'admin/wellness-content/' + this.content.id;
            this.postUrl = 'admin/update-wellness-data/' + this.content.id;
            this.createUrl = '/wellness-pages/content-create/'  +  this.content.id + '-' +  this.content.slug;
            this.editUrl = '/wellness-pages/content/edit/';
            this.paginateUrl = 'wellness-pages/content/' +  this.content.id + '-' +  this.content.slug;
            this.backUrl = '/wellness-pages/content/' +  this.content.id + '-' +  this.content.slug 
            switch(this.targetComponent) {
              case 'PierSupportListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(PierSupportListComponent);
                break;
              }
              case 'ChaplainListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(ChaplainListComponent);
                break;
              }
              case 'CivilianCounselorsListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(CivilianCounselorsListComponent);
                break;
              }
              case 'GeneralInsuranceListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(GeneralInsuranceListComponent);
                break;
              }
              case 'EmployeeAssistanceProgramComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(EmployeeAssistanceProgramComponent);
                break;
              }
              case 'MindfulLearningListComponent' : {
                this.componentFactory = this.resolver.resolveComponentFactory(MindfulLearningListComponent);                
                break;
              }
            }
            this.componentRef = this.dynamicComponentContainer.createComponent(this.componentFactory);
            this.componentRef.instance.pageTitle = this.targetTitle;
            this.componentRef.instance.dataUrl = this.dataUrl;
            this.componentRef.instance.postUrl = this.postUrl;
            this.componentRef.instance.createUrl = this.createUrl;
            this.componentRef.instance.editUrl = this.editUrl;
            this.componentRef.instance.paginateUrl = this.paginateUrl;
            this.componentRef.instance.backUrl = this.backUrl;
            this.componentRef.instance.restricted = this.content.restricted;
            this.componentRef.instance.author = this.content.author;
          }
        },
        (err: any) => console.log(err),
      );
    });
  }

}
