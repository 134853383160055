import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ZoneCheckRequestViewComponent } from '../zone-check-request-view/zone-check-request-view.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { interval, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as moment from 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-zone-check-request-list',
  templateUrl: './zone-check-request-list.component.html',
  styleUrls: ['./zone-check-request-list.component.scss']
})
export class ZoneCheckRequestListComponent implements OnInit {
  responseData:any;

  popoverTitle: string = 'Confirm Archive';
  popoverMessage: string = 'Are you sure?';
    // pagination
    page = 1;
    collectionSize: any;
    pageSize: any;
    lastPage: any;  
    apiLink:any;  
    dropdownSettings = {};
    dropdownList = [];
    modalRef: any
    assignStaffForZoneCheckForm: FormGroup;
    requestId : number;
    assigned = 0
    assignedUsers: string;
    zoneCheckText: string;
    isZoneExternal: boolean;

    selectedUsersOnly = false;
    selectedTeamOnly: boolean = false;
    hasStaff = true;
    hasTeam = true;
    selectedItems = [];
    selectedStaff = [];
    selectedTeam = [];
    tipAssigned: boolean = false;
    todayDate:Date = new Date();
    dateForm: FormGroup;
    dateRange: any;
    accoModalRef:any;
    errorMessage = null;
    minDate = null;

    @ViewChild('assignModal', {static: false}) assignModal : TemplateRef<any>;
    @ViewChild('dateRangeModel', { static: false }) dateRangeModel: TemplateRef<any>;

    
  constructor(public apiService: ApiService, private modal: NgbModal, private _flashMessagesService: FlashMessagesService, private activatedRoute: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) { 
    this.getZoneCheckRequests(this.page);
  }

  ngOnInit() {

    // if (environment.appName == "East Ridge") {
    //   this.zoneCheckText = "Watch";
    // }
    // else if (environment.appName == "MILTON") {
    //   this.zoneCheckText = "Vacation Check";
    // }
    // else {
      this.zoneCheckText = environment.zoneText;
      this.isZoneExternal = environment.isZoneExternal;
      // }
    if(this.isZoneExternal) {
      this.router.navigate(['dashboard']);
    }
    this.assignStaffForZoneCheckForm = this.formBuilder.group({
      'staff': ['', Validators.required],
    });
    this.dropdownList = [];
    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select one or more options:",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class",
      enableCheckAll: false
    };

    this.dateForm = this.formBuilder.group({
      'start_date' : [''],
      'end_date' : [''],
      'include_archive' : [''],
    });
    
    this.getStaffList();

    // this.getZoneCheckRequests();
    this.activatedRoute.params.subscribe((params) => {
      if(params['page']){
        this.page = params['page'];
        this.getZoneCheckRequests(this.page);
      }else {
        if(this.isZoneExternal) {
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['zone-check-request', {page:1}]);
        }
      }
      
    });

  }

  getZoneCheckRequests(page:any) {
    this.apiLink = 'admin/zone-check-requests?page=' + page;
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        if(response.success) {
          // this.responseData = response.data;
          this.responseData = response.data.data;
          // console.log(this.responseData);
          if(this.responseData.length == 0 && this.page > 1) { // to handle deletion of single record page
            this.page -= 1;
            this.router.navigate(['zone-check-request', {page:this.page}]);
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;

        }
      },
      (err: any) => console.log(err),
    );
  }

  viewRequest(item: any) {
      const modalRef = this.modal.open(ZoneCheckRequestViewComponent);
      modalRef.componentInstance.request = item;
      modalRef.result.then(() => {
        this.getZoneCheckRequests(this.page);
      }).catch((e) => { this.getZoneCheckRequests(this.page); });
  }
  formatDateTime(date) {
    if (!date) {
      return "";
    }
    if (!(moment(date).isValid())) {
      return "";
    }
    // return moment(date).tz("America/New_York").format('MM/DD/YYYY hh:mm:ss A');
    return moment(moment.utc(date).toDate()).local().format('MM/DD/YYYY');
  }
  deleteZoneCheck(id: any) {
    this.apiService.deleteRequest('admin/zone-check-requests/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getZoneCheckRequests(this.page);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }
  getZoneCheckListPage(pageNumber: any) {
    this.router.navigate(['zone-check-request', {page:pageNumber}]);
  }
  
  getStaffList() {
    this.apiService.getRequest('admin/staff').subscribe(
      (response: any) => {
        if(response.success) {
          var staffList = response.data;
          staffList.forEach((staff: any) => {
            this.dropdownList.push({"id":'s_' + staff.id,"itemName":staff.first_name + ' ' + staff.last_name,"type":"staff"});
          });
        }
        this.getTeamList();
      },
      (err: any) => console.log(err),
    );
  }

  getTeamList() {
    this.apiService.getRequest('admin/team').subscribe(
      (response: any) => {
        if(response.success) {
          var teamList = response.data;
          teamList.forEach((team: any) => {
            this.dropdownList.push({"id":'t_' + team.id,"itemName":team.name,"type":"team"});
          });
        }
      },
      (err: any) => console.log(err),
    );
  }

  assignStaff(id: any) { 
    this.requestId = id;
    this.modalRef = this.modal.open(this.assignModal);
  }

  closeModal() {
    this.modalRef.close();
  }

  onSubmit() {
    if (this.assignStaffForZoneCheckForm.valid) {
      this.selectedStaff = [];
      this.selectedTeam = [];
      this.assignStaffForZoneCheckForm.value.staff.forEach((item: any)=> {
        if(item.type == "staff"){
          let staffId = item.id.split('_');
          this.selectedStaff.push(staffId[1]);
        }
        if(item.type == "team"){
          let teamId = item.id.split('_');
          this.selectedTeam.push(teamId[1]);
        }
      });
      let postData = {
        'staff': this.selectedStaff,
        'request_id' : this.requestId,
        'team': this.selectedTeam
      }

      this.apiService.postRequest('admin/assign-zone-check', postData).subscribe(
        (response: any) => {
            if (response.success) {
                this._flashMessagesService.show('Staff allocated successfully', { cssClass: 'alert-success' });
                this.getZoneCheckRequests(this.page);
                this.assignStaffForZoneCheckForm.controls['staff'].setValue("");
            } else {
                this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
            }
            this.closeModal()
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
            this.closeModal()
        },
      );
    }
  }

  onItemSelect(item:any){
    // console.log(item);
    // console.log(this.selectedItems);
    // this.hasStaff = true;
    // this.hasTeam = true;
  }
  OnItemDeSelect(item:any){
      console.log(item);
      console.log(this.selectedItems);
  }
  onSelectAll(items: any){
      console.log(items);
  }
  onDeSelectAll(items: any){
      console.log(items);
  }
  
  triggerDateRange() {
    this.dateForm.controls['include_archive'].setValue(false);  
    const modalOptions: NgbModalOptions = {
      windowClass: 'ngb-date-range-modal' // Add your custom class here
    };
    this.accoModalRef = this.modal.open(this.dateRangeModel, modalOptions);
  }
  closeModalRange(result?: any) {
    this.accoModalRef.close();
  }
  downloadZoneCheckExcel() {
    let startDate = this.dateForm.value.start_date;
    let endDate = this.dateForm.value.end_date;
    if(startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(startDate) + " to " + this.formatDateTime(endDate) + ")";
    } else if(startDate && !endDate) {
      this.dateRange = "(" +  this.formatDateTime(startDate) + ")";
    } else if(!startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(endDate) + ")";
    } else {
      this.dateRange = null
    }
    let postData = {
      'from_date' : this.dateForm.value.start_date,
      'to_date' : this.dateForm.value.end_date,
      'include_archive' : this.dateForm.value.include_archive,
    }
    this.apiService.postRequest('admin/zone-check-request/export',postData).subscribe(
      (response: any) => {
        if (response.success) {
          console.log(response.data.exportUrl)
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.setAttribute('href', response.data.exportUrl);
          link.setAttribute('download', response.data.fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          // this.closeModal();
          this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          this.errorMessage = response.message;
        }
      },
      (err: any) => console.log(err),
    );
    this.closeModalRange();
  }
  resetDates() {
    this.dateForm.controls['include_archive'].setValue(false);  
    this.dateForm.controls['start_date'].setValue(null); 
    this.dateForm.controls['end_date'].setValue(null); 
    this.dateRange = null;
  }

  setMinDate(event){
    this.minDate = event.value;
  }

}
