import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../shared/services/api.service';
import { MustMatch } from '../../validator';

@Component({
  selector: 'app-admin-user-change-password',
  templateUrl: './admin-user-change-password.component.html',
  styleUrls: ['./admin-user-change-password.component.css']
})
export class AdminUserChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  id: string;
  apiErrorMessage: string;
  passwordPattern : string;
  showPwd : boolean = false;
  showConfPwd : boolean = false;

  constructor(private apiService: ApiService, private modal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.passwordPattern = '(?=.*[A-Za-z])(?=.*\\d)(?=.*[ ?"#@^$%&/\[=+*!`~:;.,{}<>\-])[A-Za-z\\d@$!%*#?&.{}:;.,<>\/\\+-\[\\]`~]{8,}';
    this.changePasswordForm = this.formBuilder.group({
      'password': ['', Validators.pattern(this.passwordPattern)],
      'confirmPassword': ['', Validators.required]
    },
    {
      validator : MustMatch('password', 'confirmPassword')
    });
  }

  closeModal(result?: any) {
    this.modal.close(result);
  }

  onSubmit() {
    let putData = { 
        'password': this.changePasswordForm.value.confirmPassword
    };
    this.apiService.putRequest('admin/admin-user/' + this.id + '/change-password', putData).subscribe(
      (response: any) => {
          this.closeModal(response);
      },
      (error: any) => {
          this.closeModal(error);
      }
    );
  }

  togglePassShow() {
    this.showPwd = !this.showPwd;
  }

  toggleConfPassShow() {
    this.showConfPwd = !this.showConfPwd;
  }

}
