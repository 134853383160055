import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../shared/services/api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdaptor } from "../../upload-adapter";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'external-links-edit',
  templateUrl: './external-links-edit.component.html',
  styleUrls: ['./external-links-edit.component.scss']
})
export class ExternalLinkEditComponent implements OnInit {
  public Editor = ClassicEditor;
  editExternalLinkForm: FormGroup;
  errorMessage = null;
  externalLinkId: string;
  images = [];
  key:string = "";
  name: string;
  link: string;
  desc: string;
  ckConfig = {
    toolbar: ['bold', 'italic','underline', 'link', '|','imageUpload']
  };    
  constructor(private apiService: ApiService, private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _flashMessagesService: FlashMessagesService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.externalLinkId = params['id'];
    });
    this.getExternalLink();
    this.editExternalLinkForm = this.formBuilder.group({
      'name': ['',Validators.required],
      'link': ['',Validators.required],
    });
  }
  getExternalLink () {
    this.apiService.getRequest('admin/external-link/' + this.externalLinkId).subscribe(
      (response: any) => {
        if(response.success) {
          this.name = response.data.name;
          this.link = response.data.link;
        }
      },
      (err: any) => console.log(err),
    );
  }
  onSubmit() {
    if (this.editExternalLinkForm.valid) {
      let postData = {
        'name': this.editExternalLinkForm.value.name,
        'link': this.editExternalLinkForm.value.link,
      }
      this.apiService.putRequest('admin/external-link/' + this.externalLinkId, postData).subscribe(
        (response: any) => {
            if (response.success) {
              this.router.navigate(['external-links']);
                this._flashMessagesService.show('Updated Successfully!', { cssClass: 'alert-success' });
            } else {
                this._flashMessagesService.show('Updation Failed!!', { cssClass: 'alert-danger' });
                this.errorMessage = response.message;
            }
        },
        err => {
            console.log(err);
            this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger' });
        },
      );
    }
  }
  onBackClick() {
    this.location.back();
  }

}
