import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService } from '../../shared/services/auth.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { PraiseOrComplaintsViewComponent } from '../praise-or-complaints-view/praise-or-complaints-view.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'praise-or-complaints-list',
  templateUrl: './praise-or-complaints-list.component.html',
  styleUrls: ['./praise-or-complaints-list.component.scss']
})
export class PraiseOrComplaintsListComponent implements OnInit {
  listData: any;
  accoModalRef: any;

  officerName: string;
  citizenName: string;
  description: string;
  createdAt: string;
  read: boolean;

  popoverTitle: string = 'Delete Confirmation';
  popoverMessage: string = 'Are you sure?';
  // crimeAlertId: any ;

  previouspraiseCompCount: any;
  praiseCompCount: any;
  praiseCompCountSubscr: Subscription;

  // pagination
  page = 1;
  collectionSize: any;
  pageSize: any;
  lastPage: any;
  apiLink: any;
  dateForm: FormGroup;
  dateRange: any;
  errorMessage = null;
  minDate = null;
  todayDate:Date = new Date();

  @Input() pageTitle: string;
  @Input() paginateUrl: string;
  @Input() dataUrl: string;
  @Input() contentId: string;
  @ViewChild('accoViewModal', { static: false }) accoViewModal: TemplateRef<any>;
  @ViewChild('dateRangeModel', { static: false }) dateRangeModel: TemplateRef<any>;

  constructor(public apiService: ApiService, private _flashMessagesService: FlashMessagesService, private authService: AuthService, private modal: NgbModal, private activatedRoute: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) {
    this.praiseCompCountSubscr = this.apiService.praiseCompCount.subscribe((res) => {
      this.praiseCompCount = res;
      if (this.previouspraiseCompCount != this.praiseCompCount) {
        // this.getList(this.page);
        this.previouspraiseCompCount = this.praiseCompCount;
      }
    });
  }

  ngOnInit() {
    if(!this.dataUrl) {
      this.router.navigate(['content-pages']);
    }
    this.listData = [];
    this.activatedRoute.params.subscribe((params) => {
      if (params['page']) {
        this.page = params['page'];
      } 
      this.getList(this.page);
    });
    this.previouspraiseCompCount = this.praiseCompCount;
    this.dateForm = this.formBuilder.group({
      'start_date' : [''],
      'end_date' : [''],
      'include_archive' : [''],
    });
    
    // if (!this.pageTitle) {
    //   this.pageTitle = "Praise or complaint";
    // }
    // if (!this.paginateUrl) {
    //   this.paginateUrl = 'praise-or-complaint';
    // }
  }

  getList(page: any) {
    // if (this.dataUrl) {
      this.apiLink = this.dataUrl + '?page=' + page;
    // } else {
    //   this.apiLink = 'admin/praise-or-complaints?page=' + page;
    // }
    this.apiService.getRequest(this.apiLink).subscribe(
      (response: any) => {
        this.listData = [];
        if (response.success) {
          this.listData = response.data.data;
          if(this.listData.length == 0 && this.page > 1) { // to handle deletion of single record page
            this.page -= 1;
            this.getPraiseComplaintListPage(this.page)
          }
          this.page = response.data.current_page;
          this.collectionSize = response.data.total;
          this.pageSize = response.data.per_page;
          this.lastPage = response.data.last_page;
          this.getPraiseComplaintCount();
        }
      },
      (err: any) => console.log(err),
    );
  }

  viewItem(id: any) {
    this.officerName = '';
    this.citizenName = '';
    this.description = '';
    this.createdAt = null;
    this.read = false;
    this.accoModalRef = null;
    this.apiService.getRequest('admin/praise-or-complaints/' + id).subscribe(
      (response: any) => {
        if (response.success) {
          this.officerName = response.data.officer_name;
          this.citizenName = response.data.citizen_name;
          this.description = response.data.description;
          this.createdAt = response.data.createdAt;
          this.read = response.data.read;
        }
        if (!this.read) {
          let putData = {};
          this.apiService.postRequest('admin/praise-or-complaints-read/' + response.data.id, putData).subscribe(
            (res: any) => {
              if (response.success) {
                console.log("Marked as read!")
                this.apiService.praiseCompCount.next(response.unseen_praise_complaints_count);
              }
            },
            err => {
              console.log(err);
            },
          );
        }
      });
    this.accoModalRef = this.modal.open(this.accoViewModal);
    this.accoModalRef.result.then(() => {
      this.getList(this.page);
    }).catch((e) => { this.getList(this.page); });
  }

  formatDateTime(date) {
    if (!date) {
      return "";
    }
    if (!(moment(date).isValid())) {
      return "";
    }
    // return moment(date).tz("America/New_York").format('MM/DD/YYYY hh:mm:ss A');
    return moment(moment.utc(date).toDate()).local().format('MM/DD/YYYY');
  }

  deleteItem(id: any) {
    this.apiService.deleteRequest('admin/praise-or-complaints/' + id).subscribe(
      (response: any) => {
        if (response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getList(this.page);
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }

  closeModal(result?: any) {
    this.accoModalRef.close();
  }

  getPraiseComplaintCount() {
    this.apiService.getRequest('admin/praise-or-complaints-count').subscribe(
      (response: any) => {
        if (response.success) {
          // this.data = response.data;
          this.apiService.praiseCompCount.next(response.data);
        }
      },
      (err: any) => console.log(err),
    );
  }
  getPraiseComplaintListPage(pageNumber: any) {
    this.router.navigate([this.paginateUrl, { page: pageNumber }]);
  }
  onBackClick() {
    this.router.navigate(['content-pages']);
  }

  triggerDateRange() {
    const modalOptions: NgbModalOptions = {
      windowClass: 'ngb-date-range-modal' // Add your custom class here
    };
    this.dateForm.controls['include_archive'].setValue(false);  
    this.accoModalRef = this.modal.open(this.dateRangeModel, modalOptions);
  }
  closeModalRange(result?: any) {
    this.accoModalRef.close();
  }
  downloadZoneCheckExcel() {
    let startDate = this.dateForm.value.start_date;
    let endDate = this.dateForm.value.end_date;
    if(startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(startDate) + " to " + this.formatDateTime(endDate) + ")";
    } else if(startDate && !endDate) {
      this.dateRange = "(" +  this.formatDateTime(startDate) + ")";
    } else if(!startDate && endDate) {
      this.dateRange = "(" + this.formatDateTime(endDate) + ")";
    } else {
      this.dateRange = null
    }
    let postData = {
      'from_date' : this.dateForm.value.start_date,
      'to_date' : this.dateForm.value.end_date,
      'id' : this.contentId,
      'include_archive' : this.dateForm.value.include_archive,
    }
    this.apiService.postRequest('admin/praise-or-complaints/export',postData).subscribe(
      (response: any) => {
        if (response.success) {
          console.log(response.data.exportUrl)
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.setAttribute('href', response.data.exportUrl);
          link.setAttribute('download', response.data.fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          // this.closeModal();
          this._flashMessagesService.show(response.message, { cssClass: 'alert-danger' });
          this.errorMessage = response.message;
        }
      },
      (err: any) => console.log(err),
    );
    this.closeModalRange();
  }
  resetDates() {
    this.dateForm.controls['include_archive'].setValue(false);  
    this.dateForm.controls['start_date'].setValue(null); 
    this.dateForm.controls['end_date'].setValue(null); 
    this.dateRange = null;
  }

  setMinDate(event){
    this.minDate = event.value;
  }
}
