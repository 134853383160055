import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit {

  popoverTitle: string = 'Confirm Delete';
  popoverMessage: string = 'Are you sure?';
  templates:any;

  constructor(public apiService: ApiService, private modal: NgbActiveModal, private _flashMessagesService: FlashMessagesService) { }

  ngOnInit() {
    this.getTemplates();
  }

  getTemplates () {
    this.apiService.getRequest('admin/template').subscribe(
      (response: any) => {
        if(response.success) {
          this.templates = response.data;
        }
      },
      (err: any) => console.log(err),
    );
  }

  closeModal(result?: any) {
      this.modal.close();
      // location.reload();
  }
  deleteTemplate(id: any) {
    this.apiService.deleteRequest('admin/template/' + id).subscribe(
      (response: any) => {
        if(response.success) {
          this._flashMessagesService.show('Delete successful', { cssClass: 'alert-success' });
          this.getTemplates();
        } else {
          this._flashMessagesService.show('Delete failed!', { cssClass: 'alert-danger' });
        }
      },
      (err: any) => console.log(err),
    );
  }

}
